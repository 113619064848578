

































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import ModalModern from '../../common/components/ModalModern.vue';
import {ICoupon} from '../../coupons/models/Coupon';
import {IFulfilledCoupon} from '../models/Item';
import modalService from '../../common/services/modal.service';

export default Vue.extend({
  mixins: [baseMixin],

  components: {
    ModalModern
  },

  props: {
    couponToRemove: {
      type: Object as () => ICoupon,
      required: false
    }
  },

  computed: {
    // getters

    getTitle() {
      return this.couponToRemove ? 'Remove ' + this.couponToRemove.name : ''
    },

    // services

    _modalService() {
      return modalService;
    },

    // refs

    confirmCouponRemovalModal() {
      return this.$refs.confirmCouponRemovalModal;
    }
  },

  methods: {
    open() {
      this.confirmCouponRemovalModal.open();
    },

    close() {
      this.confirmCouponRemovalModal.close();
    },

    confirmRemoval(coupon: IFulfilledCoupon | ICoupon) {
      this.confirmCouponRemovalModal.close();
      this._modalService.removeCoupon(coupon);
      this.$emit('setCouponToRemove');
    },

    // Styling

    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    }
  }
});
