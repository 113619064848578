

























import baseMixin from '../../common/mixins/base-component.mixin';
import CouponModal from '../../coupons/components/CouponModal.vue';
import OrderTypeModal from '../../menu/components/OrderTypeModal.vue';
import restaurants from '../../restaurants/models/Restaurants';
import routeService from '../../common/services/route.service';
import {ICoupon} from '../../coupons/models/Coupon';
import loyaltyService from '../../coupons/services/loyalty.service';
import designService from '../../common/services/design.service';
import RewardsListModern from '../components/RewardsListModern.vue';
import cart from '../../cart/models/Cart';

export default {
  mixins: [baseMixin],
  components: {
    CouponModal,
    OrderTypeModal,
    RewardsListModern
  },
  computed: {
    // services

    designService() {
      return designService;
    },

    loyaltyService() {
      return loyaltyService;
    },

    restaurants() {
      return restaurants;
    },

    routeService() {
      return routeService;
    },

    // getters

    coupons(): ICoupon[] {
      return this.loyaltyService.getAvailableCoupons();
    },

    loyaltyPointsDescription(): string {
      return this._designService.loyaltyPointsDescription;
    },

    points() {
      if (this.profile.user) {
        return this.profile.user.points;
      } else {
        return 0;
      }
    },

    requiresAuthentication(): boolean {
      return true;
    },

    restaurant() {
      return restaurants.selectedRestaurant;
    },

    restaurantCount() {
      return restaurants.restaurantCount;
    },

    textColor(): string {
      return this.getTextColor();
    }
  },
  data() {
    return {
      loyalty: null, // ILoyalty
      orderTypeInterval: 0
    };
  },
  created() {
    if (!this.profile.user) {
      return;
    }

    if (this.profile.user.rewards) {
      this.loyalty = restaurants.loyaltySettings;
    } else {
      this.routeService.route('RewardsEnrollment');
    }

    this._designService.setModalStyling();
  },
  methods: {
    chooseCoupon(coupon: ICoupon) {
      // wait until order type is set to start coupon walkthrough
      if (!cart.cart.orderType) {
        this.$refs.orderTypeModal.open();

        if (this.orderTypeInterval) {
          clearInterval(this.orderTypeInterval);
        }

        this.orderTypeInterval = setInterval(() => {
          if (cart.cart.orderType) {
            this.loyaltyService.chooseCoupon(coupon, false);

            clearInterval(this.orderTypeInterval);
            this.orderTypeInterval = 0;
          }
        }, 500);

        return;
      }

      this.loyaltyService.chooseCoupon(coupon, false);
    },

    chooseRestaurant(): void {
      this.restaurants.changeRestaurant();
      this.routeService.route('RestaurantsList');
    }
  }
}
