
























import Vue from 'vue';
import baseItemComponentMixin from '../../common/mixins/base-item-component.mixin';
import CartTicket from '../components/CartTicket.vue';
import cart from '../models/Cart';
import restaurants from '../../restaurants/models/Restaurants';
import order from '../../order/models/Order';
import cartHelperService from '../services/cart-helper.service';
import {IMenuItem} from '../../menu/models/Item';

export default Vue.extend({
    mixins: [baseItemComponentMixin],
    components: { CartTicket },
    computed: {
        // models

        cart () {
            return cart;
        },

        cartHelper () {
            return cartHelperService;
        },

        order () {
            return order;
        },

        restaurants () {
            return restaurants;
        },

        // getters

        cartObj() {
            return cart.cart;
        },

        doesCartHaveItems(): boolean {
            if (this.cartObj) {
                if ((this.cartObj.items && this.cartObj.items.length > 0)
                  || (this.cartObj.coupons && this.cartObj.coupons.length > 0)) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },

        selectedItem() {
            return cart.selectedItem;
        },

        selectedRestaurant() {
            return this.restaurants.selectedRestaurant;
        },

        sizeMap() {
            return this.restaurants.sizeMap;
        },

        tenderRequest() {
            return this.order.tenderRequest;
        }
    },
    data () {
        return {
            _debouncer: null
        }
    },
    methods: {
        clearCart() {
            this.cart.clear(this.profile.user);
            this.closeCart();
            this.navigateToMenu();
        },

        removeItem(payload: { itemId: string, editingItem: boolean }) {
            this.cart.removeItem(payload.itemId);

            if (payload.editingItem) {
                this.routeService.forceRoute();
                this.navigateToMenu();
            }

            this.closeCart();
        },

        setQuantity(item: IMenuItem) {
            if (typeof item.quantity === 'string')
                item.quantity = parseInt(item.quantity);

            if (typeof item.quantity !== 'number' || isNaN(item.quantity))
                item.quantity = 0;

            if (this._debouncer) {
                window.clearTimeout(this._debouncer);
            }

            this._debouncer = window.setTimeout(
                () => this.cart.setQuantity(item.objectId, item.quantity),
                400
            );
        },

        checkOut() {
            this.cartHelper.reviewOrder(true);

            this.closeCart();
        },

        closeCart() {
            this.$emit('closeMobileCart');
        },

        showAlert () {
            this.$emit('showAlert');
        }
    }
})
