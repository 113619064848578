import orderService from '../services/order.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import environment from '../../common/services/environment.service';
import profile from '../../profile/models/Profile';
import cartModel from '../../cart/models/Cart';
import { ITenderRequest } from './TenderRequest';
import analyticsManager from '../../common/services/analytics-manager.service';
import { ORDER_STORE } from '../stores/order.store';
import { VuexModel } from '../../common/data/VuexModel';
import { ICart } from '../../cart/cart.types';
import restaurants from '../../restaurants/models/Restaurants';
import { Util } from '../../common/services/Util';
import { IMenuItem } from '../../menu/models/Item';
import loyaltyService from '../../coupons/services/loyalty.service';
import cart from '../../cart/models/Cart';
const deepClone = require('lodash.clonedeep');

declare var dataLayer: any;

export interface IOrder {
  tenderRequest: ITenderRequest;
  submitting: boolean;
  successful: boolean;
}

export class Order extends VuexModel {
  // getters

  get error() {
    return this.state.error;
  }

  get historicalOrders() {
    return this.state.historicalOrders;
  }

  get message() {
    return this.state.message;
  }

  get progress() {
    return this.state.progress;
  }

  get errorCode() {
    return this.state.errorCode;
  }

  get storeName() {
    return ORDER_STORE;
  }

  get submitting() {
    return this.state.submitting;
  }

  get successful() {
    return this.state.successful;
  }

  get tenderRequest() {
    return this.state.tenderRequest;
  }

  get surchargeEnabled() {
    return this.state.surchargeEnabled;
  }

  // methods

  initialize() {
    return this.dispatch('initialize');
  }

  submit(payload: { cart: ICart, tenderRequest: ITenderRequest }) {
    const couponQty = payload.cart.items.reduce((total, item) => {
      if (item.couponId) {
        total += item.quantity;
      }

      return total;
    }, 0);

    const itemQty = payload.cart.items.reduce((total, item) => {
      if (item.itemId) {
        total += item.quantity;
      }

      return total;
    }, 0);

    let paymentType;

    if (payload.tenderRequest.payByCash) {
      paymentType = 'Cash';
    } else if (payload.tenderRequest.hostedPaymentId) {
      paymentType = 'Hosted Payments';
    } else if (payload.tenderRequest.encryptedCardInfo) {
      paymentType = 'Credit Card';
    } else if (payload.tenderRequest.creditCardId) {
      paymentType = 'Saved';
    }

    analyticsManager.track(
      'Submit Order',
      {
        cartId: payload.cart.objectId,
        comments: payload.tenderRequest.comments,
        couponCode: payload.tenderRequest.couponCodes ? payload.tenderRequest.couponCodes.join(', ') : null,
        couponQty,
        itemQty,
        locationId: restaurants.selectedRestaurant.objetId,
        locationName: restaurants.selectedRestaurant.name,
        orderName: payload.cart.orderName,
        orderTotal: payload.tenderRequest.total,
        paymentType,
        salesTax: payload.cart.tax,
        subTotal: payload.cart.subtotal,
        tipAmount: payload.tenderRequest.tip
      }
    );

    if (loyaltyService.linkOffer) {
      const addedCoupon = cart.cart.coupons.find(coupon => coupon.itemId === loyaltyService.linkOffer.itemId);

      if (addedCoupon) {
        /* analyticsManager.track(
          'Submit Offer Link Order',
          {
            cartId: payload.cart.objectId,
            comments: payload.tenderRequest.comments,
            couponCode: payload.tenderRequest.couponCodes ? payload.tenderRequest.couponCodes.join(', ') : null,
            couponQty,
            itemQty,
            locationId: restaurants.selectedRestaurant.objectId,
            locationName: restaurants.selectedRestaurant.name,
            orderName: payload.cart.orderName,
            orderTotal: payload.tenderRequest.total,
            paymentType,
            plu: loyaltyService.linkOffer.plu,
            salesTax: payload.cart.tax,
            subTotal: payload.cart.subtotal,
            tipAmount: payload.tenderRequest.tip
          }
        );*/
      }
    }

    if (environment.notificationId) {
      payload.tenderRequest.notificationId = environment.notificationId;

      environment.clearNotificationId();
    }

    return this.dispatch('submit', {
      tenderRequest: payload.tenderRequest
    }).then(result => {
      this.trackOrder(payload);

      if (profile.user && this.successful) {
        if (payload.tenderRequest.saveCreditCard) {
          profile.loadCreditCards(profile.user.objectId);
        }

        this.fetchHistoricalOrders(restaurants.selectedRestaurant.objectId, profile.user.objectId);
      }

      return result;
    });
  }

  trackOrder(payload: { cart: ICart, tenderRequest: ITenderRequest }): void {
    // copy data in case it gets cleared
    payload.cart = deepClone(payload.cart);
    payload.tenderRequest = deepClone(payload.tenderRequest);

    // tracking
    analyticsManager.track(
      'Order Submitted',
      {
        cartId: payload.cart.objectId,
        locationId: restaurants.selectedRestaurant.objectId,
        locationName: restaurants.selectedRestaurant.name,
        user: payload.tenderRequest.userId ? {
          objectId: payload.tenderRequest.userId,
          email: profile.user.email
        } : null,
        addedSurcharge: this.surchargeEnabled,
        cartStarted: cartHelperService.doesCartHaveItems
      }
    );

    // google tag manager tracking
    const products = [];

    payload.cart.items.forEach((item: IMenuItem) => {
      products.push({                            // List of productFieldObjects.
        category: item.categoryId,
        id: item.objectId,
        name: item.name,     // Name or ID is required.
        price: item.price,
        quantity: item.quantity
      });
    });

    dataLayer.push({
      event: 'checkout',
      ecommerce: {
        surcharge: this.surchargeEnabled,
        cartStarted: cartHelperService.doesCartHaveItems,
        purchase: {
          actionField: {
            id: payload.cart.objectId,                         // Transaction ID. Required for purchases and refunds.
            revenue: payload.cart.grandTotal,                     // Total transaction value (incl. tax and shipping)
            tax: payload.cart.tax
          },
          products
        }
      }
    });
  }

  update(tenderRequest: ITenderRequest) {
    return this.dispatch('update', tenderRequest);
  }

  checkStatus() {
    return this.dispatch('checkStatus');
  }

  fetchHistoricalOrders(locationId: string, userId: string) {
    let payload: any = {
      locationId,
      userId
    };

    return this.dispatch('fetchHistoricalOrders', payload);
  }

  clearError() {
    return this.dispatch('clearError');
  }

  preauthorizePayment(payload: {
    locationId: string,
    tenderRequest: ITenderRequest
  }) {
    let isScheduled;

    if (cartModel.cart.orderTimeString) {
      const restaurant = restaurants.selectedRestaurant;

      isScheduled = Util.isOrderScheduledPastToday(
        restaurants.currentTime,
        cartModel.cart.orderType,
        restaurant.hours,
        cartModel.cart.orderTimeString
      );
    } else {
      isScheduled = false;
    }

    return orderService.preauthorizePayment({
      locationId: payload.locationId,
      tenderRequest: payload.tenderRequest,
      isScheduled
    }).then(result => {
      if (result.error) {
        throw result.error;
      } else {
        return result;
      }
    });
  }

  confirmCurbside(vehicleColor: string, vehicleType: string, vehicleLocation: string) {
    if (!environment.orderId || !restaurants.selectedRestaurantId) {
      return false;
    }

    return orderService.confirmCurbside(restaurants.selectedRestaurantId,
      environment.orderId, vehicleColor, vehicleType, vehicleLocation);
  }
}

export default new Order();
