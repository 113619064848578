




import Vue from 'vue';
import SiteMap from '../components/SiteMap.vue';

export default Vue.extend({
  components: {
    SiteMap
  }
});
