




import Vue from 'vue';
import selectedRestaurantOutletMixi from '../../common/mixins/selected-restaurant-outlet.mixin';

export default Vue.extend({
  mixins: [selectedRestaurantOutletMixi ]
});
