






























import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import {Util} from '../../common/services/Util';
import { ICategory, ICategoryDesign } from '../stores/menu.store';
import { ICart } from '../../cart/cart.types';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { IAccountInfo } from '../../account/types/account.types';
import designService from '../../common/services/design.service';

const moment = require('moment');

const BUTTON_LAYOUTS = {
    VERTICAL: 'VERTICAL',
    HORIZONTAL: 'HORIZONTAL'
};

export default Vue.extend({
    mixins: [baseMixin],
    props: {
        account: {
            type: Object as () => IAccountInfo,
            required: true
        },
        buttonClass: {
            type: String,
            required: true
        },
        category: {
            type: Object as () => ICategory,
            required: true
        },
        cart: {
            type: Object as () => ICart,
            required: true
        },
        currentTime: {
            type: String,
            required: true
        },
        categoryDesigns: {
            type: Array as () => Array<ICategoryDesign>,
            required: false
        },
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },
        forceShort: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        // services
        _designService() {
          return designService;
        },


      // getters

        isMobile(): boolean {
            return this.environment.isMobile;
        },

        thumbnailSize() {
          return this._designService.getDesign().thumbnailSize;
        },

        thumbnailClassObject() {
          return {
            'w-20 h-20': this.thumbnailSize === 'DEFAULT',
            'w-28 h-28': this.thumbnailSize === 'MEDIUM',
            'w-36 h-36': this.thumbnailSize === 'LARGE'
          }
        }
    },
    data() {
        return {
            categoryDesign: null,
            noCategoryImg: false
        };
    },
    watch: {
        categoryDesigns() {
            this._getCategoryDesign();
        }
    },
    created() {
        this._getCategoryDesign();
    },
    methods: {
        getCategoryImage(imageLink: string): any {
            return {
                'background': 'url("' + imageLink + '") center / cover no-repeat'
            };
        },

        getCategoryImageLink(imageLink: string): string {
            return imageLink === null ? this.account.imageLink : imageLink;
        },

        available(): boolean {
            return Util.validateAvailable(this.category, this.cart, this.currentTime);
        },

        getOrderTime(): string {
            return Util.getOrderTimeString(this.cart, this.restaurant.promiseTimes, this.currentTime, this.restaurant.usePromiseTime);
        },

        getDisplayTime(timeString: string): string {
            if (!timeString) return '';

            let day: string = this.getOrderTime().split('T')[0];
            return moment(day + 'T' + timeString).format('h:mm a');
        },

        layoutIsVertical(): boolean {
            if (!this.restaurant) return true;
            return this.restaurant.buttonLayout === BUTTON_LAYOUTS.VERTICAL;
        },

        showCategoryImage(categoryLink?: string): boolean {
            return this.category.imageLink && (categoryLink !== null || this.account.imageLink !== null);
        },

        // Styling
        getButtonClass(): any {
            if (!this.categoryDesign || !this.categoryDesign.body || !this.categoryDesign.body.buttonStyle || !this.restaurant) {
                return { unavailable: !this.available() };
            }

            let buttonClass: any = this._designService.getButtonClass(this.categoryDesign.body.buttonStyle);
            buttonClass['vertical'] = this.restaurant.buttonLayout === BUTTON_LAYOUTS.VERTICAL;
            buttonClass['horizontal'] = this.restaurant.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL;
            buttonClass['unavailable'] = !this.available();
            buttonClass['tol-category-variable tol-category-variable-nodesc'] = this.restaurant.buttonLayout === BUTTON_LAYOUTS.VERTICAL;
            buttonClass['tol-category-single tol-category-single-nodesc'] = this.buttonClass === 'tol-category-single'
                && this.restaurant.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL;
            buttonClass['tol-category-mobile tol-category-mobile-nodesc'] = this.buttonClass === 'tol-category-mobile';

            return buttonClass;
        },

        getUnavailableStyle(): any {
            if (this.restaurant && this.restaurant.buttonLayout === BUTTON_LAYOUTS.HORIZONTAL) {
                return null;
            }

            /*
            if (false && this.category.showDescription) {
                return {
                    'top': '5%'
                };
            } else {
            */
            return {
                'top': '15%'
            };
            // }
        },

        getCategoryDescriptionStyle(): any {
            if (!this._designService || !this._designService.getDesign()) {
                return {};
            }

            return {
                'font-family': this._designService.getDesign().fontBody
            };
        },

        loadData(): boolean {
            return false;
        },

        _getCategoryDesign() {
            this.categoryDesigns.forEach(catDesign => {
                if (!catDesign || !catDesign.objectId) {
                    return false;
                }

                if (this.category.objectId === catDesign.objectId) {
                    this.categoryDesign = catDesign;

                    return false;
                }
            });
        },
        getCategoryImageStyle() {
          const imageLink = this.getCategoryImageLink(this.category.imageLink);

          if (!imageLink) {
            return null;
          }

          return 'background-image: url("' +
              this.getCategoryImageLink(this.category.imageLink) +
              '");';
        }
    }
});
