





















































































































































































import Vue from 'vue';
import baseItemCustomizationLayoutMixin from '../../mixins/base-item-customization-layout.mixin';
import ToppingChoicesModern from './ToppingChoicesModern.vue';
import tabsSectionHeadingsMixin from '../../mixins/tabs-section-headings.mixin';
import cart from '../../../cart/models/Cart';
import { isModifierInStock } from '../../../cart/helpers/cart.helpers';
const _ = require('lodash');

export default Vue.extend({
  components: {
    ToppingChoicesModern
  },
  mixins: [baseItemCustomizationLayoutMixin, tabsSectionHeadingsMixin],
  props: {
    isStepBuilder: {
      type: Boolean,
      required: true
    },

    isModifiersTypeNumeric: {
      type: Boolean,
      required: true
    },

    step: {
      type: Number,
      required: true
    }
  },

  computed: {
    cart() {
      return cart.cart;
    },

    hasCrusts() {
      return !!this.styleList.types.find(name => name.toLowerCase().includes('crust'))
    },

    hasSizes() {
      return this.sizes && ((this.isStepBuilder && this.step === 0) || !this.isStepBuilder);
    },

    hasStyles() {
      return this.styleList && this.styleList.types && this.styleList.types.length > 0;
    }
  },

  methods: {
    isModifierInStock(modifier) {
      return isModifierInStock(this.cart, this.item, modifier.objectId);
    },

    getStylePrice(styleName, style) {
      const size = this.item.size.name;
      const styles = _.get(this.styleList, `map['${size}']['${styleName}']`, []);

      return _.get(styles.find(it => it.name === style.name), 'price').toFixed(2);
    },

    scrollToRequirement(requirementId: string) {
      let el;

      if (requirementId) {
        let i = this.getRequiredOptions(this.item)
          .findIndex(option => option.objectId === requirementId);

        if (i === -1) {
          i = 0;
        }

        el = this.$refs.requirements[i];
      } else {
        el = this.$refs.requirements ? this.$refs.requirements[0] : null;
      }

      if (el) {
        el.scrollIntoView();
      }
    },

    showTopping(index) {
      const isSplitAndNotFirstStep = this.item.alreadySplit && this.step > 0;
      const isNotSplitAndCurrentStep = !this.item.alreadySplit && this.step === index + 1;

      return (this.isStepBuilder && (isSplitAndNotFirstStep || isNotSplitAndCurrentStep))
        || !this.isStepBuilder; // If not step builder show all toppings
    }
  }
});
