import { createSetterMutations, setStateThroughMutation } from '../../common/data/store.helpers';
import orderService from '../services/order.service';
import { TenderRequest } from '../models/TenderRequest';
import analyticsManager from '../../common/services/analytics-manager.service';
import { RESTAURANTS_STORE } from '../../restaurants/stores/restaurants.store';

export const ORDER_STORE = 'order';

const initialState = {
  historicalOrders: [],
  message: undefined,
  progress: 0,
  submitting: false,
  successful: false,
  tenderRequest: undefined,
  error: undefined,
  errorCode: null,
  surchargeEnabled: false
};

const DECLINE_MESSAGE_PLACEHOLDER: string = 'DECLINED';
export const GENERIC_DECLINE_MESSAGE: string = 'Credit card was declined. Please verify your information and try submitting again.';

export default {
  name: ORDER_STORE,
  namespaced: true,
  state: initialState,
  mutations: {
    ...createSetterMutations(initialState)
  },
  actions: {
    checkStatus({ commit, state }) {
      return orderService.checkOrderStatus().then(response => {
        if (!response) { // API seems to turn a null status response on the first request sometimes
          return;
        }

        if (state.progress === 100) {
          return;
        }

        setStateThroughMutation(commit, 'progress', parseInt(response.progress));
        setStateThroughMutation(commit, 'message', response.message);

        return response;
      });
    },
    clearError({ commit }) {
      setStateThroughMutation(commit, 'error', null);
      setStateThroughMutation(commit, 'errorCode', undefined);
    },
    fetchHistoricalOrders({ commit }, payload) {
      return orderService.fetchHistoricalOrder(payload).then(response => {
        setStateThroughMutation(commit, 'historicalOrders', response);

        return response;
      });
    },
    initialize({ commit }) {
      const result = new TenderRequest();

      setStateThroughMutation(commit, 'tenderRequest', result);

      return result;
    },
    submit({ commit, rootState }, payload) {
      setStateThroughMutation(commit, 'submitting', true);
      setStateThroughMutation(commit, 'progress', 0);
      setStateThroughMutation(commit, 'message', undefined);
      setStateThroughMutation(commit, 'successful', false);
      setStateThroughMutation(commit, 'error', undefined);
      setStateThroughMutation(commit, 'errorCode', undefined);

      return orderService.submitTenderRequest(payload).then(response => {
        if (response && response.error && !response.success) {
          setStateThroughMutation(commit, 'submitting', false);
          setStateThroughMutation(commit, 'successful', false);
          setStateThroughMutation(commit, 'progress', 100);

          let message: string;
          const rootCause: string = response.error.rootCause;
          let errorCode;

          if (rootCause === DECLINE_MESSAGE_PLACEHOLDER) {
            message = GENERIC_DECLINE_MESSAGE;

            analyticsManager.track(
              'Card Decline',
              {
                cartId: payload.cartId,
                locationId: rootState[RESTAURANTS_STORE].restaurant.objectId,
                locationName: rootState[RESTAURANTS_STORE].restaurant.name
              }
            );
          } else {
            message = rootCause;

            analyticsManager.track(
              'Order Error',
              {
                cartId: payload.cartId,
                locationId: rootState[RESTAURANTS_STORE].restaurant.objectId,
                locationName: rootState[RESTAURANTS_STORE].restaurant.name,
                message
              }
            );

            if (rootCause && rootCause.indexOf(':') >= 0) {
              message = rootCause.substr(rootCause.indexOf(':') + 1);
            } else {
              const match = /POS Error (\d+)-(.+)/.exec(message);

              if (match && match.length > 0) {
                errorCode = parseInt(match[1]);

                if (errorCode === 607) {
                  message = 'The location reported an error with your order. Please call the location at '
                    + rootState[RESTAURANTS_STORE].restaurant.address.phone + ' to verify they received your order. '
                    + 'Please do NOT re-submit before speaking with the location';
                } else {
                  message = match[2];
                }
              }
            }
          }

          setStateThroughMutation(commit, 'message', message);
          setStateThroughMutation(commit, 'error', response.error);
          setStateThroughMutation(commit, 'errorCode', errorCode);

          return response;
        }

        setStateThroughMutation(commit, 'submitting', false);
        setStateThroughMutation(commit, 'successful', true);
        setStateThroughMutation(commit, 'tenderRequest', new TenderRequest());
        setStateThroughMutation(commit, 'error', undefined);
        setStateThroughMutation(commit, 'errorCode', undefined);
        setStateThroughMutation(commit, 'progress', 100);

        return response;
      });
    },
    update({ commit }, payload) {
      const result = JSON.parse(JSON.stringify(payload));

      setStateThroughMutation(commit, 'tenderRequest', result);

      return result;
    }
  }
};
