


















































import Vue from 'vue';
import CategoryCard from './CategoryCard.vue';
import environmentService from '../../common/services/environment.service';
import util from '../../common/services/Util';
import designMixin from '../../common/mixins/design.mixin';
import {ICoupon} from '../../coupons/models/Coupon';
import CategorySubheader from './CategorySubheader.vue';
import { ICategory, ICategoryDesign, IFeaturedItem } from '../stores/menu.store';
import { ICart } from '../../cart/cart.types';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { IAccountInfo } from '../../account/types/account.types';
import FeaturedItemCard from './FeaturedItemCard.vue';

export default Vue.extend({
    components: {
        FeaturedItemCard,
        CategoryCard,
        CategorySubheader
    },
    mixins: [designMixin],
    props: {
        account: {
            type: Object as () => IAccountInfo,
            required: true
        },
        buttonClass: {
            type: Object,
            required: true
        },
        btnStyle: {
            type: Object,
            required: true
        },
        cart: {
            type: Object as () => ICart,
            required: true
        },
        categories: {
            type: Array as () => Array<ICategory>,
            required: true
        },
        categoryDesigns: {
            type: Array as () => Array<ICategoryDesign>,
            required: true
        },
        currentTime: {
            type: String,
            required: false
        },
        featuredItems: {
          type: Array as () => Array<IFeaturedItem>,
          required: true
        },
        loading: {
            type: Boolean,
            required: true
        },
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },
        subheaderCategory: {
          type: Object as () => ICategory,
          required: false
        },
        subheaderCoupon: {
          type: Object as () => ICoupon,
          required: false
        }
    },
    computed: {
        // services

        environment() {
            return environmentService;
        },

        // getters

        doesCartHaveItems(): boolean {
          return this.cartHelperService?.doesCartHaveItems;
        },

        isMobile(): boolean {
            return this.environment.isMobile;
        },

        forceShort(): boolean {
            for (let i = 0; i < this.categories.length; i++) {
                let category = this.categories[i];

                if (category.imageLink)
                    return false;
            }

            return true;
        },

        hasFeaturedItems(): boolean {
          return this.featuredItems && this.featuredItems.length > 0;
        }
    },
    data() {
        return {
            screenWidth: null,
            windowResizeDebouncer: 0
        };
    },
    created() {
        this._setPageWidth();

        window.addEventListener('resize', () => {
            if (this.windowResizeDebouncer) {
                window.clearTimeout(this.windowResizeDebouncer);
            }

            this.windowResizeDebouncer = window.setTimeout(
                () => this._setPageWidth(),
                250
            );
        });

        window.scrollTo(0, 0);

        this.$emit('clear', null);
    },
    methods: {
        viewCategory(category) {
            this.$emit('viewCategory', category);
        },

        // Styling

        getButtonTypeClass(): string {
            if (this.screenWidth && this.screenWidth === 'xs') {
                return 'tol-category-mobile';
            } else {
                return 'tol-category-single';
            }
        },

        getMenuLayoutClass() {
            return 'col-xs-12';
        },

        reviewOrder(): void {
            this.$emit('checkOut', true);
        },

        // Private functions

        _setPageWidth(): void {
            let pageWidth: number = window.innerWidth,
            MQ = util.MEDIA_QUERIES;

            if (pageWidth < MQ.SM) {
                this.screenWidth = 'xs';
            } else if (pageWidth >= MQ.SM && pageWidth < MQ.MD) {
                this.screenWidth = 'sm';
            } else if (pageWidth >= MQ.MD && pageWidth < MQ.LG) {
                this.screenWidth = 'md';
            } else {
                this.screenWidth = 'lg';
            }
        }
    }
});
