import { ICoupon, ICouponGroup } from '../models/Coupon';
import { Util } from '../../common/services/Util';
import { ICart } from '../../cart/cart.types';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { IMenuItem, IMenuItemSize } from '../../menu/models/Item';
import { IUser } from '../../profile/stores/profile.store';
import { THIS_OFFER_REQUIRES_ORDER_TYPE } from '../coupon.constants';

const moment = require('moment');

type SelectedItemsObject = { items: IMenuItem[], group: ICouponGroup };
type SelectedItemsMap = { [key: number]: SelectedItemsObject };

export function getAvailableSizes(item: IMenuItem, group: ICouponGroup) {
  let sizes: IMenuItemSize[] = [];

  let include = group.includes.find(i => {
    if (i.groupType === 'DEPARTMENT') {
      return i.department.objectId === item.departmentId;
    } else if (i.groupType === 'CATEGORY') {
      return i.category.objectId === item.categoryId;
    } else if (i.groupType === 'ITEM') {
      return i.item.objectId === item.itemId;
    }
  });

  if (include) {
    for (let style of include.styles) {
      if (style.type.toLowerCase() === 'size') {
        sizes.push(style);
      }
    }
  }

  return sizes;
}

export function getAvailableCrustForCouponItem(couponGroup: ICouponGroup, idOnly: boolean = true) {
  const includes = couponGroup.includes[0];
  const styles = includes.styles
    .filter(style => style.type.toLowerCase() === 'crust' || style.type.toLowerCase() === 'style');
  return idOnly ? styles.map(s => s.styleId) : styles;
}

export function getDateRangeMessage(dateRange: any): string {
  if (!dateRange) {
    return '';
  }

  let dateRangeMessage: string = '';

  if (dateRange && dateRange.startString) {
    let validStart = moment(dateRange.startString + 'T00:00:00');
    dateRangeMessage += 'Only valid after ' + validStart.format('ddd, MMM Do, YYYY');
  }

  if (dateRange && dateRange.endString) {
    let oneYear = moment().add(1, 'years');
    let validEnd = moment(dateRange.endString + 'T23:59:59');

    if (Util.isMomentBefore(validEnd, oneYear)) {
      if (dateRangeMessage) {
        dateRangeMessage += ' and before ' + validEnd.format('ddd, MMM Do, YYYY');
      } else {
        dateRangeMessage += 'Only valid before ' + validEnd.format('ddd, MMM Do, YYYY');
      }
    }
  }

  return dateRangeMessage;
}

export function getIncludeDescription(group: ICouponGroup, include: any): string {
  let sizes: any[] = [];
  let str: string = '';

  for (let style of include.styles) {
    if (style.type.toLowerCase() === 'size') {
      sizes.push(style);
    }
  }

  if (group.additionalItem > 0) {
    let total: number = group.additionalItem + group.includes[0].requiredQty;
    str += 'Up to ' + total;
  } else {
    str += include.requiredQty;
  }

  if (include.sizeLimit === 'EQUALS') {
    str += ' ' + include.styles[0].name;
  }

  if (include.groupType.toLowerCase() === 'department') {
    if (sizes.length > 1) {
      let sizeNames: string[] = [];

      for (let size of sizes) {
        sizeNames.push(size.name);
      }

      str += ' ' + sizeNames.join(' or ') + ' from ' + include.department.name;
    } else if (sizes.length === 1) {
      str += ' ' + sizes[0].name + ' from ' + include.department.name;
    } else {
      str += include.department.name;
    }
  } else if (include.groupType.toLowerCase() === 'category') {
    if (sizes.length > 1) {
      let sizeNames: string[] = [];

      for (let size of sizes) {
        sizeNames.push(size.name);
      }

      str += ' ' + sizeNames.join(' or ') + ' from ' + include.category.name;
    } else if (sizes.length === 1) {
      str += ' ' + sizes[0].name + ' from ' + include.category.name;
    } else {
      str += ' from ' + include.category.name;
    }
  } else {
    if (include.styles && include.styles.length > 0) {
      str += ' ' + include.styles[0].name;
    }

    str += ' ' + include.item.name;
  }

  return str;
}

export function getNumber(value): number {
  if (typeof value === 'undefined') {
    value = 0;
  }

  return value;
}

export function getTimeRangeMessage(timeRange: any): string {
  if (!timeRange) {
    return '';
  }

  let today = moment().format('YYYY-MM-DD');
  let timeRangeMessage: string = '';

  if (timeRange && timeRange.startString) {
    let validStart = moment(today + 'T' + timeRange.startString);
    timeRangeMessage += 'Only valid after ' + validStart.format('h:mm a');
  }

  if (timeRange && timeRange.endString) {
    let validEnd = moment(today + 'T' + timeRange.endString);

    if (timeRangeMessage) {
      timeRangeMessage += ' and before ' + validEnd.format('h:mm a');
    } else {
      timeRangeMessage += 'Only valid before ' + validEnd.format('h:mm a');
    }
  }

  return timeRangeMessage;
}

export function getValidations(
  coupon: ICoupon,
  cart: ICart,
  selectedRestaurant: IRestaurant,
  currentTime: string,
  alreadyAdded: boolean = false,
  orderType?: string): string[] {
  const validations: string[] = [];

  const orderTimeString = Util.getOrderTimeString(
    cart,
    selectedRestaurant.promiseTimes,
    currentTime,
    selectedRestaurant.usePromiseTime
  );

  const orderTime = moment(orderTimeString);

  if (coupon.validDateRange && coupon.validDateRange.startString) {
    const validStart = moment(coupon.validDateRange.startString + 'T00:00:00');

    if (Util.isMomentBefore(orderTime, validStart)) {
      validations.push('This offer starts on ' + validStart.format('ddd, MMM Do, YYYY'));
    }
  }

  if (coupon.validDateRange && coupon.validDateRange.endString) {
    const validEnd = moment(coupon.validDateRange.endString + 'T23:59:59');

    if (Util.isMomentAfter(orderTime, validEnd)) {
      validations.push('This offer ended on ' + validEnd.format('ddd, MMM Do, YYYY'));
    }
  }

  const today = orderTimeString.substring(0, orderTimeString.indexOf('T'));

  if (coupon.validTimeRange && coupon.validTimeRange.startString && coupon.validTimeRange.endString) {
    const startTime = moment(today + 'T' + coupon.validTimeRange.startString);
    const endTime = moment(today + 'T' + coupon.validTimeRange.endString);

    if (this.getNumber(coupon.validTimeRange.end[0]) >= this.getNumber(coupon.validTimeRange.start[0])) {
      if (Util.isMomentBefore(orderTime, startTime) || Util.isMomentAfter(orderTime, endTime)) {
        validations.push('This offer only applies between ' + startTime.format('h:mm a') +
          ' and ' + endTime.format('h:mm a') + '.');
      }
    } else if (Util.isMomentBefore(orderTime, startTime)) { // Range crosses midnight and we are before the start
      // We'll try to see if we're before the end time
      if (Util.isMomentAfter(orderTime, endTime)) {
        validations.push('This offer only applies between ' + startTime.format('h:mm a') +
          ' and ' + endTime.format('h:mm a') + '.');
      }
    }
  } else if (coupon.validTimeRange && coupon.validTimeRange.startString) {
    const startTime = moment(today + 'T' + coupon.validTimeRange.startString);

    if (Util.isMomentBefore(orderTime, startTime)) {
      validations.push('This offer starts at ' + startTime.format('h:mm a'));
    }
  } else if (coupon.validTimeRange && coupon.validTimeRange.endString) {
    const endTime = moment(today + 'T' + coupon.validTimeRange.endString);

    if (Util.isMomentAfter(orderTime, endTime)) {
      validations.push('This offer ends at ' + endTime.format('h:mm a'));
    }
  }

  if (coupon.validDays && coupon.validDays.length > 0) {
    let pass: boolean = false;
    const dayOfWeek: string = orderTime.format('dddd');
    let validDays: string = '';

    for (let day of coupon.validDays) {
      validDays += day.name + ', ';

      if (day.name === dayOfWeek) {
        pass = true;

        break;
      }
    }

    if (!pass) {
      validations.push('This offer is only valid on these days: ' + validDays.substr(0, validDays.lastIndexOf(',')));
    }
  }

  if (!orderType) {
    orderType = cart.orderType;
  }

  const validOrderType = coupon.orderTypes.find(type => type.objectId === orderType);

  if (!validOrderType) {
    const orderTypes = coupon.orderTypes.map(type => type.name).join(', ');

    validations.push(THIS_OFFER_REQUIRES_ORDER_TYPE + orderTypes);
  }

  if (coupon.maxTimeApply && coupon.maxTimeApply >= 0) {
    const appliedCoupons: ICoupon[] = cart.coupons.filter(function (cartCoupon) {
      return cartCoupon.couponId === this.couponId;
    }, coupon);

    let otherCouponCount = appliedCoupons.length;

    if (alreadyAdded) {
      otherCouponCount--;
    }

    if (otherCouponCount >= coupon.maxTimeApply) {
      validations.push('This offer may only be applied ' + coupon.maxTimeApply + ' time' + (coupon.maxTimeApply > 1 ? 's.' : '.'));
    }
  }

  if (coupon.invalidWithAutoApply) {
    const autoApplyCoupons: ICoupon[] = cart.coupons.filter(function (cartCoupon) {
      return cartCoupon.autoApply;
    });

    if (autoApplyCoupons && autoApplyCoupons.length > 0) {
      validations.push('This offer may not be applied with other auto-apply offers.');
    }
  } else if (coupon.autoApply) {
    const invalidWithAutoApply: ICoupon[] = cart.coupons.filter(function (cartCoupon) {
      return cartCoupon.invalidWithAutoApply;
    });

    if (invalidWithAutoApply && invalidWithAutoApply.length > 0) {
      validations.push(
        'This offer is an auto-apply offer and another offer in the cart is not valid with other auto-apply offers.');
    }
  }

  if (coupon.invalidWithOther && coupon.invalidWithOther.toUpperCase() === 'ORDER') {
    let otherCouponCount = cart.coupons ? cart.coupons.length : 0;

    if (alreadyAdded) {
      otherCouponCount--;
    }

    if (otherCouponCount > 0) {
      validations.push('This offer may not be applied with other offers.');
    }
  } else {
    const invalidWithOthers: ICoupon[] = cart.coupons.filter(function (cartCoupon) {
      return cartCoupon.invalidWithOther && cartCoupon.invalidWithOther.toUpperCase() === 'ORDER';
    });

    if (invalidWithOthers && invalidWithOthers.length > 0) {
      validations.push('Another offer in the cart is not valid with other offers.');
    }
  }

  return validations;
}

export function checkWalkthroughStatus(
  itemNumber: number,
  selectedItemsMap: SelectedItemsMap,
  progress: Function,
  finish: Function,
  group: ICouponGroup): boolean {
  if (!selectedItemsMap) {
    return;
  }

  if (checkForFinished(selectedItemsMap)) {
    finish();

    return true;
  } else if (selectedItemsMap[itemNumber]) {
    const isGrpComplete = isGroupComplete(
      selectedItemsMap,
      group
    );
    if (isGrpComplete) {
      progress();

      return true;
    }
  }

  return false;
}

export function checkForFinished(selectedItemsMap: SelectedItemsMap): boolean {
  let couponFinished: boolean = true;
  if (!selectedItemsMap) {
    return false;
  }


  const keys = Object.keys(selectedItemsMap);
  for (let i = 0; i < keys.length; i++) {
    const group = selectedItemsMap[keys[i]].group;
    if (!isGroupComplete(selectedItemsMap, group)) {
      couponFinished = false;
    }
  }

  return couponFinished;
}

export function isGroupComplete(selectedItemsMap: SelectedItemsMap, group: ICouponGroup): any {
  const groups = Object.values(selectedItemsMap).map(s => s.group);
  const itemMaps = Object.values(selectedItemsMap).map(s => s.items);
  let items = [];

  // get all items out of the itemMaps
  for (let itemMap of itemMaps) {
    if (itemMap != null) {
      Object.values(itemMap).forEach(item => {
        for (let i = 0; i < (item.quantity || 1); i++) {
          items.push(item); // add each quantity as its own item to simplify logic below
        }
      });
    }
  }

  // iterate through each group to make sure previous groups are complete before counting this one complete
  for (let i = 0; i < groups.length; i++) {
    const grp = groups[i];
    const groupRequiredQty = grp.requiredQty;

    let groupItemTotal = 0;

    for (let j = 0; j < grp.includes.length; j++) {
      const include = grp.includes[j];
      const includeRequiredQty = include.requiredQty;

      // filter all the items in the cart to find any that match this include
      const itemsMatchedToInclude = items.filter(item => {
        switch (include.groupType.toLowerCase()) {
          case 'department':
            return item.departmentId === include.typeId;
          case 'category':
            return item.categoryId === include.typeId;
          case 'item':
            return item.itemId === include.typeId;
          default:
            return false;
        }
      });

      if (itemsMatchedToInclude.length >= includeRequiredQty) {
        while (itemsMatchedToInclude.length > 0 && groupItemTotal < groupRequiredQty) {
          const takingItems = itemsMatchedToInclude.splice(0, includeRequiredQty);

          // remove items from item pool so they aren't counted for other includes or groups
          takingItems.forEach(item => {
            items.splice(items.indexOf(item), 1);
          });

          groupItemTotal += takingItems.length;
        }

        if (groupItemTotal >= groupRequiredQty) {
          if (grp.objectId === group.objectId) {
            return true; // all previous groups complete and the give one
          } else {
            break; // group complete but not the given one so move on to next one
          }
        } // else add more from other includes
      }
    }

    if (groupItemTotal < groupRequiredQty) {
      return false; // if any group before or equal to given one is incomplete after going through its include, abort
    }
  }

  return false;
}

export function hasLoyalty(user: IUser, restaurantId: string, restaurants: IRestaurant[]) {
  return isLoyaltyEnabled(restaurantId, restaurants) && isEnrolledInRewards(user);
}

export function isEnrolledInRewards(user: IUser) {
  return user && user.rewards;
}

export function isLoyaltyEnabled(restaurantId: string, restaurants: IRestaurant[]) {
  if (restaurantId) {
    const restaurant = restaurants ? restaurants.find(loc => loc.objectId === restaurantId) : null;

    if (restaurant) {
      return restaurant.loyalty && restaurant.loyalty.externalProgram;
    } else {
      return false;
    }
  } else {
    return restaurants ? !!restaurants.find(loc => loc.loyalty && loc.loyalty.externalProgram) : false;
  }
}

export function isEntireOrderCoupon(coupon: ICoupon): boolean {
  return !!(!coupon.groups || coupon.groups.length <= 0 || coupon.entireOffer);
}
