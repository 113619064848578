























































































































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import loyaltyMixin from '../../coupons/mixins/loyalty.mixin';
import { routerLink } from '../../common/directives/RouterLink';
import restaurants from '../../restaurants/models/Restaurants';

export default Vue.extend({
  mixins: [baseMixin, loyaltyMixin],
  directives: { routerLink },
  computed: {
    // services
    restaurants () {
      return restaurants;
    },

    // getters

    isEnrolledInRewards(): boolean {
      return this.profile.user.rewards && this.loyalty && this.loyalty.externalProgram;
    },

    loyalty() {
      return this.restaurants.loyalty;
    }
  }
});
