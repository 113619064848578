





















import Vue from 'vue';
import {IRequiredOption} from '../../../models/Item';
import designMixin from '../../../../common/mixins/design.mixin';

export default Vue.extend({
    mixins: [designMixin],
    props: {
        i: {
            type: Number,
            required: true
        },
        isNoSelected: {
            type: Boolean,
            required: true
        },
        option: {
            type: Object as () => IRequiredOption,
            required: true
        }
    },
    methods: {
        toggleNoRequired($event) {
            this.$emit('toggleNoRequired', $event);
        }
    }
});
