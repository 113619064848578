
















import Vue from 'vue';
import baseMixin, { navigateToInnerLandingPage } from '../../common/mixins/base-component.mixin';
import Login from '../components/Login.vue';
import order from '../../order/models/Order';
import cart from '../../cart/models/Cart';
import notifierService from '../../common/services/notifier.service';
import reportingService from '../../common/services/reporting.service';
import { ILoginRequest, IUser } from '../stores/profile.store';
import restaurantLoaderService from '../../restaurants/services/restaurant-loader.service';
import { Util } from '../../common/services/Util';
import restaurants from '../../restaurants/models/Restaurants';
import { ORDER_TYPES } from '../../cart/cart.types';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import environmentService from '../../common/services/environment.service';
import couponWalkthrough, { CouponWalkthrough } from '../../coupons/models/CouponWalkthrough';
import loyaltyService from '../../coupons/services/loyalty.service';

export default Vue.extend({
  mixins: [baseMixin],
  components: { Login },
  computed: {
    // models

    _cart() {
      return cart;
    },

    _notifierService() {
      return notifierService;
    },

    _reportingService() {
      return reportingService;
    },

    order() {
      return order;
    },

    // getters

    loginError() {
      return this.profile.loginError;
    },

    loginRequest() {
      return this.profile.loginRequest;
    },

    passwordResetError() {
      return this.profile.passwordResetError;
    },

    source() {
      return Util.getQueryParam('source');
    },

    user() {
      this.profile.user;
    }
  },
  data() {
    return {
      initialized: false,
      loginSubscription: null,
      userError: null
    };
  },
  created() {
    this.initialized = true;
  },
  methods: {
    login(loginRequest: ILoginRequest) {
      let selectedRestaurantId: string = this.locationId;
      loginRequest.location = selectedRestaurantId;

      const useFavoriteLocation = !loyaltyService.linkOffer && !environmentService.offerId;

      return this.profile.logIn(loginRequest, this.$route.params.origin, useFavoriteLocation).then(user => {
        if (user.error) {
          this.userError = user.error;
        } else if (user && user.success !== false) {
          this._reportingService.setRaygunUser(this.profile.user);

          let locationId = selectedRestaurantId;

          if (!locationId && useFavoriteLocation) {
            locationId = user.favoriteLocationId;
          }

          if (locationId) {
            const navigateToSource = (redirectUrl = null) => {
              if (this.source) {
                if (this.source === 'PasswordReset') {
                  this.routeService.route('MainMenu');
                } else if (this.source === 'SelectCouponItem' && couponWalkthrough.selectedCoupon) {
                  CouponWalkthrough.initiateCouponWalkthrough(couponWalkthrough.selectedCoupon);
                } else {
                  this.routeService.route(this.source);
                }
              } else {
                if (redirectUrl) {
                  environmentService.open(redirectUrl);
                } else {
                  if (environmentService.directRoute
                    && environmentService.directRoute.toLowerCase() === 'register') {
                    environmentService.directRoute = null;
                  }

                  this.navigateToInnerLandingPage();
                }
              }
            };

            const setOrderType = (orderType: string, restaurant: IRestaurant) => {
              if (orderType === ORDER_TYPES.DELIVERY) {
                cart.setOrderType(orderType);
              } else {
                const orderTypes = Object.keys(restaurant.orderTypes);
                const nonDeliveryOrderTypes = orderTypes.filter(type => type !== ORDER_TYPES.DELIVERY);

                if (nonDeliveryOrderTypes.length === 1) {
                  cart.setOrderType(nonDeliveryOrderTypes[0]);
                }
              }
            };

            let promise;

            if (locationId === restaurants.selectedRestaurantId) {
              promise = new Promise(resolve => {
                navigateToSource(restaurants.selectedRestaurant.redirectUrl);

                resolve(restaurants.selectedRestaurant);
              });
            } else {
              // only sets location
              promise = restaurantLoaderService.selectRestaurantById(locationId).then(restaurant => {
                navigateToSource(restaurant.redirectUrl);

                return restaurant;
              });
            }

            promise.then(restaurant => {
              this.fetchHistoricalOrdersAndAddUser(user);

              // order type override
              const orderType = Util.getQueryParam('orderType');

              if (orderType) {
                setOrderType(orderType, restaurant);
              }
            });
          } else if (!user.favoriteLocationId) {
            this.routeService.route('RestaurantChooser', { route: this.source }, true);
          } else {
            this.routeService.route('RestaurantsList');
          }
        }

        return user;
      });
    },

    fetchHistoricalOrdersAndAddUser(user: IUser) {
      let selectedRestaurantId: string = restaurants.selectedRestaurantId;

      if (this._cart.cart) {
        this._cart.addUser(user.objectId);
      }

      this.order.fetchHistoricalOrders(selectedRestaurantId, user.objectId);
    },

    resetPassword(username: string): void {
      this.profile.resetPassword(username);
    },

    navigateToProfile() {
      this.routeService.route('User');
    },

    navigateToRegister() {
      this.register(this.source ? { route: this.source } : undefined);
    }
  }
});
