





















































































































import Vue from 'vue';
import designService from '../../common/services/design.service';
import menuBus from '../menu.bus';
import modalBus from '../../common/messaging/modal.bus';
import { IMenuItem, IMenuItemSize } from '../models/Item';
import {MODAL_ACTIONS} from '../../common/common.constants';
import {Util} from '../../common/services/Util';
import designMixin from '../../common/mixins/design.mixin';
import { ICategory } from '../stores/menu.store';
import { ICart } from '../../cart/cart.types';
import filtersMixin from '../../common/mixins/filters.mixin';

const moment = require('moment');

export default Vue.extend({
  mixins: [designMixin, filtersMixin],
  props: {
    cart: {
      type: Object as () => ICart,
      required: true
    },

    categoriesMap: {
      type: Object as () => { [key: string]: ICategory },
      required: true
    },

    currentTime: {
      type: String,
      required: true
    },

    isStepBuilder: {
      type: Boolean,
      required: true
    },

    item: {
      type: Object as () => IMenuItem,
      required: true
    },

    orderTypes: {
      type: Object,
      required: true
    },

    sizeMap: {
      type: Object,
      required: true
    },

    splitItems: {
      type: Array as () => Array<IMenuItem>,
      required: false
    },

    step: {
      type: Number,
      required: true
    },

    toppingsList: {
      type: Object,
      required: true
    },
  },
  computed: {
    _designService() {
      return designService;
    },

    _menuBus() {
      return menuBus;
    },

    _modalBus() {
      return modalBus;
    }
  },
  data() {
    return {
      _debouncer: 0
    };
  },
  methods: {
    changeQuantity(quantity: number, amount: number) {
      this.item.quantity = quantity + amount;

      if (this._debouncer)
        window.clearTimeout(this._debouncer);

      this._debouncer = window.setTimeout(
        () => this.$emit('setQuantity', {
          itemId: this.item.objectId,
          quantity: this.item.quantity
        }),
        750
      );
    },

    getSizesForItem(item: IMenuItem): any {
      let id;

      id = item.objectId;

      if (typeof id === 'string') {
        id = parseInt(id);

        if (!id) {
          id = item.itemId;

          if (typeof id === 'string')
            id = parseInt(id);
        }
      }

      if (!id)
        return false;

      return this.sizeMap[item.itemId];
    },

    getAvailableSplitItems(): IMenuItem[] {
      let items: IMenuItem[] = [];

      this.splitItems.forEach((item: IMenuItem) => {
        if (this._isItemAllowedForSplit(item))
          items.push(item);
      });

      return items;
    },

    showSplitItemChoices(): void {
      const sizes = this.getSizesForItem(this.item);

      if (sizes === false) {
        return;
      }

      const hasSizes = sizes && sizes.length > 0;
      let isMissingSize: boolean;

      if (this.item.hasDefaultSize || this.item.size.objectId || !hasSizes) {
        isMissingSize = false;
      } else {
        isMissingSize = true;
      }

      if (isMissingSize) {
        const message: string = 'Please select a size to continue.';

        this._modalBus.simpleMessageModal$.next({
          action: MODAL_ACTIONS.OPEN,
          message
        });
      } else {
        this._modalBus.splitItemChoicesModal$.next(MODAL_ACTIONS.OPEN);
      }
    },

    unsplitItem(): void {
      this.$emit('unsplitItem', this.item.objectId);
    },

    // Styling

    _validateAvailable(category: ICategory): boolean {
      return Util.validateAvailable(category, this.cart, this.currentTime);
    },

    _isItemAllowedForSplit(item: IMenuItem): boolean {
      if (this.sizeMap[item.itemId]) {
        return this.sizeMap[item.itemId].some((size: IMenuItemSize) => {
          return size.styleId === this.item.size.styleId && size.allowFraction
            && this._validateAvailable(this.categoriesMap[this.item.categoryId])
            && item.itemId !== this.item.itemId;
        });
      } else {
        return item.categoryId === this.item.categoryId && item.itemId !== this.item.itemId;
      }
    }
  }
});
