




























import Vue from 'vue';
import designService from '../services/design.service';
import storageService from '../services/storage.service';
import decodeText from '../filters/DecodeText';
import designMixin from '../mixins/design.mixin';
import { ICart } from '../../cart/cart.types';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import filtersMixin from '../mixins/filters.mixin';

export default Vue.extend({
    mixins: [designMixin, filtersMixin],
    props: {
        cart: { // TODO: this prop doesn't seem to be used at all
            type: Object as () => ICart,
            required: false
        },
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },
        restaurantCount: {
            type: Number,
            required: true
        },
        showActions: {
          type: Boolean,
          default: true
        }
    },

    computed: {
        // services

        _designService () {
            return designService;
        }
    },

    data () {
        return {
            isStoreRoute: false,
            showChangeLink: true
        }
    },

    created() {
        this.isStoreRoute = /^\/location$/.test(window.location.pathname);

        if (!!JSON.parse(storageService.sessionStorage.getItem('singleLocation'))) {
            this.showChangeLink = false;
        }
    },

    methods: {
        buildAddressString(): string {
            if (Object.keys(this.restaurant).length === 0)
                return;

            if (!this.restaurant.address)
                return;

            let addressString: string;

            if (this.restaurant.address.addressLine2) {
                addressString = `
                    ${this.restaurant.address.addressLine},
                    ${this.restaurant.address.addressLine2}`;
            } else {
                addressString = `${this.restaurant.address.addressLine}`;
            }

            return addressString;
        },

        buildAddressStringLine2(): string {
            if (Object.keys(this.restaurant).length === 0)
                return;

            if (!this.restaurant.address)
                return;

            let addressString: string = `
                ${this.restaurant.address.city},
                ${this.restaurant.address.stateCode},
                ${this.restaurant.address.postalCode}`;

            return addressString;
        },

        changeLocation(): void {
            this.$emit('changeRestaurant');
        },

        navigateToViewLocation(): void {
            this.$emit('viewLocation');
        },

        // Styling
        getSidebarStyle(): any {
            return this._designService.getSidebarStyle(false);
        },

        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        },

        getButtonClass(): any {
            return this._designService.getButtonClass();
        }
    }
})
