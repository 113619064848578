









































































































































import Vue from "vue";
import { IMenuItem, IMenuItemSize } from '../models/Item';
import { ICategory, IItemDesign } from '../stores/menu.store';
import SelectSizes from './SelectSizes.vue';
import { ADD_TO_CART_ACTIONS, Util } from '../../common/services/Util';
import itemCardMixin from '../mixins/item-card.mixin';
import { ICart } from '../../cart/cart.types';
import notificationService from '../../common/messaging/notification.service';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { OPEN_SCHEDULE_MODAL, SHOW_ALERT } from '../../common/messaging/notifications';
import designService from '../../common/services/design.service';
import { isItemInStock } from '../../cart/helpers/cart.helpers';

export default Vue.extend({
  mixins: [itemCardMixin],
  props: {
    cart: {
      type: Object as () => ICart,
      required: true
    },

    category: {
      type: Object as () => ICategory,
      required: false
    },

    currentTime: {
      type: String,
      required: true
    },

    defaultItemDesign: {
      type: Object as () => IItemDesign,
      required: true
    },

    restaurant: {
      type: Object as () => IRestaurant,
      required: true
    },

    sizes: {
      type: Array as () => Array<IMenuItemSize>,
      required: false
    }
  },
  name: 'ItemCardModern',
  components: { SelectSizes },
  data: () => ({
    noItemImg: false,
    quantity: 1,
    selectedSizeId: null
  }),
  computed: {
    // services
    _notificationService() {
      return notificationService;
    },

    _designService() {
      return designService;
    },

    defaultSizeId() {
      if (this.item.hasDefaultSize) {
        if (this.item.defaultSizeId) {
          return this.item.defaultSizeId;
        } else if (this.item.styles.length > 0) {
          const sizeStyle = this.item.styles.find(style => style.type.toLowerCase() === 'size');

          if (sizeStyle) {
            return sizeStyle.styleId;
          } else {
            return null;
          }
        } else {
          return null;
        }
      } else {
        return null;
      }
    },

    isCoupon() {
      return this.item.menuType === 'COUPON';
    },

    showCustomizeButtonLayout() {
      const hasSizes = this.sizes && this.sizes.length > 0;

      return this.restaurant.itemSelectedPostTask === 'ASK_USER' &&
        this.item.customizable &&
        !this.item.hasRequirements &&
        (hasSizes ? this.item.hasDefaultSize : true) &&

        // Show the "customize / add to cart" buttons if has a Default Style.
        // If no Default Style but HAS styles, then show only the Order Button (direct pass to Customize probably).
        (this.item.hasStyles ? this.item.hasDefaultStyle : true);

       // Only show the Order Button
    },

    shouldShowPrice() {
      return this.item.price && this.item.price > 0 && !this.isDisplaySizeChooser();
    },

    thumbnailSize() {
      return this._designService.getDesign().thumbnailSize;
    },

    thumbnailClassObject() {
      return {
        'rounded-full bg-secondary-100': this.hasImage,
        'w-20 h-20': this.thumbnailSize === 'DEFAULT',
        'w-28 h-28': this.thumbnailSize === 'MEDIUM',
        'w-36 h-36': this.thumbnailSize === 'LARGE'
      }
    }

  },
  methods: {
    forceAddToCart(sizeId?: string) {
      this.selectItem(sizeId, ADD_TO_CART_ACTIONS.FORCE_ADD);
    },

    forceCustomize(sizeId?: string) {
      this.selectItem(sizeId, ADD_TO_CART_ACTIONS.FORCE_CUSTOMIZE);
    },

    getSelectedSizeId() {
      return this.$refs.selectSizes ? this.getSizeId(this.$refs.selectSizes.selectedSize) : null;
    },

    getSizeId(size: IMenuItemSize) {
      return size ? size.sizeId : null;
    },

    isDisplaySizeChooser(): boolean {
      return this.defaultItemDesign && this.defaultItemDesign.displaySizeChooser &&
        this.sizes && this.sizes.length;
    },

    isItemInStock(item: IMenuItem): boolean {
      return isItemInStock(this.cart, item, null);
    },

    selectItem(sizeId?: string, addToCartAction = ADD_TO_CART_ACTIONS.COMPUTE) {
      let outOfStockMessage;

      if ((sizeId || this.defaultSizeId)
        && !isItemInStock(this.cart, this.item, [{ styleId: sizeId || this.defaultSizeId }])) {
        outOfStockMessage = 'That size is not in stock currently.';
      } else if ((this.item.defaultStyleId
        && !isItemInStock(this.cart, this.item, [{ styleId: this.item.defaultStyleId }]))
        || !isItemInStock(this.cart, this.item)) {
        addToCartAction = ADD_TO_CART_ACTIONS.FORCE_CUSTOMIZE;
      }

      if (outOfStockMessage) {
        this._notificationService.notify(
          SHOW_ALERT,
          {
            message: outOfStockMessage
          }
        );

        return;
      }

      if (!this.validateAvailable()) {
        this._notificationService.notify(
          SHOW_ALERT,
          {
            message: `That item is not available at the selected order time.
            <span class='please-schedule underline'>Click here to schedule your order for another time.</span>`,
            notification: OPEN_SCHEDULE_MODAL
          }
        );

        return;
      }

      if (this.isCoupon) {
        this.$emit('selectCoupon', this.item);
      } else {
        if (this.sizes && this.sizes.length === 1) {
          sizeId = this.getSizeId(this.sizes[0]);
        }

        this.$emit('selectItem', { addToCartAction, item: this.item, sizeId, quantity: this.quantity });
      }
    },

    validateAvailable(): boolean {
      return Util.validateAvailable(this.category, this.cart, this.currentTime);
    },

    selectSize(size) {
      this.selectedSizeId = this.getSizeId(size);
    }
  }
});
