























import Vue from 'vue';

export default Vue.extend({
  props: {
    alert: {
      type: Object,
      required: true,
      default: { show: false, message: '', notification: null }
    }
  },
  methods: {
    onClick() {
      if (this.alert.notification) {
        this.$emit('doAlertAction', this.alert);
      }
    }
  }
});
