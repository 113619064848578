























































import Vue from 'vue';
import BaseComponentMixin from '../../common/mixins/base-component.mixin';
import {IAddress} from '../../common/models/Address';
import storageService from '../../common/services/storage.service';
import RestaurantHours from './RestaurantHours.vue';
import {Util} from '../../common/services/Util';
import { IRestaurant } from '../types/restaurant.types';

const moment = require('moment');

export default Vue.extend({
    mixins: [BaseComponentMixin],
    components: { RestaurantHours },
    props: {
      currentTime: {
        type: String,
        required: false
      },

      restaurant: {
          type: Object as () => IRestaurant,
          required: true
      },

      restaurantCount: {
        type: Number,
        required: false
      },

      showMap: {
          type: Boolean,
          required: false
      },

      title: {
          type: String,
          required: false
      }
    },
    computed: {
        // getters

        showChangeLink (): boolean {
          return !JSON.parse(storageService.sessionStorage.getItem('singleLocation'));
        },

        // styling

        textColor(): string {
          return this._designService.popupMainTextColor;
        }
    },
    methods: {
        getCityStatePostalCodeText(): string {
            let address: IAddress = this.restaurant.address;

            return address ? `${address.city}, ${address.stateCode} ${address.postalCode}` : '';
        },

        chooseRestaurant(): void {
            this.$emit('changeRestaurant');
        },

        getRestaurantTime(): string {
            return Util.formatDate(moment(this.currentTime));
        }
    }
});
