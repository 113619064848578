import { VueRecaptcha } from 'vue-recaptcha';
import recaptchaService  from '../services/recaptcha.service';
import { RESTAURANTS_STORE } from '../../restaurants/stores/restaurants.store';
import cart from '../../cart/models/Cart';

declare var CONSTANTS: any;

declare var window: any;

export default {
  components: {
    VueRecaptcha
  },
  computed: {
    // services
    recaptchaService() {
      return recaptchaService;
    },

    recaptchaSiteKey() {
      // Use Google's debug test key if running through Cypress tests
      // https://developers.google.com/recaptcha/docs/faq#id-like-to-run-automated-tests-with-recaptcha.-what-should-i-do
      return window.Cypress ? '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI' : CONSTANTS.RECAPTCHA_SITE_KEY;
    },

    // getters
    cartId() {
      return cart.cart.objectId;
    },
    restaurantId() {
      return this.$store.state[RESTAURANTS_STORE].restaurant.objectId;
    }
  },

  data() {
    return {
      recaptchaRendered: false,
      performingRecaptcha: false,
      callback: null
    };
  },

  methods: {
    performRecaptcha(callback) {
      this.resetRecaptcha();
      this.callback = callback;
      this.performingRecaptcha = true;
      this.$refs.invisibleRecaptcha.execute();
    },

    onRecaptchaVerify(response) {
      this.recaptchaService.verify({
        locationId: this.restaurantId,
        cartId: this.cartId,
        responseToken: response
      }).then(() => {
        const callback = this.callback;
        if (typeof callback === 'function') {
          callback();
        } else {
          this.onRecaptchaSuccess();
        }
      }).catch(error => {
        this.onRecaptchaFailed(error);
      });
      this.performingRecaptcha = false;
    },

    // Override this in component to do something with this after response
    onRecaptchaExpired() {
      console.log('recaptcha expired');
      this.performingRecaptcha = false;
    },

    onRecaptchaRendered() {
      this.recaptchaRendered = true;
    },

    // Override this in component to do something with this after response
    onRecaptchaSuccess() {
      console.log('recaptcha successfully verified');
    },

    onRecaptchaFailed(error) {
      console.log('recaptcha failed');
    },

    resetRecaptcha() {
      this.$refs.invisibleRecaptcha.reset(); // Direct call reset method
      this.performingRecaptcha = false;
    },
  }
};
