























































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import {IMenuItem} from '../models/Item';
import SplitItemCard from './SplitItemCard.vue';
import { ICategory } from '../stores/menu.store';
import notifierService from '../../common/services/notifier.service';
import filtersMixin from '../../common/mixins/filters.mixin';
const sortBy = require('lodash/sortBy');

export default Vue.extend({
    mixins: [designMixin, filtersMixin],
    components: {
        SplitItemCard
    },
    props: {
        category: {
            type: Object as () => ICategory,
            required: true
        },
        open: {
            type: Boolean,
            required: false,
            default: false
        },
        item: {
            type: Object as () => IMenuItem,
            required: true
        },
        splitItems: {
            type: Array as () => Array<IMenuItem>,
            required: true
        }
    },
    computed: {
        buttonStyle() {
            return {
                'background-color': this.buttonBackgroundColor,
                color: this.buttonTextColor,
                'border-color': this.buttonBorderColor
            };
        },

        selectedSplit() {
          return this.splitItems.find(split => this.isSplitItemSelected(split));
        },

        sortedItems() {
          return sortBy(this.splitItems, ['categoryId', 'sortNumber']);
        }
    },
    data() {
        return {
            isOpen: false
        };
    },
    created() {
        this.isOpen = this.open;
    },
    methods: {
        toggleOpen() {
          if(this.item.size.objectId) {
            this.isOpen = !this.isOpen;
          } else {
            notifierService.error('Please select a size before selecting a 2nd half.',
              'NO_SIZE');
            this.isOpen = true;
          }
        },

        isSplitItemSelected(splitItem: IMenuItem) {
            if (!this.item.split || !this.item.split.right)
                return false;

            return this.item.split.right.objectId === splitItem.objectId;
        },

        unsplitItem() {
            this.$emit('unsplit', this.item);
        },

        onSelect(item: IMenuItem) {
            if (this.item.split && this.item.split.right.objectId === item.objectId) {
                this.unsplitItem();

                return;
            }

            this.isOpen = false;

            this.$emit('select', item);
        }
    }
});
