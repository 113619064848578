










import Vue from 'vue';
import designService from '../../common/services/design.service';
import environmentService from '../../common/services/environment.service';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurants from '../../restaurants/models/Restaurants';

export default Vue.extend({
    mixins: [baseMixin],
    computed: {
        // services

        _designService () {
            return designService;
        },

        environment () {
            return environmentService;
        },

        restaurants() {
          return restaurants;
        },

        // getters

        isMobile(): boolean {
            return this.environment.isMobile;
        },

        loyaltyProgramName() {
            return restaurants.loyaltySettings.programName;
        }
    },
    data() {
        return {
            initialized: false
        };
    },
    mounted () {
        if ($('.modal-backdrop').length > 0) {
            $('.modal-backdrop').remove();
            $('body').removeClass('modal-open');
        }

        window.scrollTo(0, 0);
        this.initialized = true;
    },
    methods: {
        // Styling
        getHeaderBar(padding: number): any {
            let style: any = {
                'background-color': this._designService.backgroundColor,
                'color': this._designService.tertiaryColor,
                'font-family': this._designService.getDesign().fontHeadline
            };

            if (padding)
                style['padding'] = padding + 'px ' + padding + 'px';

            return style;
        }
    }
});
