






























































































import Vue from 'vue';
import InputSelect from '../../common/components/InputSelect.vue';
import order from '../models/Order';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurants from '../../restaurants/models/Restaurants';
import environmentService from '../../common/services/environment.service';
import SelectedRestaurant from '../../common/components/SelectedRestaurant.vue';
import '@fortawesome/fontawesome-pro';

const vehicleColors = ['Black', 'Blue', 'Brown', 'Gold', 'Gray', 'Green', 'Orange', 'Red', 'Silver', 'Tan', 'White', 'Yellow'];
const vehicleTypes = ['Car', 'Motorcycle', 'SUV', 'Truck', 'Van'];

export default Vue.extend({
  mixins: [baseMixin],

  components: {
    InputSelect,
    SelectedRestaurant
  },

  computed: {
    orderId() {
      return environmentService.orderId;
    },

    phone() {
      return restaurants.selectedRestaurant.address.phone;
    },

    restaurant() {
      return restaurants.selectedRestaurant;
    }
  },

  data() {
    return {
      attemptedSubmit: false,
      isSuccessful: false,
      submitted: false,
      vehicleColor: null,
      vehicleColors,
      vehicleType: null,
      vehicleTypes,
      vehicleLocation: null // string
    };
  },
  methods: {
    submit() {
      this.attemptedSubmit = true;

      if (!this.vehicleColor || !this.vehicleType) {
        return;
      }

      const promise = order.confirmCurbside(this.vehicleColor, this.vehicleType, this.vehicleLocation);

      if (promise) {
        promise.then(() => {
          this.isSuccessful = true;
        });
      } else {
        return;
      }

      this.submitted = true;
    }
  }
});
