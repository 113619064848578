

































import Vue from 'vue';
import designMixin from '../mixins/design.mixin';
import ModalModern from './ModalModern.vue';

export default Vue.extend({
  mixins: [designMixin],
  components: {
    ModalModern
  },
  props: {
    canCancel: Boolean
  },
  computed: {
    // styling

    buttonClass() {
      return ['btn', 'btn-primary'];
    },
  },
  data() {
    return {
      errors: null // string[]
    };
  },
  methods: {
    open(errors: string[]) {
      this.errors = errors;

      this.$refs.modal.open();
    },

    cancel() {
      this.$emit('cancel');

      this.close();
    },

    close() {
      this.errors = null;

      this.$emit('close');

      this.$refs.modal.close();
    }
  }
});
