








































































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import {ISuggestionItem} from '../../common/models/Suggestion';
import menuBus, { ITrackSuggestionRequest } from '../menu.bus';
import { IMenuItemSize } from '../models/Item';
import suggestionService from '../../common/services/suggestion.service';
import ModalModern from '../../common/components/ModalModern.vue';
import { IItemDesign } from '../stores/menu.store';
import cart from '../../cart/models/Cart';
import { ICart } from '../../cart/cart.types';
import restaurant from '../../restaurants/models/Restaurants';
import filtersMixin from '../../common/mixins/filters.mixin';

export default Vue.extend({
    mixins: [designMixin, subscriberMixin, filtersMixin],
    components: { ModalModern },
    props: {
        cart: {
            type: Object as () => ICart,
            required: true
        },
        defaultItemDesign: {
            type: Object as () => IItemDesign,
            required: false
        },
        sizeMap: {
            type: Object,
            required: true
        },
        suggestions: {
            type: Array as () => Array<ISuggestionItem>,
            required: false
        }
    },
    computed: {
        // services

        _menuBus() {
            return menuBus;
        },

        suggestionService() {
            return suggestionService;
        },

        // styling

        bodyStyle() {
            return this.modalHeaderStyle;
        }
    },
    created() {
        this.subscribe(this._menuBus.showSuggestions$, () => {
            this.open();
        });
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        // getters

        getSizes(suggestion) {
          return this.sizeMap[suggestion.objectId];
        },

        getTitle() {
            let result;

            if (this.suggestions) {
                const settings = restaurant.selectedRestaurant.suggestionSettings;

                if (settings) {
                    const isPersonalized = this.suggestions.find(suggestion => suggestion.personalized);

                    if (isPersonalized) {
                        result = settings.personalized.message;
                    } else {
                        result = settings.specified.message;
                    }
                }
            }

            if (!result) {
                result = 'You Might Also Enjoy';
            }

            return result;
        },

        _getSourceItemId(): string {
            return cart.selectedItem.itemId;
        },

        // event handlers

        addSuggestion(suggestion: ISuggestionItem, size: IMenuItemSize): void {
            this.suggestionService.addSuggestion(suggestion, size);

            this.close();
        },

        rejectSuggestion(suggestion: ISuggestionItem): void {
            this.suggestionService.rejectSuggestion(suggestion);

            this.close();
        }
    }
});
