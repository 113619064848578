













































































































































































































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import ReviewOrderDetails from './ReviewOrderDetails.vue';
import AddTip from './AddTip.vue';
import OutOfStockModal from './OutOfStockModal.vue';
import RequirementOutOfStockModal from './RequirementOutOfStockModal.vue';
import SuggestionsModal from '../../menu/components/SuggestionsModal.vue';
import restaurants from '../../restaurants/models/Restaurants';
import order from '../models/Order';
import { IFulfilledCoupon, IMenuItem, IRequiredItem } from '../../menu/models/Item';
import designService from '../../common/services/design.service';
import modalService from '../../common/services/modal.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import CartCoupon from '../../cart/components/CartCoupon.vue';
import CartItem from '../../cart/components/CartItem.vue';
import {routerLink} from '../../common/directives/RouterLink';
import menuBus from '../../menu/menu.bus';
import suggestionService from '../../common/services/suggestion.service';
import menu from '../../menu/models/Menu';
import {ITenderRequest} from '../models/TenderRequest';
import {ISuggestionItem} from '../../common/models/Suggestion';
import routeService from '../../common/services/route.service';
import { IUser } from '../../profile/stores/profile.store';
import cart from '../../cart/models/Cart';
import { Util } from '../../common/services/Util';
import analyticsManager from '../../common/services/analytics-manager.service';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { attemptToCheckOut } from '../helpers/order.helpers';
import notificationService from '../../common/messaging/notification.service';
import notifierService from '../../common/services/notifier.service';
import { SHOW_ALERT } from '../../common/messaging/notifications';
import { ORDER_TYPES } from '../../cart/cart.types';
import { isItemInStock, isRequiredItemInStock } from '../../cart/helpers/cart.helpers';
import { removeEmojis } from '../../common/helpers/text.helpers';
const _ = require('lodash');

export interface IOutOfStockRequirement {
  parentItemObjectId: string;
  parentItemName: string;
  requiredItemName: string;
}

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    AddTip,
    CartCoupon,
    CartItem,
    OutOfStockModal,
    RequirementOutOfStockModal,
    ReviewOrderDetails,
    SuggestionsModal
  },
  directives: {
    routerLink
  },
  props: {
    cartError: {
      type: String,
      required: false
    },
    currentTime: {
      type: String,
      required: true
    },
    isAddingCoupon: {
      type: Boolean,
      required: true
    },
    restaurantCount: {
      type: Number,
      required: true
    },
    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    },
    tenderRequest: {
      type: Object as () => ITenderRequest,
      required: true
    },
    updating: {
      type: Boolean,
      required: true
    },
    user: {
      type: Object as () => IUser,
      required: false
    }
  },
  computed: {
    // models

    _cartHelper() {
      return cartHelperService;
    },

    _designService() {
      return designService;
    },

    _menuBus() {
      return menuBus;
    },

    _modalService() {
      return modalService;
    },

    menu() {
      return menu;
    },

    order() {
      return order;
    },

    restaurants() {
      return restaurants;
    },

    suggestionService() {
      return suggestionService;
    },

    // getters

    cart() {
      return cart.cart;
    },

    cartItemCount(): number {
      const cart = this.cart;

      if (cart && cart.items) {
        return cart.items.reduce((count, item: IMenuItem) => {
          count += item.quantity;

          return count;
        }, 0);
      } else {
        return 0;
      }
    },

    defaultItemDesign() {
      return this.menu.defaultItemDesign;
    },

    isDelivery(): boolean {
      return this.cart.orderType === ORDER_TYPES.DELIVERY;
    },

    isHostedPaymentEnabled(): boolean {
      return this.selectedRestaurant && this.selectedRestaurant.thrivePaymentsEnabled;
    },

    isSchedulingAllowed(): boolean {
      return this.selectedRestaurant.allowScheduling;
    },

    sizeMap() {
      return this.restaurants.sizeMap;
    },

    suggestions() {
      return this.menu.currentSuggestions;
    },

    orderName: {
      get () {
        return this.cart.orderName;
      },

      set (orderName) {
        if (orderName) {
          this.readySetOrderName(orderName);
        }
      }
    },

    validations() {
      return this.cart.validations;
    }
  },
  data() {
    return {
      _debouncer: 0,
      couponCode: '',
      couponToRemove: null, // IFulfilledCoupon;
      displayOutOfStockRequirement: null, // IOutOfStockRequirement
      modalSubscription: null, // ISubscription;
      outOfStockItems: [], // IMenuItem[] = [];
      outOfStockRequirements: [] // IOutOfStockRequirement[] = [];
    };
  },
  mounted() {
    analyticsManager.track('Cart Review', {
      cartId: this.cart.objectId,
      locationId: this.selectedRestaurant.objectId,
      locationName: this.selectedRestaurant.name,
      orderTotal: this.cart.grandTotal
    });

    this._checkForOutOfStockItems();
    this._checkForOutOfStockRequirements();
    this._validateCartCoupons();

    this.suggestionService.checkForSuggestions(null, this.restaurants.selectedRestaurant).catch(() => {
      return;
    });
  },
  methods: {
    closedOutOfStockModal(): void {
      if (this.cart.items.length === 0) {
        this.navigateToMenu();
      }
    },

    closedRequirementOutOfStockModal(): void {
      this.outOfStockRequirements = [
        ...this.outOfStockRequirements.slice(1)
      ];

      this._checkForOutOfStockRequirements();
    },

    readySetOrderName: _.debounce(function(orderName: string) {
      this.$emit('setOrderName', orderName);
    }, 400),

    checkout() {
      this.tenderRequest.comments = removeEmojis(this.tenderRequest.comments);
      this.orderName = removeEmojis(this.orderName);

      if (this.isAddingCoupon) {
        return Util.waitUntil(() => !this.isAddingCoupon).then(() => {
          return this.checkout();
        });
      }

      const result = cartHelperService.reviewOrder(false, false);

      if (result.success) {
        cart.fetchBySession().then(() => {
          const result = attemptToCheckOut(
            this.cart,
            this.tenderRequest,
            this.selectedRestaurant,
            this.currentTime,
            (error: string) => {
              analyticsManager.track('Checkout Blocked At Order Review', {
                cartId: this.cart.objectId,
                error,
                locationId: this.selectedRestaurant.objectId,
                locationName: this.selectedRestaurant.name,
                user: this.user
              });

              this.$emit('showAlert', error);
            },
            () => this._modalService.selectDeliveryModal(),
            () => {
              modalService.openScheduleOrderModal(false);
            },
            (message: string) => {
              notificationService.notify(SHOW_ALERT, { message });
            },
            (error: string) => {
              notifierService.error(error);
            }
          );

          if (result) {
            routeService.route('Checkout');
          }
        });
      }
    },

    chooseTip(tipAmount: number) {
      if (tipAmount) {
        this.$emit('addTip', this.tenderRequest);
      }
    },

    confirmRemoveCoupon(coupon: IFulfilledCoupon) {
      this.couponToRemove = coupon;
      this._modalService.removeCouponModal(this.couponToRemove);

      this.modalSubscription = this._modalService.removeCoupon$.subscribe(
        (removalCoupon: IFulfilledCoupon) => {
          this.confirmRemoval(removalCoupon);
        });
    },

    confirmRemoval(couponInfo: IFulfilledCoupon) {
      if (this.modalSubscription) {
        this.modalSubscription.unsubscribe();
        this.modalSubscription = null;
      }

      this.$emit('removeCoupon', couponInfo.couponObjectId);
    },

    isRequiredItemInStock(item: IMenuItem, requiredItem: IRequiredItem): boolean {
      return isRequiredItemInStock(this.cart, item, requiredItem);
    },

    submitCouponCode() {
      if (this.couponCode) {
        this.couponCode = removeEmojis(this.couponCode);

        this.$emit('addCouponCode', this.couponCode);
        this.couponCode = '';
      }
    },

    getOrderMessageEnabled(): boolean {
      return this.selectedRestaurant.communicationSettings.orderMessageEnabled;
    },

    getOrderMessage(): string {
      if (!this.selectedRestaurant.communicationSettings || !this.selectedRestaurant.communicationSettings.orderMessage) {
        return '';
      }

      return this.selectedRestaurant.communicationSettings.orderMessage;
    },

    keepAndEditItem(parentItemObjectId: string): void {
      this.$emit('editItem', this._findItemByObjectId(parentItemObjectId));
    },

    changeQuantity(event) {
      this.$emit('setQuantity', event);
    },

    changeItem(event) {
      this.$emit('editItem', event);
    },

    deleteItem(event) {
      this.$emit('removeItem', event);
    },

    deleteCoupon(event) {
      this.$emit('removeCoupon', event);
    },

    clearCart() {
      this.$emit('clearCart', this.cart.items.map(i => i.objectId));
    },

    // suggestions

    addSuggestion(suggestion: ISuggestionItem): void {
      this.suggestionService.addSuggestion(suggestion);
    },

    rejectSuggestion(suggestion: ISuggestionItem): void {
      this.suggestionService.rejectSuggestion(suggestion);
    },

    // emitters

    emitChangeRestaurant($event) {
      this.$emit('changeRestaurant', $event);
    },

    emitChooseType($event) {
      this.$emit('chooseType', $event);
    },

    emitRemoveDeliveryZone($event) {
      this.$emit('removeDeliveryZone', $event);
    },

    emitSaveAddress($event) {
      this.$emit('saveAddress', $event);
    },

    emitSetDeliveryZone($event) {
      this.$emit('setDeliveryZone', $event);
    },

    emitSetTime($event) {
      this.$emit('setTime', $event);
    },

    emitSuggestedAddresses($event) {
      this.$emit('suggestedAddresses', $event);
    },

    scheduleAsap($event) {
      this.$emit('asap', $event);
    },

    emitRemoveItem(id) {
      this.$emit('removeItem', id);
    },

    // Styling

    // Private Methods
    _checkForOutOfStockItems(): void {
      this.outOfStockItems = this.cart.items.filter((item: IMenuItem) => !isItemInStock(this.cart, item));

      if (this.outOfStockItems.length > 0) {
        const outOfStockIds: string[] = this.outOfStockItems.map(i => i.objectId);
        this.$emit('removeItems', outOfStockIds);
        this.$refs.outOfStockModal.open();
        this._designService.setModalStyling();
      }
    },

    _checkForOutOfStockRequirements(): void {
      this.cart.items.forEach((item: IMenuItem) => {
        this.outOfStockRequirements = [
          ...this.outOfStockRequirements,
          ...this._getOutOfStockRequirements(item)
        ];
      });

      if (this.outOfStockRequirements.length > 0)
        this._displayRequirementOutOfStock();
    },

    _getOutOfStockRequirements(item: IMenuItem): IOutOfStockRequirement[] {
      return item.requiredItemsFlat
        .filter(r => r.itemId !== null)
        .filter(r => !this.isRequiredItemInStock(item, r))
        .map(r => {
          return {
            parentItemObjectId: item.objectId,
            parentItemName: item.name,
            requiredItemName: r.name
          };
        });
    },

    _displayRequirementOutOfStock(): void {
      this.displayOutOfStockRequirement = this.outOfStockRequirements[0];
      this.requirementOutOfStockModal.open();
      this._designService.setModalStyling();
    },

    _findItemByObjectId(objectId: string): IMenuItem {
      return this.cart.items.filter(i => i.objectId === objectId)[0];
    },

    _validateCartCoupons() {
      if (this.validations.length === 0) {
        return;
      }

      for (let i = 0; i < this.validations.length; i++) {
        this.$emit('showAlert', this.validations[i].message);
      }
    },
  },
  watch: {
    validations(value, oldValue) {
      if (value.length > 0 && oldValue.length === 0) {
        for (let i = 0; i < value.length; i++) {
          this.$emit('showAlert', value[i].message);
        }
      } else if (value.length === 0) {
        this.$emit('clearAlert');
      }
    }
  }
});
