





































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import CustomizeItem from '../components/CustomizeItem.vue';
import account from '../../account/models/Account';
import cart from '../../cart/models/Cart';
import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
import menu from '../models/Menu';
import restaurants from '../../restaurants/models/Restaurants';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import menuBus from '../menu.bus';
import {
    IMenuItem,
    IRequiredItem,
    IRequiredOption,
    ITopping
} from '../models/Item';
import {IStyle} from '../../coupons/models/Coupon';
import {SPLIT_SIDES, VALIDATIONS} from '../menu.constants';
import suggestionService from '../../common/services/suggestion.service';
import {navigateToMenu} from '../../common/mixins/base-component.mixin';
import { ICart, IValidation } from '../../cart/cart.types';
import ToppingsManager from '../services/toppings-manager.service';
import { canLeaveItemCustomization } from '../helpers/item-customization.helpers';
import { SESSION_EXPIRED } from '../../profile/models/Profile';
import { CART_STORE } from '../../cart/stores/cart.store';
import { IRestaurant } from '../../restaurants/types/restaurant.types';

const lodashGet = require('lodash.get');

export default Vue.extend({
    mixins: [baseMixin],
    components: { CustomizeItem },
    computed: {
        // services

        _menuBus () {
            return menuBus;
        },

        _notifierService () {
            return notifierService;
        },

        account () {
            return account;
        },

        cart () {
            return cart;
        },

        couponWalkthrough () {
            return couponWalkthrough;
        },

        menu () {
            return menu;
        },

        restaurants () {
            return restaurants;
        },

        routeService () {
            return routeService;
        },

        suggestionService() {
            return suggestionService;
        },

        // getters

        accountDesign() {
            return account.design;
        },

        cartObject() {
            return this.cart.cart;
        },

        categories() {
            return this.menu.categories;
        },

        couponGroup() {
            return couponWalkthrough.couponGroup;
        },

        currentTime() {
            return restaurants.currentTime;
        },

        defaultItemDesign() {
            return menu.defaultItemDesign;
        },

        item() {
            return cart.selectedItem;
        },

        itemDesign() {
            return menu.itemDesign;
        },

        itemDesigns() {
            return menu.itemDesigns;
        },

        mainToppingsList() {
            return this.menu.selectedToppingsList;
        },

        restaurant() {
            return restaurants.selectedRestaurant;
        },

        selectedCoupon() {
            return couponWalkthrough.selectedCoupon;
        },

        shouldCheckForSuggestions: {
          get() {
            return this.$store.state.cart.shouldCheckForSuggestions;
          },

          set(value) {
            this.$store.state.cart.shouldCheckForSuggestions = value;
          }
        },

        sizeMap() {
            return restaurants.sizeMap;
        },

        splitItems() {
            return this.menu.splitItemChoices;
        },

        splitToppingsList() { // TODO: test
            return this.menu.splitToppingsList;
        },

        styleList() {
            return menu.selectedStyleList;
        },

        toppingsList(): any {
            if (this.item.alreadySplit && this.splitSide && this.splitToppingsList) {
                if (this.splitSide === SPLIT_SIDES.LEFT) {
                    if (this.item.onlyIncludedModifiers) {
                      return this.toppingsManager.getOnlyIncludedToppings(this.mainToppingsList);
                    }
                    return this.mainToppingsList;
                } else {
                    if (this.item.split.right.onlyIncludedModifiers) {
                      // update item so split is defined
                      this.toppingsManager.item = this.item;
                      return this.toppingsManager.getOnlyIncludedToppings(this.splitToppingsList, SPLIT_SIDES.RIGHT);
                    }
                    return this.splitToppingsList;
                }
            } else {
                if (this.item.onlyIncludedModifiers) {
                  return this.toppingsManager.getOnlyIncludedToppings(this.mainToppingsList);
                }
                return this.mainToppingsList;
            }
        },

        updating() {
            return cart.updating;
        },

        isEditingCheckoutItem() {
          return this.$store.state.cart.editingCheckoutItem;
        }
    },

    data() {
        return {
            clearSplitItemChoicesMQ: null,
            finishItemCustomizationMQ: null,
            isCancelled: false,
            loadSplitItemChoicesMQ: null,
            removing: false,
            screenWidth: null,
            splitSide: null,
            updateRequirementsMQ: null,
            toppingsManager: null
        }
    },

    created () {
        this._initMessageQueues();

        this.toppingsManager = new ToppingsManager(this.item, null);

        if (!this.isEditingCheckoutItem) {
          const { maxAllowed, toppingCount } = this.item;

          this.cart.setMaxToppingsData({
            [this.item.objectId]: {
              inclusionsCount: this.toppingsManager.toppings.length,
              allowedToppings: maxAllowed ? maxAllowed - toppingCount : toppingCount,
            }
          });
        }

        document.addEventListener(SESSION_EXPIRED, this.onSessionExpire);
    },

    methods: {
        addNoRequiredItem(payload: {requirementOption: IRequiredOption, item: IMenuItem, parentId: string}) {
            this.cart.addNoRequiredItem(payload.requirementOption.objectId, payload.parentId);
        },

        removeAllRequiredItemInstances(payload: { requiredItem: IRequiredItem, parentInstanceId: string}): void {
            this.cart.removeAllRequiredItemInstances(payload);
        },

        onCancel() {
          this.isCancelled = true;

          let returnRoute = this.routeService.getReturnRoute();

          if (returnRoute && 'OrderReview' === returnRoute.route) {
            this.goBack();
          } else {
            this.removeItem();
          }
        },

        removeItem() {
          this.isCancelled = true;

          let returnRoute = this.routeService.getReturnRoute();

          if (returnRoute) {
            if ('SelectCouponItem' === returnRoute.route) {
              this.cart.removeItem(this.item.objectId);
              this.routeService.clearReturnRoute();
              this.routeService.route(returnRoute.route, returnRoute.routeData);
            }
          } else {
            navigateToMenu();

            this.cart.removeItem(this.item.objectId);
          }
        },

        onRemoveItem() {
          this.removeItem();
        },

        removeRequiredItem(itemObjectId: string) {
            this.cart.removeItem(itemObjectId);
        },

        setQuantity(payload: { itemId: string, quantity: number }) {
            this.cart.setQuantity(payload.itemId, payload.quantity);
        },

        updateSize(payload: { itemId: string, sizeId: string }) {
            this.cart.updateSize(payload.itemId, payload.sizeId);

            const style = this.getNonSizeStyle(this.item);

            this.menu.loadToppingsList(
                this.item.itemId,
                payload.sizeId,
                style ? style.styleId : null
            );
        },

        updateStyle(payload: { itemId: string, styleId: string }) {
            this.cart.updateStyle(payload.itemId, payload.styleId);

            this.menu.loadToppingsList(
                this.item.itemId,
                this.item.size ? this.item.size.styleId : null,
                payload.styleId
            );
        },

        onUpdateToppings(toppings: ITopping[]) {
            if (toppings) {
                this.cart.updateToppings(this.item.objectId, toppings);
            }
        },

        getNonSizeStyle(item: IMenuItem): IStyle {
            return item.styles.find(style => style.type.toLowerCase() !== 'size');
        },

        updateRequirements(requirements: IRequiredItem[]): void {
            this.cart.updateRequirements(this.item.objectId, requirements);
        },

        toggleSplitSide(side: string): void {
            this.splitSide = side;
        },

        onFinishItemCustomization(item: IMenuItem, comments?: string) {
            const returnRoute = this.routeService.getReturnRoute();

            if ((item.hasRequirements || item.requiredOptions.length > 0)
              && !this._hasPassedValidations(this.cartObject)) {
                navigateToMenu().catch(() => {
                  return;
                });

                return false;
            }

            if (returnRoute) {
                if ('SelectCouponItem' === returnRoute.route) {
                    this.couponWalkthrough.selectCouponItem(
                        item,
                        returnRoute.routeData.itemNumber,
                        returnRoute.payload.group
                    );
                }

                // if user is editing this item and it has invalid coupons now, remove them
                if (this.isEditingCheckoutItem) {
                  const invalidCouponIds: string[] = this.cartObject.validations.filter((validation: IValidation) => {
                    return validation.type === VALIDATIONS.COUPON;
                  }).map(v => v.objectId);

                  if (invalidCouponIds.length > 0) {
                    this.cart.removeItems(invalidCouponIds, this.cartObject.objectId);
                  }
                }
                if (comments !== null) {
                  this.cart.addCommentsToItem(item.objectId, comments).then(() => {
                    this.routeService.route(returnRoute.route, returnRoute.routeData);
                  });
                } else {
                  this.routeService.route(returnRoute.route, returnRoute.routeData);
                }
            } else {
                this.shouldCheckForSuggestions = true;

                const done = () => {
                  window.setTimeout(() => {
                    if (this._hasPassedValidations(this.cartObject)) {
                      this.cart.showAutoApplyCouponMessages(item);
                      this._notifierService.success(
                        item.name + ' has been added to the cart!');
                    }
                  }, 300);
                };

                navigateToMenu().then(() => {
                  if (comments !== null) {
                    this.cart.addCommentsToItem(item.objectId, comments).then(() => {
                      done();
                    });
                  } else {
                    done();
                  }
                }).catch(() => {
                  return;
                });
            }
        },

        onSessionExpire() {
          this.isCancelled = true;
        },

        onSplitItem(payload: { item: IMenuItem, splitItemId: string }) {
            if (payload !== null) {
                this.cart.splitItem(payload);

                this.splitSide = SPLIT_SIDES.RIGHT;
            }
        },

        onUnsplitItem() {
            this.cart.unsplitItem(this.item.objectId);

            this.splitSide = null;
        },

        onUpdateRequirements(payload: IRequiredItem[]) {
            if (payload) {
                this.updateRequirements(payload);
            }
        },

        // Private functions

        _hasPassedValidations(cart: ICart): boolean {
            const validations: IValidation[] = cart.validations.filter((validation: IValidation) => {
                return validation.type === VALIDATIONS.REQUIRED || validation.type === VALIDATIONS.STYLE;
            });

            return validations.length === 0;
        },

        _cleanUpMessageQueues(): void {
            if (this.clearSplitItemChoicesMQ) {
                this.clearSplitItemChoicesMQ.unsubscribe();
            }

            if (this.finishItemCustomizationMQ) {
                this.finishItemCustomizationMQ.unsubscribe();
            }

            if (this.loadSplitItemChoicesMQ) {
                this.loadSplitItemChoicesMQ.unsubscribe();
            }
        },

        _initMessageQueues(): void {
            this.clearSplitItemChoicesMQ = this._menuBus.clearSplitItemChoices$.subscribe(() => {
              this.menu.clearSplitItemChoices();
            });

            this.loadSplitItemChoicesMQ = this._menuBus.loadSplitItemChoices$.subscribe(
                (payload: string) => {
                    if (payload) {
                        this.menu.loadItemsForSplit(payload);
                    }
                }
            );
        }
    },

    beforeRouteLeave(to, from, next) {
      if (this.isCancelled) {
        next();

        return;
      }

      if (canLeaveItemCustomization(to, this.cartObject, this.item, this.restaurant,
        this.removing, this.$store.state.cart.shouldCheckForSuggestions,
        this.$store.state.cart.editingCheckoutItem,
        value => this.$store.commit(CART_STORE + '/SET_EDITING_CHECKOUT_ITEM', value),
        routeService.mustRoute(),
        () => routeService.clearReturnRoute(),
        routeService.fromSuggestion,
        value => routeService.fromSuggestion = value,
        (item?: IMenuItem, restaurant?: IRestaurant) => suggestionService.checkForSuggestions(item, restaurant),
        (cartId: string, itemId: string) => menu.loadSuggestions(cartId, itemId),
        (message: string, type: string, validation: IValidation) => notifierService.error(message, type, validation))) {
        next();
      } else {
        next(false);
      }
    },

    destroyed () {
      document.removeEventListener(SESSION_EXPIRED, this.onSessionExpire);

      this._cleanUpMessageQueues();
    }
});
