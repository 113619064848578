








import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import RestaurantView from '../components/RestaurantView.vue';
import restaurantLoader from '../services/restaurant-loader.service';
import restaurants from '../models/Restaurants';

export default Vue.extend({
    mixins: [baseMixin],
    components: {RestaurantView},
    props: {},
    computed: {
        // models

        restaurants () {
            return restaurants;
        },

        restaurantLoader () {
            return restaurantLoader;
        },

        // getters

        currentTime() {
            return this.restaurants.currentTime;
        },

        restaurant() {
          return this.restaurants.selectedRestaurant;
        }
    },
    methods: {
        loadData(): boolean {
            if (this.isInitialized) {
                this.restaurantLoader.reloadSelectedRestaurant();
            }

            return true;
        }
    }
});
