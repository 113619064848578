











































import Vue from 'vue';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import OrderHistory from '../components/OrderHistory.vue';
import order from '../models/Order';
import StyledModal from '../../common/components/StyledModal.vue';
import cart from '../../cart/models/Cart';
import restaurants from '../../restaurants/models/Restaurants';
import designService from '../../common/services/design.service';
import {navigateToMenu} from '../../common/mixins/base-component.mixin';
import designMixin from '../../common/mixins/design.mixin';
import { IMenuItem } from '../../menu/models/Item';
import { isItemInStock } from '../../cart/helpers/cart.helpers';

export default Vue.extend({
  mixins: [subscriberMixin, designMixin],
  components: {
    OrderHistory,
    StyledModal
  },
  computed: {
    // services

    cart() {
      return cart;
    },

    designService() {
      return designService;
    },

    order() {
      return order;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    restaurant() {
      return this.restaurants.selectedRestaurant;
    },

    orders() {
      return this.order.historicalOrders;
    },

    // refs

    outOfStockModal() {
      return this.$refs.outOfStockModal;
    }
  },
  data() {
    return {
      outOfStockItems: null // IMenuItem[];
    };
  },
  methods: {
    chooseOrder(cartId: string) {
      this.cart.reorder(cartId).then(() => {
        navigateToMenu();
      }).catch(() => {
        this.outOfStockItems = this.cart.cart.items.filter((item: IMenuItem) => !isItemInStock(this.cart.cart, item));

        if (this.outOfStockItems.length > 0) {
          const outOfStockIds: string[] = this.outOfStockItems.map(i => i.objectId);
          this.cart.removeItems(outOfStockIds);
          this.outOfStockModal.open();
          this._designService.setModalStyling();
        }
      });
    },

    closedOutOfStockModal(): void {
      navigateToMenu();
    }
  }
});
