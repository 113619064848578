import {BaseApiService} from '../../common/services/BaseApiService';
import {
  API_ADD_COMMENTS_TO_ITEM_URL,
  API_ADD_COUPON_BY_CODE_URL,
  API_ADD_COUPON_URL,
  API_ADD_ITEM_URL,
  API_ADD_ITEM_WITH_SIZE_URL,
  API_ADD_NO_REQUIRED_ITEM_URL,
  API_ADD_REQUIRED_ITEM_URL,
  API_ADD_STYLE_URL,
  API_ADD_USER_URL,
  API_CHANGE_REQUIREMENTS_URL,
  API_CHANGE_TOPPINGS_URL,
  API_CHECK_CART_STATUS_URL,
  API_CHECK_ORDER_TIME_URL,
  API_CLEAR_URL,
  API_FETCH_GIFT_CARD_BALANCE_URL,
  API_FIND_HISTORICAL_URL,
  API_REORDER_URL,
  API_REMOVE_DELIVERY_ZONE_URL,
  API_REMOVE_ITEM_URL,
  API_REMOVE_ITEMS_URL,
  API_REMOVE_REQUIREMENT_INSTANCES_URL,
  API_SCHEDULE_ASAP_URL,
  API_SET_DELIVERY_ZONE_URL,
  API_SET_ORDER_NAME_URL,
  API_SET_ORDER_TIME_URL,
  API_SET_ORDER_TYPE_URL,
  API_SET_QUANTITY_URL,
  API_SET_TENDER_TYPE_URL,
  API_SPLIT_ITEM_URL,
  API_UNSPLIT_ITEM_URL,
  API_FETCH_BY_SESSION_URL,
  API_GET_CART_UPDATE_TIME_URL
} from '../cart.constants';
import storageService from '../../common/services/storage.service';
import {IRequiredItem} from '../../menu/models/Item';
import {RequestMethod} from '../../common/services/axios-http.service';
import {IZoneArea} from '../../restaurants/types/restaurant.types';
import { createRequirementsUpdatePayload } from '../helpers/cart.helpers';

export interface IUpdateRequirementState {
    instanceId?: string; // for additional quantities, reference the original item objectId
    requirementId: string; // option item id
    requiredItemId: string; // required item id
    parentInstanceId: string; // parent item or required item objectId
    quantity: number; // should always be 1 (see instanceId)
}

export class CartService extends BaseApiService {
    addCoupon(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_COUPON_URL,
            search: `cart=${payload.cartId}&coupon=${payload.couponId}&promo=${payload.promo}`
        });
    }

    addCouponCode(payload: { cartId: string, couponCode: string }) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_COUPON_BY_CODE_URL,
            search: `cart=${payload.cartId}&coupon=${payload.couponCode}`
        });
    }

    fetchGiftCardBalance(payload: { locationId: string, giftCardCode: string, giftCardCvv?: string }) {
        let query = `giftCardNumber=${payload.giftCardCode}&location=${payload.locationId}`;

        if (payload.hasOwnProperty('giftCardCvv')) {
            query += `&giftCardCVV=${payload.giftCardCvv}`;
        }

        return this.request({
            method: RequestMethod.Get,
            url: API_FETCH_GIFT_CARD_BALANCE_URL,
            search: query
        });
    }

    addCommentsToItem(payload) {
        return this.request({
            method: RequestMethod.Post,
            body: {message: payload.comments},
            url: API_ADD_COMMENTS_TO_ITEM_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemId}`
        });
    }

    addItem(payload) {
        const qtyParam = payload.quantity > 1 ? `&qty=${payload.quantity}` : '';

        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_ITEM_URL,
            search: `cart=${payload.cartId}&item=${payload.itemId}` + qtyParam
        });
    }

    addItemWithSize(payload) {
        const qtyParam = payload.quantity > 1 ? `&qty=${payload.quantity}` : '';

        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_ITEM_WITH_SIZE_URL,
            search: `cart=${payload.cartId}&item=${payload.itemId}&style=${payload.sizeId}` + qtyParam
        });
    }

    addRequiredItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_REQUIRED_ITEM_URL,
            search: `cart=${payload.cartId}&requirement=${payload.requirementId}&item=${payload.itemId}&parent=${payload.parentId}`
        });
    }

    addSizeToItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_STYLE_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemId}&style=${payload.sizeId}`
        });
    }

    addStyleToItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_STYLE_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemId}&style=${payload.styleId}`
        });
    }

    changeToppings(payload) {
        return this.request({
            method: RequestMethod.Post,
            url: API_CHANGE_TOPPINGS_URL,
            body: {toppings: payload.toppings},
            search: `cart=${payload.cartId}&instance=${payload.itemId}`
        });
    }

    removeItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_ITEM_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemId}`
        });
    }

    clearCart() {
        return this.request({
            method: RequestMethod.Get,
            url: API_CLEAR_URL
        });
    }

    getCartUpdateTime(cartId: string) {
      return this.request({
        method: RequestMethod.Get,
        url: API_GET_CART_UPDATE_TIME_URL,
        search: `cartId=${cartId}`
      });
    }

    fetchCartBySession() {
        let restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

        return this.request({
          method: RequestMethod.Get,
          url: API_FETCH_BY_SESSION_URL,
          search: `location=${restaurantId}`
        });
    }

    setQuantity(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SET_QUANTITY_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemId}&qty=${payload.quantity}`
        });
    }

    setOrderType(payload: { curbside: boolean, orderTypeId: string }) {
        const curbside = payload.curbside ? '1' : '0';

        return this.request({
            method: RequestMethod.Get,
            url: API_SET_ORDER_TYPE_URL,
            search: `curbside=${curbside}&type=${payload.orderTypeId}`
        });
    }

    setOrderTime(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SET_ORDER_TIME_URL,
            search: `date=${payload.date}&time=${payload.time}`
        });
    }

    checkOrderTime() {
        return this.request({
            method: RequestMethod.Get,
            url: API_CHECK_ORDER_TIME_URL
        });
    }

    scheduleAsap() {
        return this.request({
            method: RequestMethod.Get,
            url: API_SCHEDULE_ASAP_URL
        });
    }

    addNoRequiredItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_NO_REQUIRED_ITEM_URL,
            search: `cart=${payload.cartId}&requirement=${payload.requirement}&parent=${payload.parent}`
        });
    }

    removeItems(payload) {
        return this.request({
            method: RequestMethod.Post,
            url: API_REMOVE_ITEMS_URL,
            body: {removeItems: payload.itemIds},
            search: `cart=${payload.cartId}`
        });
    }

    addUserToCart(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_ADD_USER_URL,
            search: `cart=${payload.cartId}&user=${payload.userId}`
        });
    }

    setOrderName(payload) {
        return this.request({
            body: { message: payload.orderName },
            method: RequestMethod.Post,
            url: API_SET_ORDER_NAME_URL
        });
    }

    findHistoricalCart(cartId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_FIND_HISTORICAL_URL,
            search: `cart=${cartId}`
        });
    }

    reorderCart(cartId: string) {
      return this.request({
        method: RequestMethod.Get,
        url: API_REORDER_URL,
        search: `cart=${cartId}`
      });
    }

    checkCartStatus(cartId: string) {
        return this.request({
            method: RequestMethod.Get,
            url: API_CHECK_CART_STATUS_URL,
            search: `cart=${cartId}`
        });
    }

    setDeliveryZone(payload: { cartId: string, zone: IZoneArea | string }) {
        let zoneId;

        if (typeof payload.zone === 'string') {
            zoneId = payload.zone;
        } else {
            zoneId = payload.zone.zoneId;
        }

        return this.request({
            method: RequestMethod.Get,
            url: API_SET_DELIVERY_ZONE_URL,
            search: `cart=${payload.cartId}&zone=${zoneId}`
        });
    }

    removeDeliveryZone(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_DELIVERY_ZONE_URL,
            search: `cart=${payload.cartId}`
        });
    }

    setTenderType(payload: { cartId: string, tenderTypeId: string }) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SET_TENDER_TYPE_URL,
            search: `cart=${payload.cartId}&tenderType=${payload.tenderTypeId}`
        });
    }

    splitItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_SPLIT_ITEM_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemObjectId}&item=${payload.itemId}`
        });
    }

    unsplitItem(payload) {
        return this.request({
            method: RequestMethod.Get,
            url: API_UNSPLIT_ITEM_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemObjectId}`
        });
    }

    removeAllInstancesOfRequiredItem(payload: { cartId: string, requiredItem: IRequiredItem, parentInstanceId: string }) {
        return this.request({
            method: RequestMethod.Get,
            url: API_REMOVE_REQUIREMENT_INSTANCES_URL,
            search: `cart=${payload.cartId}&requirement=${payload.requiredItem.itemId}&parent=${payload.parentInstanceId}`
        });
    }

    changeRequirements(payload: { cartId: string, itemInstanceId: string, requirements: IRequiredItem[] }) {
        const body = {
            requirements: createRequirementsUpdatePayload(payload.requirements)
        };

        return this.request({
            method: RequestMethod.Post,
            body,
            url: API_CHANGE_REQUIREMENTS_URL,
            search: `cart=${payload.cartId}&instance=${payload.itemInstanceId}`
        });
    }
}

export default new CartService();
