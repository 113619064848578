









































































































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import scheduleOrderMixin from '../../common/mixins/schedule-order.mixin';
import SelectModern from '../../common/components/SelectModern.vue';
import { IOrderType } from '../../restaurants/types/restaurant.types';

declare var require: any;
const moment = require('moment');

export default Vue.extend({
    mixins: [designMixin, scheduleOrderMixin],
    components: {SelectModern},
    props: {
        buttonClass: {
            type: Object,
            required: true
        },
        btnStyle: {
            type: Object,
            required: true
        },
        orderTime: {
            type: String,
            required: false
        },
        orderType: {
            type: String,
            required: false
        },
        orderTypes: {
            type: Object as () => { [key: number]: IOrderType },
            required: false
        },
        isDelivery: {
            type: Boolean,
            required: false
        }
    },
    watch: {
        day(value) {
            this.$emit('changeDay', value);
        },
        scheduleType(value) {
            if (value === 'asap') {
                let asapTime = moment(this.currentTime);
                if (this.cartObject && this.cartObject.expectedTimeString) {
                    asapTime = moment(this.cartObject.expectedTimeString);
                }
                let [hour, minute] = asapTime.format('hh:mm').split(':');
                this.hour = hour;
                this.minute = (Math.ceil(Number(minute)/5) * 5).toString(); // round to nearest increment of 5
                this.amPm = asapTime.hour() >= 12 ? 'pm' : 'am';
            }
        }
    },
    data () {
        return {
            scheduleType: 'schedule'
        };
    },
    methods: {
        submit() {
            if (this.scheduleType === 'schedule') {
                this.setOrderTime();
            } else {
                this.scheduleAsap();
            }
        }
    }
});
