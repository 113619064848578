






import Vue from 'vue';
import profile from '../../profile/models/Profile';
import baseMixin from '../../common/mixins/base-component.mixin';
import routeService from '../../common/services/route.service';
import environmentService from '../../common/services/environment.service';
import order from '../models/Order';
import cart from '../../cart/models/Cart';
import ConfirmationModern from '../components/ConfirmationModern.vue';

export default Vue.extend({
    mixins: [baseMixin],
    components: {
        ConfirmationModern
    },
    props: {
        isCurbsideOrder: Boolean
    },
    computed: {
        cart () {
            return cart;
        },

        environment () {
            return environmentService;
        },

        order () {
            return order;
        },

        profile () {
            return profile;
        },

        routeService () {
            return routeService;
        },

        // getters

        user() {
            return this.profile.user;
        }
    },
    data() {
        return {
            initialized: false
        };
    },
    mounted () {
        this.fetchHistoricalOrders();

        this.initialized = true;
    },
    methods: {
        fetchHistoricalOrders() {
            const user = profile.user;

            if (user && user.objectId) {
                this.order.fetchHistoricalOrders(this.locationId, user.objectId);
            }
        }
    }
});
