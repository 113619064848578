






































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import modalMixin from '../../common/mixins/modal.mixin';
import restaurantListMixin from '../mixins/restaurant-list.mixin';
import { IAddress } from '../../common/models/Address';
import ModalModern from '../../common/components/ModalModern.vue';
import { IRestaurant } from '../types/restaurant.types';
import { LatLngLiteral } from '../../common/models/LatLng';

export default Vue.extend({
  components: { ModalModern },
    mixins: [modalMixin, restaurantListMixin, designMixin],
    props: {
      addressForDelivery: Object as () => IAddress,
      automaticallySetLocation: {
        type: Boolean,
        default: true
      },
      buttonTitle: {
        type: String,
        default: 'SELECT'
      },
      deliveryLocationList: Array as () => Array<IRestaurant>,
      isFindingLocations: Boolean,
      locationString: String,
      origin: Object as () => LatLngLiteral,
      selectedOrderType: String
  },
  computed: {
    locations () {
      if (this.deliveryLocationList) {
        return this.deliveryLocationList;
      }

      return this.restaurantList;
    }
  },
  methods: {
    changeLocation() {
      this.userLocation = null;

      this.close();

      this.$emit('changeLocation');
    },

    onEditClick() {
      this.changeLocation();
    },

    onChangeLocation() {
      this.changeLocation();
    },

    onSelectRestaurant(payload: { restaurant: IRestaurant, distance?: string }) {
      this.close();

      if (this.automaticallySetLocation) {
        this.onSelectModalRestaurant(payload);
      } else {
        this.$emit('selectRestaurant', payload);
      }
    }
  }
});
