





































































































































































































































































































































































































































import Vue from 'vue';
import creditCardFormMixin from '../mixins/credit-card-form.mixin';
import CheckboxModern from '../../common/components/CheckboxModern.vue';
import SelectModern from '../../common/components/SelectModern.vue';

export default Vue.extend({
  mixins: [creditCardFormMixin],
  components: { CheckboxModern, SelectModern },
  data: () => ({
    CVVFieldTouched: false
  })
});
