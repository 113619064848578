


























import Vue from 'vue';
import {IMenuItem} from '../../menu/models/Item';
import designService from '../../common/services/design.service';
import StyledModal from '../../common/components/StyledModal.vue';

export default Vue.extend({
    components: {
        StyledModal
    },
    props: {
        outOfStockItems: {
            type: Array as () => Array<IMenuItem>,
            required: true
        }
    },
    computed: {
        _designService () {
            return designService;
        }
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        // event handlers

        onHide() {
            this.$emit('onHide');
        },

        // styling

        getButtonClass(): any {
            return this._designService.getButtonClass();
        },

        getButtonStyle(fontSize: string): any {
            let style: any = this._designService.getButtonStyle();

            if (fontSize) {
                style['font-size'] = fontSize;
            }

            return style;
        }
    }
});
