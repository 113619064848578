




























































































































































































































































































































































































































































































































































































































import Vue from 'vue';
import checkOutMixin from '../mixins/check-out.mixin';
import CreditCardFormModern from './CreditCardFormModern.vue';
import CheckboxModern from '../../common/components/CheckboxModern.vue';
import SelectModern from '../../common/components/SelectModern.vue';
import PasswordFormModern from '../../profile/components/PasswordFormModern.vue';
import recaptchaMixin from '../../captcha/mixins/recaptcha.mixin';
import { getCurrencyType } from '../../common/helpers/currency.helpers';

export default Vue.extend({
        mixins: [checkOutMixin, recaptchaMixin],

        components: {
            CreditCardFormModern,
            CheckboxModern,
            SelectModern,
            PasswordFormModern
        },

        computed: {
            allowTips() {
                return this.restaurant.tipSettings.allowTips;
            },

            currencyType() {
                return getCurrencyType(this.restaurant.address.country);
            },

            isPhoneAlreadySaved () {
                return this.phones && !!this.phones.find(p => p.number === this.tenderRequest.phone)
            },

            linkStyle() {
                return {
                    color: this.linkColor
                };
            },

            orderButtonText() {
                return this.isHostedPaymentEnabled && this.enterNewCreditCard ? 'Next (To payment info)' : 'Place Order';
            },
        },

        methods: {
          adjustedItemPrice(item) {
            let itemPrice = item.price;
            const fulfilledCoupons = this.getFulfilledCoupons(item).filter(c => c.eachPrice != 0 && c.showAsLineItem === false);
            for (let i = 0; i < fulfilledCoupons.length; i++) {
              itemPrice += fulfilledCoupons[i].eachPrice;
            }
            return itemPrice;
          },

          // Called from recaptcha mixin
          onRecaptchaSuccess() {
            this.finalizeOrder();
          },

          onSubmitButtonClick() {
            this.performRecaptcha();
          }
        }
    });
