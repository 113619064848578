






import Vue from 'vue';

export default Vue.extend({
  mixins: [],
  components: {},
  props: {},
  computed: {},
  data() {
    return {};
  },
  methods: {}
});
