













































































































































































































import Vue from 'vue';
import baseMixin from '../mixins/base-component.mixin';
import { Address, IAddress } from '../models/Address';
import routeService from '../services/route.service';
import { Util } from '../services/Util';
import designService from '../services/design.service';
import modalService from '../services/modal.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import googleService from '../services/google.service';
import notifierService from '../services/notifier.service';
import { emptyAddress, ITenderRequest } from '../../order/models/TenderRequest';
import StateSelect from './StateSelect.vue';
import ModalModern from './ModalModern.vue';
import CheckboxModern from './CheckboxModern.vue';
import maps from '../../maps/models/Maps';
import { IUser } from '../../profile/stores/profile.store';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import loyaltyService from '../../coupons/services/loyalty.service';
import cart from '../../cart/models/Cart';

export default Vue.extend({
  mixins: [baseMixin],

  components: {
    StateSelect,
    ModalModern,
    CheckboxModern
  },

  props: {
    addresses: {
      type: Array as () => Array<IAddress>,
      required: false
    },

    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    },

    suggestedAddresses: {
      type: Array as () => Array<IAddress>,
      required: false
    },

    tenderRequest: {
      type: Object as () => ITenderRequest,
      required: true
    },

    updating: {
      type: Boolean,
      required: true
    },

    user: {
      type: Object as () => IUser,
      required: false
    }
  },

  computed: {
    _googleService() {
      return googleService;
    },

    _modalService() {
      return modalService;
    },

    _notifierService() {
      return notifierService;
    },

    cartHelperService() {
      return cartHelperService;
    },

    designService() {
      return designService;
    },

    notifications() {
      return this.$store.state.notifications.notifications.filter(it => it.id === 'DELIVERY_MODAL')
    },

    routeService() {
      return routeService;
    }
  },

  data() {
    return {
      deliveryAddress: null, // IAddress
      closeDeliveryModalSubscription: null, // ISubscription,
      endDeliverySubscription: null, // ISubscription,
      enterNewDeliveryAddress: true,
      lastCheckedPostalCode: null, // string,
      saveDeliveryAddress: false,
      selectDeliveryModalSubscription: null, // ISubscription,
      selectedDeliveryAddress: null, // IAddress,
      submitDeliverySubscription: null // ISubscription,
    };
  },

  created() {
    this.deliveryAddress = new Address(this.tenderRequest.deliveryAddress);
    this.deliveryAddress.latitude = null;
    this.deliveryAddress.longitude = null;

    this.selectDeliveryModalSubscription = this._modalService.selectDeliveryModal$.subscribe(() => {
      this.deliveryAddress = new Address(this.tenderRequest.deliveryAddress);
      this.deliveryAddress.latitude = null;
      this.deliveryAddress.longitude = null;

      if (!this.deliveryAddress.objectId) {
        this.enterNewDeliveryAddress = true;
        this.selectedDeliveryAddress = undefined;
      } else if (this.deliveryAddress.objectId) {
        this.selectedDeliveryAddress = JSON.parse(JSON.stringify(this.deliveryAddress));
        this.enterNewDeliveryAddress = false;
      }

      this.open();
    });

    this.closeDeliveryModalSubscription = this._modalService.closeDeliveryModal$.subscribe(() => {
      this.$emit('clearSuggestedAddresses', null);

      this.close();
    });
  },

  methods: {
    cancelDeliveryAddress() {
      this.$emit('clearSuggestedAddresses', null);
      this.close();
      this._modalService.cancelDelivery();

      // add the offer link coupon even if they don't finish setting up delivery
      if (loyaltyService.linkOffer) {
        const found = cart.cart.coupons.find(c => c.couponId === loyaltyService.linkOffer.couponId);

        if (!found) {
          loyaltyService.chooseCoupon(loyaltyService.linkOffer, false);
        }
      }
    },

    changeDeliveryAddress(address: IAddress, event) {
      if (event.target.checked) {
        this.selectedDeliveryAddress = JSON.parse(JSON.stringify(address));
        this.enterNewDeliveryAddress = false;
      }
    },

    changeDeliveryAddressToNew(event) {
      if (event.target.checked) {
        this.enterNewDeliveryAddress = true;
        this.selectedDeliveryAddress = undefined;
        this.deliveryAddress = emptyAddress();
      }
    },

    checkPostalCode() {
      if (!this.deliveryAddress || !this.deliveryAddress || !this.deliveryAddress.postalCode) {
        return;
      }

      if (this.lastCheckedPostalCode && this.lastCheckedPostalCode === this.deliveryAddress.postalCode) {
        return;
      }

      this.lastCheckedPostalCode = this.deliveryAddress.postalCode;

      maps.verifyPostalCode(this.deliveryAddress.postalCode)
        .then((result) => {
          let validatedAddress = result;

          if (validatedAddress.city)
            this.deliveryAddress.city = validatedAddress.city;

          if (validatedAddress.stateCode) {
            this.deliveryAddress.stateCode = validatedAddress.stateCode;
            this.deliveryAddress.stateName = validatedAddress.stateName;
          }
          // tslint:disable-next-line:no-empty
        }).catch(() => {
      });
    },

    chooseDelivery(saveDeliveryAddress: boolean) {
      maps.validateDeliveryAddress(this.deliveryAddress, this.selectedRestaurant)
        .then((result: any) => {
          if (result !== 'OK') {
            this.updateDeliveryZone(result);

            this.close();
          }

          if (saveDeliveryAddress && this.user && this.user.objectId) {
            this.saveAddressToProfile(this.deliveryAddress, this.user.objectId);
          }

          this.tenderRequest.deliveryAddress = this.deliveryAddress;

          this._modalService.closeDeliveryModal();
          this.endDeliverySubscriptions();
        })
        .catch((result) => {
          let notification: string = '';

          if ((typeof result) === 'string') {
            notification = result;
          } else if (result && (typeof result) === 'object' && result.length && result.length > 0) {
            this.$emit('setSuggestedAddresses', result);

            return;
          } else if (result && result.hasOwnProperty('length') && result.length === 0) {
            notification = 'Address not found.';
          } else {
            notification = this._googleService.getNotificationMessage(result);
          }

          this._notifierService.error(notification);
          this.$emit('removeDeliveryZone', null);
        });
    },

    chooseSuggestedAddress(address: IAddress) {
      if (!address) {
        return;
      }

      const aptNumber: string = this.deliveryAddress.addressLine2;

      this.deliveryAddress = address;

      if (aptNumber) {
        this.deliveryAddress.addressLine2 = aptNumber;
      }

      this.selectedDeliveryAddress = undefined;
      this.enterNewDeliveryAddress = true;
      this.$emit('clearSuggestedAddresses', null);
      this.submitDeliveryAddress();
    },

    close() {
      this.$refs.modal.close();
    },

    endDeliverySubscriptions() {
      this.$emit('clearSuggestedAddresses', null);

      if (this.submitDeliverySubscription) {
        this.submitDeliverySubscription.unsubscribe();

        this.submitDeliverySubscription = undefined;
      }

      if (this.endDeliverySubscription) {
        this.endDeliverySubscription.unsubscribe();

        this.endDeliverySubscription = undefined;
      }
    },

    getFormattedAddress(address: IAddress) {
      return maps.getFormattedAddress(address);
    },

    isNewDeliveryAddressSelected(): boolean {
      return this.enterNewDeliveryAddress;
    },

    isSelectedDeliveryAddress(address: IAddress): boolean {
      return this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId === address.objectId;
    },

    isValidAddress(): boolean {
      return this.deliveryAddress &&
        this.deliveryAddress.addressLine.length <= 250 &&
        (!this.deliveryAddress.addressLine2 ||
          this.deliveryAddress.addressLine2.length <= 20) &&
        this.deliveryAddress.city.length <= 200 &&
        this.deliveryAddress.postalCode.length <= 50;
    },

    openDeliveryModal(): void {
      this.submitDeliverySubscription = this._modalService.submitDelivery$.subscribe(
        (payload: { saveDeliveryAddress: boolean, deliveryAddress: IAddress }) => {
        this.chooseDelivery(payload.saveDeliveryAddress);
      });

      this.endDeliverySubscription = this._modalService.endDeliverySubscriptions$.subscribe(() => {
        this.endDeliverySubscriptions();
      });

      this._modalService.selectDeliveryModal();
    },

    onChange() {
      this.deliveryAddress.latitude = null;
      this.deliveryAddress.longitude = null;
    },

    onHide() {
      window.setTimeout(() => {
        this.$emit('clearSuggestedAddresses', null);
        this._modalService.cancelDelivery();
        this._modalService.endDeliverySubscriptions();
      }, 0);

      this._modalService.closedDeliveryModal();

      this.cartHelperService.checkOrderTime();

      this.lastCheckedPostalCode = null;
    },

    onStateChange(event) {
      this.deliveryAddress.stateCode = event.target.value;
    },

    open() {
      this.deliveryAddress = new Address(this.tenderRequest.deliveryAddress);
      this.deliveryAddress.latitude = null;
      this.deliveryAddress.longitude = null;

      this.$refs.modal.open();
    },

    saveAddressToProfile(address: IAddress, userId: string) {
      let payload = {
        userId,
        addressLine: address.addressLine,
        addressLine2: address.addressLine2,
        city: address.city,
        stateCode: address.stateCode,
        stateName: address.stateName,
        postalCode: address.postalCode,
        type: 'DELIVERY'
      };

      this.$emit('saveAddress', payload);
    },

    showUseAddressButton(): boolean {
      if (Util.isEmpty(this.suggestedAddresses)) {
        return true;
      }

      if (this.suggestedAddresses && this.suggestedAddresses.length === 0) {
        return true;
      }

      return false;
    },

    signIn(payload) {
      // lets cart helper service unsubscribe from delivery subscriptions
      this._modalService.cancelDelivery();

      if (loyaltyService.linkOffer && routeService.currentRoute.name === 'SelectCouponItem') {
        if (!payload) {
          payload = {};
        }

        payload.source = routeService.currentRoute.name;
      }

      this.logIn(payload);
    },

    submitDeliveryAddress() {
      if (this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId) {
        this.deliveryAddress = JSON.parse(JSON.stringify(this.selectedDeliveryAddress));
      } else {
        if (!this.isValidAddress()) {
          return;
        }

        this.deliveryAddress.latitude = null;
        this.deliveryAddress.longitude = null;
      }

      this._modalService.submitDelivery({ saveDeliveryAddress: this.saveDeliveryAddress, deliveryAddress: this.deliveryAddress });
    },

    toggleSaveDeliveryAddress() {
      this.saveDeliveryAddress = !this.saveDeliveryAddress;
    },

    updateDeliveryZone(result) {
      window.setTimeout(() => {
        if (this.updating) {
          this.updateDeliveryZone(result);

          return;
        }

        this.$emit('setDeliveryZone', result);
      }, 50);
    },

    // Styling
    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    },

    getLinkStyle(): any {
      return {
        'color': this._designService.tertiaryColor,
        'text-decoration': 'underline'
      };
    }
  },

  destroyed() {
    if (this.closeDeliveryModalSubscription) {
      this.closeDeliveryModalSubscription.unsubscribe();
    }

    if (this.selectDeliveryModalSubscription) {
      this.selectDeliveryModalSubscription.unsubscribe();
    }
  }
});
