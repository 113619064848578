












import Vue from 'vue';
import designMixin from '../mixins/design.mixin';
import ModalModern from './ModalModern.vue';
import '@fortawesome/fontawesome-pro';

export default Vue.extend({
    mixins: [designMixin],
    components: {
        ModalModern
    },
    props: {
        message: {
            type: String,
            required: true,
            default: 'Loading'
        },
        title: {
            type: String,
            required: true,
            default: 'Loading...'
        }
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            // this.$refs.modal.close();
        }
    }
});
