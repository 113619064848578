


















































































import Vue from 'vue';
import requirementsMixin from '../../../mixins/requirement.mixin';
import designMixin from '../../../../common/mixins/design.mixin';
import {IRequiredItem} from '../../../models/Item';
import {Util} from '../../../../common/services/Util';

export default Vue.extend({
        mixins: [requirementsMixin, designMixin],
        methods: {
            addRequired($event) {
                this.$emit('addRequired', $event);
            },

            decreaseRequiredQty() {
                this.$emit('decreaseRequiredQty');
            },

            getRequiredItemFor(itemId: string, parentItemId: string, index: number) {
                return `Req${itemId}-${parentItemId}-${index}`;
            },

            getRequiredItemId(requiredItem: IRequiredItem): string {
              return Util.getRequiredItemId(requiredItem);
            },

            increaseRequiredQty($event) {
                this.$emit('increaseRequiredQty', $event);
            },

            selectAndDecrease($event) {
              if (this.requiredQty > 0) {
                this.$emit('decreaseRequiredQty', $event);
              }
            },

            selectAndIncrease($event) {
              if (!this.isSelected) {
                this.$emit('toggleRequired', { target: {
                  checked: true
                } });
              } else {
                this.$emit('increaseRequiredQty', $event);
              }
            },

            toggleRequired($event) {
              this.$emit('toggleRequired', $event);
            }
        }
    });
