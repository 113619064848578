


























































































































































































import Vue from 'vue';
import ModalModern from '../../common/components/ModalModern.vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurants from '../../restaurants/models/Restaurants';
import { ILoginRequest, IUser } from '../stores/profile.store';
import { Util } from '../../common/services/Util';

export default Vue.extend({
  mixins: [baseMixin],

  components: {
    ModalModern
  },

  props: {
    loginError: String,
    loginRequest: {
      type: Object as () => ILoginRequest,
      required: true
    },
    resetError: String,
    user: Object as () => IUser,
    userError: String
  },

  data() {
    return {
      isLoggingIn: false,
      showForgotPassword: false,
      submitted: false
    };
  },

  computed: {
    // models

    restaurants() {
      return restaurants;
    },

    // getters

    isEmailValid(): boolean {
      return this.loginRequest.username;
    },

    isPasswordValid(): boolean {
      return this.loginRequest.password;
    },

    isValid(): boolean {
      return this.isEmailValid && this.isPasswordValid;
    },

    restaurant() {
      return this.restaurants.selectedRestaurant;
    },

    source() {
      return Util.getQueryParam('source');
    }
  },

  created() {
    if (this.user && this.user.objectId) {
      this.$emit('loggedIn', this.user);

      return;
    }
  },

  mounted() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    }
  },

  methods: {
    signIn(): void {
      if (!this.isReadyToSignIn()) {
        this.submitted = true;

        return;
      }

      this.isLoggingIn = true;

      this.$emit('login', this.loginRequest);
    },

    resetPassword(): void {
      if (!this.isReadyToResetPassword()) {
        this.submitted = true;

        return;
      }

      this.$emit('onResetPassword', this.loginRequest.username);
    },

    isReadyToSignIn(): boolean {
      return this.isValid && !this.isLoggingIn;
    },

    isReadyToResetPassword(): boolean {
      return this.isEmailValid;
    },

    cancelPasswordReset(): void {
      this.showForgotPassword = false;
    },

    isUserLoggedIn(): boolean {
      return this.user && this.user.objectId && this.user.objectId.length > 0;
    },

    forgotPassword() {
      this.showForgotPassword = true;
    },

    register() {
      this.$emit('register');
    },

    logOut() {
      this.$emit('logOut');
    },

    openLocationFinder() {
      this.routeService.route('RestaurantChooser', { route: this.source }, true);
    },

    back() {
      window.history.back();
    }
  },

  watch: {
    loginError(error) {
      if (error) {
        this.isLoggingIn = false;
      }
    },

    userError(error) {
      if (error) {
        this.$refs.userErrorModal.open();

        this.isLoggingIn = false;
      }
    }
  }
});
