



























































































































import Vue from 'vue';
import loyaltyMixin from '../../coupons/mixins/loyalty.mixin';
import { IAddress } from '../../common/models/Address';
import { ICreditCard } from '../../common/models/CreditCard';
import { IPhone } from '../../common/models/Phone';
import designService from '../../common/services/design.service';
import ProfileAddress from './ProfileAddress.vue';
import ProfileCredit from './ProfileCredit.vue';
import ProfileInfo from './ProfileInfo.vue';
import ProfilePhone from './ProfilePhone.vue';
import ModalModern from '../../common/components/ModalModern.vue';
import designMixin from '../../common/mixins/design.mixin';
import { IRegisterRequest, IUser } from '../stores/profile.store';
import { routerLink } from '../../common/directives/RouterLink';
import { ILoyalty } from '../../restaurants/types/restaurant.types';

export default Vue.extend({
  mixins: [designMixin, loyaltyMixin],

  components: {
    ModalModern,
    ProfileAddress,
    ProfileCredit,
    ProfileInfo,
    ProfilePhone
  },

  directives: { routerLink },

  props: {
    addresses: {
      type: Array as () => Array<IAddress>,
      required: true
    },

    addressTypes: {
      type: Array as () => Array<any>,
      required: true
    },

    creditCards: {
      type: Array as () => Array<ICreditCard>,
      required: true
    },

    loyaltySettings: {
      type: Object as () => ILoyalty,
      required: true
    },

    phones: {
      type: Array as () => Array<IPhone>,
      required: true
    },

    phoneTypes: {
      type: Array as () => Array<any>,
      required: true
    },

    profileAddressError: String,

    profileInfoError: String,

    profilePhoneError: String,

    registerRequest: {
      type: Object as () => IRegisterRequest,
      required: true
    },

    suggestedAddresses: {
      required: true
    },

    user: {
      type: Object as () => IUser,
      required: false
    }
  },

  computed: {
    _designService () {
      return designService;
    },

    isEnrolledInRewards(): boolean {
      return this.user.rewards && this.loyaltySettings && this.loyaltySettings.externalProgram;
    }
  },

  methods: {
    openModal() {
      this.$refs.deleteAccountModal.open();
      this._designService.setModalStyling();
    },

    deleteAccount() {
      this.cancelModal();
      this.$emit('deleteAccount', this.user.objectId);

      window.setTimeout(
        () => {
            this.$emit('viewMenu');
        },
        250
      );
    },

    cancelModal() {
      this.$refs.deleteAccountModal.close();
    },

    viewMenu() {
      this.$emit('viewMenu');
    },

    signOut() {
      this.$emit('signOut');
    },

    clearProfileState() {
      this.$emit('clearProfileState');
    },

    updateProfile($event) {
      this.$emit('updateProfile', $event);
    },

    changePassword($event) {
      this.$emit('changePassword', $event);
    },

    clearSuggestedAddresses() {
      this.$emit('clearSuggestedAddresses');
    },

    setSuggestedAddresses($event) {
      this.$emit('setSuggestedAddresses', $event);
    },

    setPrimary($event) {
      this.$emit('setPrimary', $event);
    },

    savePhone($event) {
      this.$emit('savePhone', $event);
    },

    removePhone($event) {
      this.$emit('removePhone', $event);
    },

    removeCredit($event) {
      this.$emit('removeCredit', $event);
    },

    // Styling
    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    }
  }
});
