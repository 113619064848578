
















































































































































import Vue from 'vue';
import passwordFormMixin from '../mixins/password-form.mixin';
import CheckboxModern from '../../common/components/CheckboxModern.vue';
import restaurants from '../../restaurants/models/Restaurants';
import PhoneInput from '../../common/components/PhoneInput.vue';

export default Vue.extend({
  mixins: [passwordFormMixin],
  components: { PhoneInput, CheckboxModern},
  props: {
    isOnlyRegisterIfLoyaltyChecked: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    joinLoyaltyChecked: {
      get () {
        return this.registerRequest.rewards;
      },
      set (v) {
        this.registerRequest.rewards = v;
      }
    },

    restaurant() {
      return restaurants.selectedRestaurant;
    }
  }
});
