
































import Vue from 'vue';
import { routerLink } from '../directives/RouterLink';
import baseMixin from '../mixins/base-component.mixin';
import designMixin from '../mixins/design.mixin';

export default Vue.extend({
      mixins: [baseMixin, designMixin],
      directives: {
          routerLink
      },
      methods: {
          // Styling

          getBottomDiv1(): any {
            if (!this._designService.getDesign()) {
              return {};
            }

            return {
              'height': '10px',
              'color': this._designService.backgroundColor
            };
          },

          getBottomDiv3(): any {
            if (!this._designService.getDesign()) {
              return {};
            }

            return {
              'height': '10px',
              'color': this._designService.backgroundColor
            };
          },

          getHeaderImageCss(): String {
              if (!this._designService.getDesign()) {
                  return null;
              }

              let html: string = this._designService.getDesign().htmlHeader;
              let test = /<img[^>]+src="([^">]+)"/.exec(html);

              if (test && test.length > 1) {
                return 'background-image: url("' + (test[1]) + '");';
              } else {
                return null;
              }
          },

          getHeaderLogo(): any {
            return this._designService.getDesign() ? this._designService.getDesign().headerLogo : null;
          },

          getHeaderSlogan(): any {
            return this._designService.getDesign() ? this._designService.getDesign().headerSlogan : null;
          },

          getHeaderSloganStyle(): any {
            if (!this._designService.getDesign()) {
              return {};
            }

            return {
              'color': this._designService.sloganFontColor,
              'font-size': this._designService.getDesign().sloganFontSize,
              'font-style': this._designService.getDesign().sloganFontStyle,
              'font-family': this._designService.getDesign().fontHeadline
            };
          },

          getHeaderStyle(): any {
            if (!this._designService.getDesign()) {
              return {};
            }

            return {
              'background-color': this._designService.headerBackgroundColor,
              'color': this._designService.headerTextFontColor,
              'font-size': this._designService.getDesign().headerTextFontSize,
              'font-style': this._designService.getDesign().headerTextFontStyle,
              'font-family': this._designService.getDesign().fontHeadline + ' !important'
            };
          },

          getHtmlHeader(): any {
            return this._designService.getDesign() ? this._designService.getDesign().htmlHeader : null;
          }
      }
  })
