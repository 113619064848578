















































































































































































































































import Vue from 'vue';
import { SPLIT_SIDES } from '../menu.constants';
import { IMenuItem, IRequiredItem, IRequiredOption, ITopping } from '../models/Item';
import designService from '../../common/services/design.service';
import environmentService from '../../common/services/environment.service';
import util from '../../common/services/Util';
import { FRACTION_TYPES } from '../services/toppings-manager.service';
import designMixin from '../../common/mixins/design.mixin';
import filtersMixin from '../../common/mixins/filters.mixin';
import { isToppingInStock } from '../../cart/helpers/cart.helpers';
import cart from '../../cart/models/Cart';

export default Vue.extend({
  mixins: [designMixin, filtersMixin],
  props: {
    getToppingQuantity: {
      type: Function,
      required: true
    },

    item: {
      type: Object as () => IMenuItem,
      required: true
    },

    showButtons: {
      type: Boolean,
      required: false,
      default: true
    },

    splitSide: {
      type: String,
      required: false
    },

    updating: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    // models

    _designService() {
      return designService;
    },

    environment() {
      return environmentService;
    },

    // getters

    uniqueRequiredOptions() {
      const options = this.getRequiredOptions(this.item);
      const result = [];

      options.forEach(option => {
        const existing = result.find(op => op.objectId === option.objectId);

        if (!existing) {
          result.push(option);
        }
      });

      return result;
    }
  },
  data() {
    return {
      FRACTION_TYPES: FRACTION_TYPES,
      SPLIT_SIDES: SPLIT_SIDES,
      screenWidth: null,
      showItemDetails: true,
      windowResizeDebouncer: 0
    };
  },
  created() {
    this._setPageWidth();

    window.addEventListener('resize', () => {
      if (this.windowResizeDebouncer)
        window.clearTimeout(this.windowResizeDebouncer);

      this.windowResizeDebouncer = window.setTimeout(
        () => this._setPageWidth(),
        250
      );
    });
  },
  methods: {
    consolidateToppings(toppings: ITopping[], fractionType: string) {
      let displayToppings: ITopping[] = [];

      toppings.forEach(topping => {
        if (!topping)
          return;

        if (topping.fractionType !== fractionType.toUpperCase())
          return;

        if (displayToppings.length === 0) {
          displayToppings.push(topping);
        } else {
          let existingTopping = displayToppings.find(x => x.itemId === topping.itemId);

          if (existingTopping) {
            if (!existingTopping.price && topping.price)
              existingTopping.price = topping.price;
          } else {
            displayToppings.push(topping);
          }
        }
      });

      return displayToppings;
    },

    getDisplayItems(toppings: ITopping[], fractionType: string) {
      return this.consolidateToppings(toppings, fractionType);
    },

    getItemLink(): string {
      return !this.splitSide ? this.item.imageLink : this.item.split[this.splitSide].imageLink;
    },

    hasRequiredItems(item: IMenuItem) {
      return item.requiredItems && item.requiredItems.length > 0;
    },

    isToppingInStock(topping: ITopping): boolean {
      return isToppingInStock(cart.cart, this.item, topping);
    },

    isMobile(): boolean {
      return this.screenWidth === 'xs';
    },

    getRequiredOptions(item: IMenuItem) {
      if (!item.requiredOptions) {
        return [];
      }

      let options: IRequiredOption[] = [];

      for (let requiredOption of item.requiredOptions) {
        options.push(requiredOption);

        if (!item.requiredItems) {
          continue;
        }

        for (let requiredItem of item.requiredItems) {
          if (requiredItem.requirementId === requiredOption.objectId) {
            this.addRequiredOptionsToArray(options, requiredItem);
          }
        }
      }

      return options;
    },

    addRequiredOptionsToArray(options: IRequiredOption[], item: IRequiredItem) {
      if (!item.requiredOptions)
        return;

      for (let requiredOption of item.requiredOptions) {
        options.push(requiredOption);

        if (!item.requiredItems)
          continue;

        for (let requiredItem of item.requiredItems) {
          if (requiredItem.requirementId === requiredOption.objectId) {
            this.addRequiredOptionsToArray(options, requiredItem);
          }
        }
      }
    },

    hasRequiredOption(item: IMenuItem, option: IRequiredOption) {
      let requiredItems: IRequiredItem[] = this.getRequiredItemsFlat(item, option);

      return !!(requiredItems && requiredItems.length > 0);
    },

    getRequiredItemsFlat(item: IMenuItem, option: IRequiredOption) {
      let requiredItems: IRequiredItem[] = [];

      for (let requiredItem of item.requiredItems) {
        if (this.doesRequirementMatchOption(requiredItem, option)) {
          requiredItems.push(requiredItem);
        }

        if (!requiredItem.requiredItems) {
          continue;
        }

        for (let secondaryRequiredItem of requiredItem.requiredItems) {
          if (this.doesRequirementMatchOption(secondaryRequiredItem, option)) {
            requiredItems.push(secondaryRequiredItem);
          }

          if (!secondaryRequiredItem.requiredItems) {
            continue;
          }

          for (let tertiaryRequiredItem of secondaryRequiredItem.requiredItems) {
            if (this.doesRequirementMatchOption(tertiaryRequiredItem, option)) {
              requiredItems.push(tertiaryRequiredItem);
            }
          }
        }
      }

      return requiredItems;
    },

    doesRequirementMatchOption(requiredItem: IRequiredItem, option: IRequiredOption): boolean {
      return requiredItem.requirementId === option.objectId;
    },

    hasIncludedItems(item: IMenuItem, fractionType: string) {
      return item.includedItems && item.includedItems.filter((menuItem) => {
        return menuItem.fractionType === fractionType.toUpperCase();
      }).length > 0;
    },

    toggleItemDetails() {
      this.showItemDetails = !this.showItemDetails;
    },

    // Styling
    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    },

    getItemImageStyle(imageLink: string): any {
      if (imageLink) {
        return {
          'height': '15px',
          'width': '15px'
        };
      }

      return {
        'width': '15px'
      };
    },

    // Private functions

    _setPageWidth(): void {
      let pageWidth: number = window.innerWidth,
        MQ = util.MEDIA_QUERIES;

      if (this.environment.isMobile) {
        this.showItemDetails = false;
      }

      if (pageWidth < MQ.SM) {
        this.screenWidth = 'xs';
        this.showItemDetails = false;
      } else if (pageWidth >= MQ.SM && pageWidth < MQ.MD) {
        this.screenWidth = 'sm';
      } else if (pageWidth >= MQ.MD && pageWidth < MQ.LG) {
        this.screenWidth = 'md';
      } else {
        this.screenWidth = 'lg';
      }
    },

    finishEdit() {
      this.$emit('finishEdit', this.item.objectId);
    },

    removeItem() {
      this.$emit('removeItem', this.item.objectId);
    }
  }
});
