
















































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import {LatLngLiteral} from '../../common/models/LatLng';
import { IRestaurant } from '../types/restaurant.types';

export default Vue.extend({
    mixins: [baseMixin],
    props: {
        buttonTitle: String,
        distanceFromUser: String,
        locationFinderActive: Boolean,
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },
        showButton: {
            type: Boolean,
            default: true
        },
        userLocation: Object as () => LatLngLiteral
    },
    computed: {
        isOpen(): boolean {
            return this.restaurant.restaurantOpenInitialLoad;
        },

        isSchedulingAllowed(): boolean {
            return this.restaurant.allowScheduling;
        }
    },
    methods: {
        getButtonClass(): any {
            return this._designService.getButtonClass();
        },

        getButtonStyle(): any {
            return {
                'border-color': this._designService.buttonBorderColor,
                'font-family': this._designService.getDesign().fontBody,
                'background-color': this._designService.popupTabColor,
                'color': this._designService.popupMainTextColor
            };
        },

        getButtonTitle(): string {
          if (this.buttonTitle) {
            return this.buttonTitle;
          } else {
            if (this.isOpen) {
              return 'ORDER';
            } else if (this.isSchedulingAllowed) {
              return 'SCHEDULE ORDER';
            } else {
              return 'VIEW MENU';
            }
          }
        },

        getInverseButtonStyle(): any {
            return {
                'border-color': this._designService.buttonBorderColor,
                'font-family': this._designService.getDesign().fontBody,
                'background-color': this._designService.popupMainTextColor,
                'color': this._designService.popupTabColor
            };
        },

        map($event, restaurant: IRestaurant): boolean {
            this.mapAddress($event, restaurant);

            if (!this.environment.isMobile) {
                this.$emit('showMap', restaurant);
            }

            return false;
        }
    }
});
