























import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: Boolean,
    id: String,
    nativeEventEmit: Boolean,
    radio: Boolean,
    title: {
      required: true, // for accessibility
      type: String
    },
    value: Boolean // equivalent to "checked" property
  },

  methods: {
    onClick() {
      let emit;
      if (this.nativeEventEmit) {
        emit = {target: {checked: !this.value}};
      } else {
        emit = !this.value;
      }

      this.$emit('input', emit);
      this.$emit('click', emit);
    }
  }
});
