import {BaseApiService} from '../../common/services/BaseApiService';
import * as ENDPOINTS from '../order.constants';
import {ITenderRequest} from '../models/TenderRequest';
import {RequestMethod} from '../../common/services/axios-http.service';

export class OrderService extends BaseApiService {
    submitTenderRequest(payload: { tenderRequest: ITenderRequest }) {
        return this.request({
            body: payload.tenderRequest,
            method: RequestMethod.Post,
            url: ENDPOINTS.API_SUBMIT_TENDER_REQUEST_URL
        });
    }

    checkOrderStatus() {
        return this.request({
            method: RequestMethod.Get,
            url: ENDPOINTS.API_ORDER_CHECK_STATUS_URL
        });
    }

    fetchHistoricalOrder(payload: { locationId: string, userId: string }) {
        return this.request({
            method: RequestMethod.Get,
            search: `location=${payload.locationId}&user=${payload.userId}`,
            url: ENDPOINTS.API_FETCH_HISTORICAL_ORDERS_URL
        });
    }

    preauthorizePayment(payload: {
        isScheduled: boolean,
        locationId: string,
        tenderRequest: ITenderRequest
    }) {
        const postBody = Object.assign({
            scheduled: payload.isScheduled
        }, payload.tenderRequest);

        return this.request({
            method: RequestMethod.Post,
            body: postBody,
            url: ENDPOINTS.API_PREAUTHORIZE_PAYMENT_URL,
            search: `location=${payload.locationId}`
        });
    }

    confirmCurbside(locationId: string, orderId: string, vehicleColor: string, vehicleType: string, vehicleLocation: string) {
        return this.request({
            method: RequestMethod.Post,
            body: {
                color: vehicleColor,
                parkingSpot: vehicleLocation,
                vehicleType
            },
            url: ENDPOINTS.API_CONFIRM_CURBSIDE_URL.replace('{locationId}', locationId).replace('{orderId}', orderId)
        });
    }
}

export default new OrderService();
