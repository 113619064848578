





























































































































import Vue from 'vue';
import storageService from '../services/storage.service';
import baseMixin from '../mixins/base-component.mixin';
import { routerLink } from '../directives/RouterLink';
import modalService from '../services/modal.service';
import { IAccountInfo } from '../../account/types/account.types';

declare var VENDOR_PREFIX: string;

export default Vue.extend({
    mixins: [baseMixin],

    components: {
      RefundPolicyModal: require('./RefundPolicyModal.vue').default,
      SecurityPolicyModal: require('./SecurityPolicyModal.vue').default
    },

    directives: { routerLink },

    props: {
      account: {
          type: Object as () => IAccountInfo,
          required: true
      }
    },

    computed: {
      _modalService() {
        return modalService;
      },

      // getters

      appStoreUrl(): string {
        return this.account.appStoreUrl;
      },

      googlePlayUrl(): string {
        return this.account.googlePlayUrl;
      },

      thrivePOSLogoUrl(): string {
        return VENDOR_PREFIX + 'images/thrive-pos-logo.png';
      }
    },
    data() {
      return {
        shouldShowAppDownload: false
      };
    },
    created() {
      if (!storageService.localStorage.getItem('HideAppDownload')) {
        if (this.isMobile && !this.isMobileApp
          && ((this.isiOS && !!this.appStoreUrl) || (this.isAndroid && !!this.googlePlayUrl))) {
          this.shouldShowAppDownload = true;
        }
      }
    },
    methods: {
      hideAppDownload(): void {
        this.shouldShowAppDownload = false;

        storageService.localStorage.setItem('HideAppDownload', true);
      },

      onRefundPolicyClick() {
        this.$refs.refundPolicyModal.open();
      },

      onSecurityPolicyClick() {
        this.$refs.securityPolicyModal.open();
      },

      openFeedback() {
        this._modalService.feedbackModal$.emit(true);
      },

      // Styling
      getHtmlFooter(): any {
        return this._designService.getDesign() ? this._designService.getDesign().htmlFooter : null;
      }
    }
  });
