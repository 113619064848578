import { BaseApiService } from '../../common/services/BaseApiService';
import { RequestMethod } from '../../common/services/axios-http.service';
import { API_RECAPTCHA_VERIFY_URL } from '../recaptcha.constants';

export class RecaptchaService extends BaseApiService {
  verify(payload: { locationId: string, cartId: string, responseToken: string }) {
    return this.request({
      method: RequestMethod.Post,
      url: API_RECAPTCHA_VERIFY_URL,
      body: {
        responseToken: payload.responseToken
      },
      search: `locationId=${payload.locationId}&cart=${payload.cartId}`
    });
  }
}

export default new RecaptchaService();
