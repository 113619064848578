


















import Vue from "vue";

export default Vue.extend({
  name: 'StickyActions',
  props: {
    stickTop: {
      type: Boolean
    }
  },
  data: () => ({
    isSticky: false
  }),
  mounted () {
    const observer = new IntersectionObserver(
      ([e]) => this.isSticky = e.intersectionRatio < 1,
      { threshold: [1] }
    )

    observer.observe(this.$el)
  }
});
