




import Vue from 'vue';
import RestaurantChooser from '../components/RestaurantChooser.vue';

export default Vue.extend({
  components: { RestaurantChooser }
});
