






















































































































































  import Vue from 'vue';
  import { ICoupon } from '../../coupons/models/Coupon';
  import { IMenuItem, ITopping } from '../../menu/models/Item';
  import designMixin from '../../common/mixins/design.mixin';
  import filtersMixin from '../../common/mixins/filters.mixin';
  import { isToppingInStock } from '../helpers/cart.helpers';
  import cart from '../models/Cart';

  export default Vue.extend({
      mixins: [designMixin, filtersMixin],

      props: {
          coupons: {
              type: Array as () => Array<ICoupon>,
              required: true
          },

          image: String,

          item: {
              type: Object as () => IMenuItem,
              required: true
          }
      },
      computed: {
        // Getters

        itemIndex() {
          return this.$attrs['data-index'];
        },

        adjustedItemPrice() {
          let itemPrice = this.item.price;

          const fulfilledCoupons = this.getFulfilledCoupons().filter(c => {
            return c.eachPrice != 0 && c.showAsLineItem === false;
          });

          for (let i = 0; i < fulfilledCoupons.length; i++) {
            itemPrice += fulfilledCoupons[i].eachPrice;
          }

          return itemPrice;
        }
      },
  data() {
    return {
      detailsToggled: true,
      initialized: false,
      leftToppingMap: [],
      rightToppingMap: [],
      wholeToppingMap: []
    };
  },
  created() {
    if (this.item.includedItems && this.item.includedItems.length > 0) {
      this.buildToppingMaps();
    }

    this.initialized = true;
  },
  methods: {
    getFulfilledCoupons() {
      let result = [];

      for (let fulfilledCoupon of this.item.fulfilledCoupons) {
        for (let coupon of this.coupons) {
          if (fulfilledCoupon.couponObjectId !== coupon.objectId
            || coupon.entireOffer || coupon.groups.length === 0) {
            continue;
          }

          fulfilledCoupon.name = coupon.name;
          fulfilledCoupon.showAsLineItem = coupon.showAsLineItem;

          result.push(fulfilledCoupon);

          break;
        }
      }

      return result;
    },

    getSplitText(): string {
      return `1/2 ${this.item.split.left.name} 1/2 ${this.item.split.right.name}`;
    },

    buildToppingMaps() {
      for (let topping of this.item.includedItems) {
        this.addToToppingMap(topping, 'WHOLE', this.wholeToppingMap);
        this.addToToppingMap(topping, 'LEFT', this.leftToppingMap);
        this.addToToppingMap(topping, 'RIGHT', this.rightToppingMap);
      }

      for (let requiredItem of this.item.requiredItems) {
        if (requiredItem.name === 'NO') {
          continue;
        }

        this.addToRequirementMap(requiredItem, this.wholeToppingMap);

        if (!requiredItem.requiredItems) {
          continue;
        }

        for (let secondaryRequirement of this.item.requiredItems) {
          if (secondaryRequirement.name === 'NO') {
            continue;
          }

          this.addToRequirementMap(secondaryRequirement, this.wholeToppingMap);

          if (!secondaryRequirement.requiredItems) {
            continue;
          }

          for (let tertiaryRequirement of this.item.requiredItems) {
            if (tertiaryRequirement.name === 'NO') {
              continue;
            }

            this.addToRequirementMap(tertiaryRequirement, this.wholeToppingMap);
          }
        }
      }
    },

    addToRequirementMap(requiredItem: any, toppingMap: any[]) {
      let existingTopping = this.getTopping(requiredItem, toppingMap);

      if (existingTopping) {
        existingTopping.quantity = (existingTopping.quantity ? 0 : existingTopping.quantity) + 1;
      } else {
        let newTopping = {
          itemId: requiredItem.itemId,
          name: requiredItem.name,
          quantity: requiredItem.quantity
        };

        toppingMap.push(newTopping);
      }
    },

    addToToppingMap(topping: any, type: string, toppingMap: any[]) {
      if (topping.fractionType.toUpperCase() === type.toUpperCase()) {
        let existingTopping = this.getTopping(topping, toppingMap);

        if (existingTopping) {
          existingTopping.quantity = existingTopping.quantity + topping.quantity;
        } else {
          let newTopping = {
            itemId: topping.itemId,
            name: topping.name,
            quantity: topping.quantity
          };

          toppingMap.push(newTopping);
        }
      }
    },

    getTopping(topping: { itemId: string, name: string, quantity: number }, toppingMap: any[]) {
      for (let existingTopping of toppingMap) {
        if (existingTopping.itemId === topping.itemId) {
          return existingTopping;
        }
      }

      return undefined;
    },

    getNonSizeStyle(): string {
      for (let style of this.item.styles) {
        if (style.type.toUpperCase() !== 'SIZE') {
          return style.name;
        }
      }

      return '';
    },

    toggleItemDetails() {
      this.detailsToggled = !this.detailsToggled;
    },

    hasToppings(toppingList: ITopping[], type: string): boolean {
      if (!toppingList) {
        return false;
      }

      for (let topping of toppingList) {
        if (topping.fractionType.toUpperCase() === type.toUpperCase()) {
          return true;
        }
      }

      return false;
    },

    getToppings(toppingList: any[]) {
      let toppings: string = '';

      for (let topping of toppingList) {
        let isRequirement = this.item.requiredItemsFlat.find(
          req => req.name.toUpperCase() === topping.name.toUpperCase()
        );

        if (isRequirement) {
          continue;
        }

        if (topping.quantity === 0 || !this.isToppingInStock(topping)) {
          toppings += 'NO ' + topping.name;
        } else if (topping.quantity > 1) {
          toppings += topping.name + ' x' + topping.quantity;
        } else {
          toppings += topping.name;
        }

        toppings += ', ';
      }

      return toppings.substring(0, toppings.lastIndexOf(','));
    },

    getRequirements() {
      if (!this.item || !this.item.requiredItemsFlat) {
        return '';
      }

      let requirements: string = '';

      for (let requirement of this.item.requiredItemsFlat) {
        requirements += `${requirement.name.toUpperCase()}, `;
      }

      return requirements.substring(0, requirements.lastIndexOf(','));
    },

    getRequiredItems() {
      if (!this.item || !this.item.requiredItemsFlat) {
        return [];
      }

      return this.item.requiredItemsFlat;
    },

    isToppingInStock(topping: ITopping): boolean {
      return isToppingInStock(cart.cart, this.item, topping);
    },

    editItem() {
      this.$store.commit('cart/SET_EDITING_CHECKOUT_ITEM', true);
      this.$emit('editItem', this.item);
    },

    removeCoupon(coupon) {
      this.$emit('removeCoupon', coupon);
    },

    changeQuantity() {
      this.$emit('changeQuantity', this.item);
    },

    decQuantity() {
      if (this.item.quantity === 1) return;
      this.item.quantity--;
      this.changeQuantity();
    },

    incQuantity() {
      this.item.quantity++;
      this.changeQuantity();
    },

    removeItem() {
      this.$emit('removeItem', this.item.objectId);
    }
  }
});
