




































































import Vue from 'vue';
import designService from '../../common/services/design.service';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import { MODAL_ACTIONS } from '../../common/common.constants';
import modalBus from '../../common/messaging/modal.bus';
import modalService from '../../common/services/modal.service';
import { GENERIC_DECLINE_MESSAGE } from '../stores/order.store';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import ModalModern from '../../common/components/ModalModern.vue';

export default Vue.extend({
    mixins: [subscriberMixin],
    components: {
        ModalModern
    },
    props: {
        message: {
            type: String,
            required: false
        },
        progress: {
            type: Number,
            required: false
        },
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        },
        submitting: {
            type: Boolean,
            required: false
        },
        successful: {
            type: Boolean,
            required: false
        }
    },
    computed: {
        // services

        _designService () {
            return designService;
        },

        _modalBus () {
            return modalBus;
        },

        _modalService () {
            return modalService;
        },

        // getters

        errorMessage() {
            let phone;

            if (this.restaurant && this.restaurant.address) {
                phone = this.restaurant.address.phone;
            } else {
                phone = '';
            }

            return 'Please call the restaurant at ' + phone + '.';
        },

        isDeclineError() {
          return this.message === GENERIC_DECLINE_MESSAGE;
        }
    },
    mounted () {
        this.subscribe(this._modalService.progressBarModal$, () => {
            this.open();
            this._designService.setModalStyling();
        });

        // Set up listeners for modals
        this.subscribe(this._modalBus.orderProgressModal$, (action: boolean) => {
            if (action === MODAL_ACTIONS.OPEN) {
                this.open();
            } else if (action === MODAL_ACTIONS.CLOSE) {
                this.close();
            }
        });
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$emit('close');

            this.$refs.modal.close();
        },

        getProgress(): any {
            return {
                width: `${this.progress}%`
            };
        },

        orderSuccess() {
            this.$emit('orderSuccess', this);
        },

        // styling

        getButtonClass(): any {
            return this._designService.getButtonClass();
        },

        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        }
    },
    watch: {
      progress(value, oldValue) {
        if (Number(value) >= 50 && Number(oldValue) != 50) {
          this.$refs.progressbar.classList.add('animated');
        } else if (Number(value) === 100 && Number(oldValue) != 100) {
          this.$refs.progressbar.classList.remove('animated');
        }
      }
    }
});
