




export default {
  name: "LoadingCircle",
  props: {
    lightBackground: false
  }
}
