






























































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurantListMixin from '../../restaurants/mixins/restaurant-list.mixin';
import { IRestaurant } from '../types/restaurant.types';
import { Util } from '../../common/services/Util';
import routeService from '../../common/services/route.service';
import RestaurantCardModern from './RestaurantCardModern.vue';
import RestaurantListModal from './RestaurantListModal.vue';
import restaurantLoaderService from '../services/restaurant-loader.service';
import profile from '../../profile/models/Profile';

export default Vue.extend({
  mixins: [baseMixin, restaurantListMixin],
  components: {
    RestaurantCardModern,
    RestaurantListModal
  },
  computed: {
    isPostalCodeValid() {
      if (this.selectedRestaurant) {
        return true;
      } else {
        const postalCode = this.postalCode;

        return postalCode && postalCode.length >= 5 && postalCode.length <= 7;
      }
    },

    location() {
      if (this.userLocation === false) {
        return null;
      }

      return this.userLocation;
    },

    route() {
      return Util.getQueryParam('route');
    }
  },
  data() {
    return {
      distanceFromUser: null, // number
      postalCode: null, // string
      postalCodeChanged: false,
      restaurant: null, // IRestaurant
      searchingForLocations: false
    };
  },
  methods: {
    chooseLocation() {
      if (!this.isPostalCodeValid) {
        this.postalCodeChanged = true;

        return;
      }

      this.searchingForLocations = true;

      this.findLocationsNearMe(this.postalCode).then(() => {
        this.searchingForLocations = false;

        this.$refs.restaurantListModal.open();
      }).catch(() => {
        this.searchingForLocations = false;
      });
    },

    isChanged(isChanged: boolean): boolean {
      return isChanged || this.submitted === true;
    },

    isError(isValid, isChanged): boolean {
      return this.isChanged(isChanged) && !isValid;
    },

    onSelectRestaurant(payload: { restaurant: IRestaurant, distance?: string }) {
      this.restaurant = payload.restaurant;
      this.distanceFromUser = payload.distance;

      if (this.route) {
        restaurantLoaderService.selectRestaurantById(payload.restaurant.objectId).then(() => {
          profile.updateLocation(payload.restaurant.objectId);

          Util.waitUntil(() => profile.user && typeof profile.user.coupons !== 'undefined').then(() => {
            routeService.route(this.route);
          });
        });
      } else {
        restaurantLoaderService.chooseRestaurantById(payload.restaurant.objectId).then(() => {
          profile.updateLocation(payload.restaurant.objectId);
        });
      }
    }
  }
});
