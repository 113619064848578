





























import Vue from 'vue';
import { Util } from '../services/Util';
import designMixin from '../mixins/design.mixin';
import environment from '../services/environment.service';

export default Vue.extend({
  mixins: [designMixin],
  props: {
    id: String,
    name: String,
    value: String
  },
  computed: {
    // models
    environment() {
      return environment;
    },

    // getters

    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:value', value);
      }
    },
    dateFieldValue: {
      get() {
        if (!this.value) {
          return null;
        }

        if (!/^[0-9\-\\]*$/.test(this.value)) {
          return;
        }

        let [y, m, d] = this.value.split('-');

        return `${y}-${m}-${d}`;
      },
      set(value: string) {
        if (!/^[0-9\-\\]*$/.test(value)) {
          return;
        }

        let [y, m, d] = value.split('-');

        this.$emit('update:value', `${y}-${m}-${d}`);
      }
    }
  },
  data() {
    return {
      lastValue: null
    };
  },
  created() {
    if (!this.value && this.environment.isMobile) { // mobile browsers use a date picker UI that always have a value
      this.$emit('update:value', '2000-01-01');

      this.onChange();
    }
  },
  methods: {
    onKeyUp(event) {
      if (this.textValue) {
        if (event.keyCode === 8) {
          if (this.lastValue.substr(this.lastValue.length - 1) === '/')
            this.textValue = this.textValue.substring(0, this.textValue.length - 1);

          this.lastValue = this.textValue;

          return;
        }

        // if (this.value.substring(0, this.value.length - 1) === this.lastValue) { // adding onto the end
        if (event.which !== 191) { // not slash and end of month or day
          if (/^[0-9]{2}$/.test(this.textValue) || /^[0-9]{1,2}\/[0-9]{2}$/.test(this.textValue))
            this.textValue += '/';
        }
        // }

        if (Util.parseDate(this.textValue))
          this.$emit('validValue', this.textValue);

        this.lastValue = this.textValue;
      }
    },

    onEnter() {
      this.$emit('enter');
    },

    onChange() {
      const me = this;

      setTimeout(() => {
        this.$emit('change', me.textValue);
      });
    }
  }
});
