































import Vue from 'vue';
import designService from '../../common/services/design.service';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import {MODAL_ACTIONS} from '../../common/common.constants';
import modalBus from '../../common/messaging/modal.bus';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import ModalModern from '../../common/components/ModalModern.vue';

export default Vue.extend({
    mixins: [subscriberMixin],
    components: {
        ModalModern
    },
    props: {
        restaurant: {
            type: Object as () => IRestaurant,
            required: true
        }
    },
    computed: {
        // services

        _designService () {
            return designService;
        },

        _modalBus () {
            return modalBus;
        },
    },
    created () {
        this.subscribe(this._modalBus.orderSubmissionTimeoutModal$, (action: boolean) => {
            if (action === MODAL_ACTIONS.OPEN) {
                this.open();
            } else if (action === MODAL_ACTIONS.CLOSE) {
                this.close();
            }
        });
    },
    methods: {
        open() {
            this.$refs.modal.open();
        },

        close() {
            this.$refs.modal.close();
        },

        // styling

        getButtonClass(): any {
            return this._designService.getButtonClass();
        },

        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        }
    }
});
