









import Vue from 'vue';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import profile from '../models/Profile';
import PasswordReset from '../components/PasswordReset.vue';

export default Vue.extend({
    mixins: [subscriberMixin],
    components: {PasswordReset},
    computed: {
        // models

        profile () {
            return profile;
        },

        // getters

        resetError() {
            return this.profile.resetError;
        },

        resetToken() {
            return this.profile.resetToken;
        }
    },
    data () {
        return {
            initialized: false
        };
    },
    created () {
        this.initialized = true;
    },
    methods: {
        changePassword(payload): void {
            this.profile.changePasswordWithToken(payload);
        }
    }
});
