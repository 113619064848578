








































































































































































































































import { Address, IAddress } from '../../common/models/Address';
  import {emptyAddress, ITenderRequest} from '../../order/models/TenderRequest';
  import {Util} from '../../common/services/Util';
  import LoadingCircle from '../../common/components/LoadingCircle.vue';
  import Vue from 'vue';
  import StateSelect from '../../common/components/StateSelect.vue';
  import CheckboxModern from '../../common/components/CheckboxModern.vue';
  import maps from '../../maps/models/Maps';
  import { IUser } from '../../profile/stores/profile.store';
import { ORDER_TYPES } from '../types/restaurant.types';

  export default Vue.extend({
    name: 'DeliveryAddressModern',
    components: { LoadingCircle, StateSelect, CheckboxModern },
    props: {
      addresses: {
        type: Array as () => Array<IAddress>,
        required: false
      },
      orderType: {},
      searchingForLocations: {
        type: Boolean,
        required: true
      },
      suggestedAddresses: Array as () => Array<IAddress>,
      tenderRequest: {
        type: Object as () => ITenderRequest,
        required: true
      },
      user: Object as () => IUser,
    },
    data: () => ({
      deliveryAddress: null, // IAddress
      enterNewDeliveryAddress: false,
      hideEnterDeliveryAddressBtn: false,
      invalidDeliveryAddress: false,
      lastCheckedPostalCode: null, // string
      ORDER_TYPES,
      saveDeliveryAddress: false,
      selectedDeliveryAddress: null, // IAddress
      validations: {
        addressLine: true,
        addressLine2: true,
        city: true,
        postalCode: true,
        state: true
      }
    }),
    created() {
      this.deliveryAddress = new Address(this.tenderRequest.deliveryAddress);

      if (this.user && this.user.objectId) {
        if (!this.addresses || this.addresses.length === 0 || Util.isEmpty(this.addresses)) {
          this.enterNewDeliveryAddress = true;
          this.hideEnterDeliveryAddressBtn = true;
        } else {
          this.enterNewDeliveryAddress = false;

          this.selectedDeliveryAddress = this.addresses.find(function (address) {
            return address.primaryAddress;
          });
        }
      }
    },
    methods: {
      changeDeliveryAddress(address: IAddress, event): void {
        if (event.target.checked) {
          this.selectedDeliveryAddress = JSON.parse(JSON.stringify(address));
          this.enterNewDeliveryAddress = false;
        }
      },
      changeDeliveryAddressToNew(event): void {
        if (event.target.checked) {
          this.enterNewDeliveryAddress = true;
          this.selectedDeliveryAddress = undefined;
          this.tenderRequest.deliveryAddress = emptyAddress();
        }
      },
      checkPostalCode(): void {
        if (!this.tenderRequest || !this.tenderRequest.deliveryAddress
          || !this.tenderRequest.deliveryAddress.postalCode) {
          return;
        }

        if (this.lastCheckedPostalCode
          && this.lastCheckedPostalCode === this.tenderRequest.deliveryAddress.postalCode) {
          return;
        }

        this.validatePostalCode();

        this.lastCheckedPostalCode = this.tenderRequest.deliveryAddress.postalCode;

        maps.verifyPostalCode(this.tenderRequest.deliveryAddress.postalCode)
          .then(result => {
            this.validations.postalCode = true;

            let validatedAddress = result;

            if (validatedAddress.city) {
              this.tenderRequest.deliveryAddress.city = validatedAddress.city;

              this.validations.city = true;
            }

            if (validatedAddress.stateCode) {
              this.tenderRequest.deliveryAddress.stateCode = validatedAddress.stateCode;
              this.tenderRequest.deliveryAddress.stateName = validatedAddress.stateName;

              this.validations.state = true;
            }
          })
          .catch(() => {
            this.validations.postalCode = false;
          });
      },
      chooseSuggestedAddress(address: IAddress): void {
        if (!address) {
          return;
        }

        const aptNumber: string = this.tenderRequest.deliveryAddress.addressLine2;

        this.tenderRequest.deliveryAddress = address;

        if (aptNumber) {
          this.tenderRequest.deliveryAddress.addressLine2 = aptNumber;
        }

        this.selectedDeliveryAddress = undefined;
        this.enterNewDeliveryAddress = true;
        this.$emit('clearSuggestedAddresses');

        this.submitDeliveryAddress();
      },
      isSelectedDeliveryAddress(address: IAddress): boolean {
        return this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId === address.objectId;
      },

      getDeliveryFor(address: IAddress): string {
        return 'Delivery-' + address.objectId;
      },

      toggleSaveDeliveryAddress(): void {
        this.saveDeliveryAddress = !this.saveDeliveryAddress;
      },

      getFormattedAddress(address): string {
        return maps.getFormattedAddress(address);
      },

      submitDeliveryAddress(): void {
        if (this.selectedDeliveryAddress && this.selectedDeliveryAddress.objectId) {
          this.tenderRequest.deliveryAddress = JSON.parse(JSON.stringify(this.selectedDeliveryAddress));
        } else if (!this._isValidAddress()) {
          this.invalidDeliveryAddress = true;
        }

        if (this._validationsPassed()) {
          this.$emit('submitDelivery', {
            address: this.tenderRequest.deliveryAddress,
            saveDeliveryAddress: this.saveDeliveryAddress
          });
        }
      },

      // Validation Methods
      validateAddressLine(): void {
        this.validations.addressLine = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.addressLine, 250);
      },

      validateAddressLine2(): void {
        this.validations.addressLine2 = this._isValidInput(this.tenderRequest.deliveryAddress.addressLine2, 20);
      },

      validatePostalCode(): void {
        this.validations.postalCode = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.postalCode, 6);
      },

      validateCity(): void {
        this.validations.city = this._isValidRequiredInput(this.tenderRequest.deliveryAddress.city, 200);
      },

      validateState(event): void {
        this.validations.state = this._isValidSelect(event.currentTarget.value);
      },
      // Private Methods
      _isValidAddress(): boolean {
        if (this._hasTenderRequest()) {
          const deliveryAddress = this.tenderRequest.deliveryAddress;
          return this._isValidRequiredInput(deliveryAddress.addressLine, 250)
            && this._isValidInput(deliveryAddress.addressLine2, 20)
            && this._isValidRequiredInput(deliveryAddress.city, 200)
            && this._isValidRequiredInput(deliveryAddress.postalCode, 50)
            && this._isValidSelect(deliveryAddress.stateCode);
        } else {
          return false;
        }
      },

      _validationsPassed(): boolean {
        return this.validations.addressLine
          && this.validations.addressLine2
          && this.validations.city
          && this.validations.postalCode
          && this.validations.state;
      },

      _isValidRequiredInput(value: string, length: number): boolean {
        return value !== '' && value.length <= length;
      },

      _isValidInput(value: string, length: number): boolean {
        return !value || value.length <= length;
      },

      _isValidSelect(value: string): boolean {
        return value !== '';
      },

      _hasTenderRequest(): boolean {
        return this.tenderRequest && !!this.tenderRequest.deliveryAddress;
      },

      onDeliveryAddressChange() {
        this.tenderRequest.deliveryAddress.latitude = null;
        this.tenderRequest.deliveryAddress.longitude = null;
      }
    }
  });
