






import Vue from 'vue';
import selectedRestaurantOutletMixin from '../../common/mixins/selected-restaurant-outlet.mixin';

export default Vue.extend({
  mixins: [selectedRestaurantOutletMixin]
});
