








































































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import HereMap from '../../maps/components/HereMap.vue';
import RestaurantCardModern from './RestaurantCardModern.vue';
import maps from '../../maps/models/Maps';
import userRestaurantDistanceMixin from '../mixins/user-restaurant-distance.mixin';
import { IRestaurant } from '../types/restaurant.types';

export default Vue.extend({
  mixins: [baseMixin, userRestaurantDistanceMixin],
  components: {
    HereMap,
    RestaurantCardModern
  },
  props: {
    automaticallySetLocation: {
      type: Boolean,
      default: true
    },
    buttonTitle: String,
    findLocations: Boolean,
    loadingRestaurants: {
      type: Boolean,
      required: true
    },
    locationFinderActive: Boolean,
    pageResults: Boolean,
    selectedRestaurant: Object as () => IRestaurant,
    showNoLocationsFoundMessage: Boolean,
    showMultipleLocationsFoundMessage: Boolean
  },
  computed: {
    location() {
      if (this.userLocation === false) {
        return null;
      }

      return this.userLocation;
    },

    shownRestaurants() {
      let restaurants = this.restaurantList;

      if (!restaurants) {
        restaurants = this.restaurants;
      }

      if (!restaurants) {
        restaurants = [];
      }

      return this.pageResults ? restaurants.slice(0, this.restaurantsShown) : restaurants;
    },

    mapMarkers() {
      if (this.restaurantList) {
        return this.restaurantList.map(restaurant => {
          return {
            lat: restaurant.latitude,
            lng: restaurant.longitude,
            label: restaurant.name
          }
        });
      }
    }
  },
  data() {
    return {
      buildDistanceArrayIntervalId: null,
      mapVisible: false,
      restaurantSelected: false,
      restaurantsShown: 3,
      restaurantToMap: null,
      sectionHeight: 0
    };
  },
  methods: {
    changeLocation(event): void {
      event.preventDefault();
      this.$emit('onChangeLocation');
    },

    getCoordinates(type: string): any {
      return this.restaurantToMap[type];
    },

    hasRestaurants(): boolean {
      return this.shownRestaurants && this.shownRestaurants.length > 0;
    },

    onClickUseMyCurrentLocation() {
      maps.loadUserLocation().then(loc => {
        this.$emit('updateUserLocation', loc);

        if (loc) {
          setTimeout(() => {
            this.calculateDistances();
          });
        }
      });
    },

    selectClosestRestaurant() {
      this.$emit('selectClosestRestaurant');
    },

    setRestaurantSelected(event, restaurant: IRestaurant): void {
      if (this.automaticallySetLocation) {
        if (restaurant.redirectUrl !== null && restaurant.redirectUrl !== '') {
          this.environment.open(restaurant.redirectUrl);
        } else if (!event.target.classList.contains('map-navigation')) {
          this.restaurantSelected = true;
          this.$emit('selectRestaurant', {
            restaurant,
            distance: this.getDistanceString(restaurant)
          });
        }
      } else {
        this.restaurantSelected = true;
        this.$emit('selectRestaurant', {
          restaurant,
          distance: this.getDistanceString(restaurant)
        });
      }
    },

    showMap(restaurant: IRestaurant): void {
      if (this.environment.isiOS) {
        window.location.href = 'maps://?q=' + this.util.getFullAddress(restaurant.address);
      } else {
        this.restaurantToMap = restaurant;
        this.mapVisible = true;
        window.scrollTo(0, 0);
      }
    }
  }
});
