





import Vue from 'vue';
import RestaurantSelect from '../components/RestaurantSelect.vue';

export default Vue.extend({
  components: {
    RestaurantSelect
  }
});
