








































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import { LatLngLiteral } from '../../common/models/LatLng';
import { IRestaurant } from '../types/restaurant.types';
import cartHelperService from '../../cart/services/cart-helper.service';

export default Vue.extend({
  mixins: [baseMixin],
  props: {
    buttonTitle: String,
    distanceFromUser: String,
    locationFinderActive: Boolean,
    restaurant: {
      type: Object as () => IRestaurant,
      required: true
    },
    showButton: {
      type: Boolean,
      default: true
    },
    userLocation: Object as () => LatLngLiteral
  },
  computed: {
    cartHelperService() {
      return cartHelperService;
    },

    isClosed(): boolean {
      return !this.restaurant.restaurantOpenInitialLoad;
    },

    isSchedulingAllowed(): boolean {
      return this.restaurant.allowScheduling;
    },

    orderButtonTitle() {
      if (this.buttonTitle) {
        return this.buttonTitle;
      } else if (this.isClosed) {
        if (this.restaurant.allowScheduling) {
          return 'Schedule Order';
        } else {
          return 'View Menu';
        }
      } else {
        return 'ORDER NOW';
      }
    }
  },
  methods: {
    map($event, restaurant: IRestaurant): boolean {
      this.mapAddress($event, restaurant);

      if (!this.environment.isMobile) {
        this.$emit('showMap', restaurant);
      }

      return false;
    }
  }
});
