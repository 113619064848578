import { IMenuItem, IMenuItemSize, IRequiredItem, IRequiredOption, ITopping } from '../models/Item';
import { Util } from '../../common/services/Util';
import baseToppingCustomizationMixin from './base-topping-customization.mixin';
import designService from '../../common/services/design.service';
import notifierService from '../../common/services/notifier.service';
import { ICoupon, ICouponGroup } from '../../coupons/models/Coupon';
import Requirement from '../components/item-customization-layouts/shared/Requirement.vue';
import NoRequirement from '../components/item-customization-layouts/shared/NoRequirement.vue';
import { hoverScheme } from '../directives/ItemButtonHover';
import { IItemDesign } from '../stores/menu.store';
import cart from '../../cart/models/Cart';
import RequirementsManager, {
    getRequiredItemsFromPath,
    getRequiredOptionFromPath,
    RequiredItemPath
} from '../services/requirements-manager.service';
import ToppingsManager, { ACTIONS, FRACTION_TYPES } from '../services/toppings-manager.service';
import filtersMixin from '../../common/mixins/filters.mixin';
import { getAvailableCrustForCouponItem } from '../../coupons/helpers/coupon.helpers';
import { isRequiredItemInStock, isToppingInStock, itemsInStock } from '../../cart/helpers/cart.helpers';
const deepClone = require('lodash.clonedeep');

export default {
    mixins: [baseToppingCustomizationMixin, filtersMixin],
    components: {
        NoRequirement,
        Requirement
    },
    directives: { hoverScheme },
    props: {
        item: {
            type: Object as () => IMenuItem,
            required: true
        },

        itemDesign: {
            type: Object as () => IItemDesign,
            required: false
        },

        selectedCoupon: {
            type: Object as () => ICoupon,
            required: false
        },

        selectedCouponGroup: {
            type: Object as () => ICouponGroup,
            required: false
        },

        sizeAvailableMap: {
            type: Object as () => {[key: string]: boolean},
            required: true
        },

        sizes: {
            type: Array as () => Array<IMenuItemSize>,
            required: false
        },

        splitSide: String,

        styleList: {
            type: Object,
            required: true
        },

        toppingsList: {
            type: Object,
            required: true
        },

        toppingsManager: {
            type: Object as () => ToppingsManager,
            required: true
        },

        updating: {
            type: Boolean,
            required: true
        }
    },
    computed: {
        // models

        _designService () {
            return this.designService;
        },

        designService () {
            return designService;
        },

        notifierService () {
            return notifierService;
        },

        // getters

        cart() {
            return cart.cart;
        },

        cartModel() {
          return cart;
        },

        isToppingBasedPricing() {
          return this.item.pricingMethod === 'TOPPING_BASED';
        }
    },
    watch: {
        item() {
            this.requirementsManager.item = this.item;
        }
    },
    data () {
        return {
            clickedSizeName: '',
            displayItem: null,
            isCouponWalkthroughItem: false,
            requiredItemIds: null,
            requirementsManager: null
        };
    },
    created () {
        this.displayItem = this.item;
        this.requirementsManager = new RequirementsManager(this.item);
        this.requiredItemIds = this.requirementsManager.requirements.map(r => r.itemId);
        this.isCouponWalkthroughItem = !(!this.selectedCoupon || Object.keys(this.selectedCoupon).length === 0);
    },
    methods: {
        allowsFraction(topping: ITopping): boolean {
            let allowFraction;

            if (this.item.thriveControlCenter) {
                allowFraction = this.item.allowFraction;
            } else {
                allowFraction = topping.allowFraction;
            }

            return allowFraction && (!this.item.size || this.item.size.allowFraction);
        },

        changeSize(size, event) {
            this.clickedSizeName = size.name;

            let itemSize;

            for (let style of this.item.styles) {
                if (style.type.toLowerCase() === 'size') {
                    itemSize = style;
                }
            }

            if (!itemSize || event.currentTarget.value !== itemSize.styleId) {
                let newSize = event.currentTarget.id;

                for (let style of this.item.styles) {
                    if (style.type.toLowerCase() !== 'size') {
                        for (let styleType of this.styleList.types) {
                            let itemStyle;

                            if (style.type.toLowerCase() === styleType.toLowerCase()) {
                                itemStyle = style;
                            }

                            let stylesForSize = this.styleList.map[newSize][itemStyle.type];

                            let styleForSize = stylesForSize.filter((styleSize: any) => {
                                return styleSize.styleId === itemStyle.styleId;
                            })[0];

                            if (styleForSize.price === -1) {
                                for (let s of stylesForSize) {
                                    if (s.price !== -1) {
                                        this.$emit('updateStyle', s.objectId);
                                    }
                                }
                            }
                        }
                    }
                }

                this.$emit('updateSize', size.sizeId);
            }
        },

        getAvailableSizesForCouponItem() {
            const result = [];

            this.selectedCouponGroup.includes.forEach(include => {
                include.styles
                    .filter(style => style.type.toLowerCase() === 'size')
                    .forEach(size => result.push(size.sizeId));
            });

            return result;
        },

        changeStyle(style, event) {
            let itemStyle;

            for (let s of this.item.styles) {
                if (s.type.toLowerCase() === style.type.toLowerCase()) {
                    itemStyle = s;
                }
            }

            if (!itemStyle || event.currentTarget.value !== itemStyle.styleId) {
                this.$emit('updateStyle', style);
            }
        },

        requiredItemsInStock(items: IMenuItem[]): boolean {
            return itemsInStock(this.cart, items).length > 0;
        },

        styleIsDisabledForSize(style) {
            if (Util.isEmpty(this.styleList)) {
                return false;
            }

            if (!this.item.styles) {
                return true;
            }

            let itemSize;

            for (let itemStyle of this.item.styles) {
                if (itemStyle.type.toLowerCase() === 'size') {
                    itemSize = itemStyle;
                }
            }

            if (!itemSize || !this.styleList.map[itemSize.name])
                return false;

            let sizesForStyle: any[] = this.styleList.map[itemSize.name][style.type];

            let styleForSize = sizesForStyle.find(size => {
                return size.styleId === style.styleId;
            });

            return !styleForSize || styleForSize.price === -1;
        },

        sizeAvailable(size) {
            if (!this.sizeAvailableMap || Object.keys(this.sizeAvailableMap).length === 0) {
                return true;
            }

            let result;

            if (this.isCouponWalkthroughItem) {
                const sizes = this.getAvailableSizesForCouponItem();

                if (sizes.length === 0) {
                     result = true;
                } else {
                    result = sizes.indexOf(size.sizeId) > -1;
                }
            } else {
                result = !!this.sizeAvailableMap[size.name];
            }

            // disable size if there is a fractional topping for a size that doesn't support it
            if (result && !size.allowFraction && this.item.includedItems.length > 0) {
                result = !this.item.includedItems.find(topping => topping.fractionType !== FRACTION_TYPES.WHOLE);
            }

            return result;
        },

        crustAvailable(style) {
            if (this.isCouponWalkthroughItem) {
                const crusts = getAvailableCrustForCouponItem(this.selectedCouponGroup);

                if (crusts.length > 0) {
                    return crusts.indexOf(style.styleId) > -1;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        },

        // requirements

        getRequiredItemFor(itemId: string, parentItemId: string, index: number) {
            return `Req${itemId}-${parentItemId}-${index}`;
        },

        getRequirementOptionFor(option: IRequiredOption): string {
            if (option.parentItemId === this.item.itemId) {
                return '';
            }

            for (let requiredItem of this.requirementsManager.requirements) {
                if (requiredItem.itemId === option.parentItemId) {
                    return 'for ' + requiredItem.name;
                }
            }

            return '';
        },

        getRequiredOptions(item: IMenuItem) {
            if (!item.requiredOptions) {
                return [];
            }

            const requiredOptions = item.requiredOptions.slice(0);

            requiredOptions.sort((a: any, b: any) => {
                return a.sortNumber - b.sortNumber;
            });

            let options: IRequiredOption[] = [];

            for (let requiredOption of requiredOptions) {
                const requiredItemPath = {
                    optionId: requiredOption.objectId
                };

                options.push(Object.assign({}, requiredOption, {
                    parentInstanceId: item.objectId,
                    requiredItemPath
                }));

                const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);

                for (let requiredItem of requiredItems) {
                    if (requiredItem.requirementId === requiredOption.objectId) {
                        this.addNestedRequiredOptionsToArray(requiredItem, Object.assign({}, requiredItemPath, {
                            itemId: requiredItem.itemId,
                            objectId: requiredItem.objectId
                        }), options);
                    }
                }
            }

            return options;
        },

        addNestedRequiredOptionsToArray(requiredItem: IRequiredItem, requiredItemPath: RequiredItemPath, options: IRequiredOption[]) {
            if (!requiredItem.requiredOptions) {
                return;
            }

            for (let requiredOption of requiredItem.requiredOptions) {
                const nestedRequiredItemPath = deepClone(requiredItemPath);

                let bottomLevelPath = nestedRequiredItemPath;

                while (bottomLevelPath.nestedItemPath) {
                    bottomLevelPath = bottomLevelPath.nestedItemPath;
                }

                bottomLevelPath.nestedItemPath = {
                    optionId: requiredOption.objectId
                };

                options.push(Object.assign({}, requiredOption, {
                    parentInstanceId: requiredItem.objectId,
                    requiredItemPath: nestedRequiredItemPath
                }));

                const requiredItems = getRequiredItemsFromPath(nestedRequiredItemPath, this.item);

                requiredItems.forEach(r => {
                    const reqItemPath = deepClone(nestedRequiredItemPath);

                    bottomLevelPath = reqItemPath;

                    while (bottomLevelPath.nestedItemPath) {
                        bottomLevelPath = bottomLevelPath.nestedItemPath;
                    }

                    bottomLevelPath.itemId = r.itemId;
                    bottomLevelPath.objectId = r.objectId;

                    this.addNestedRequiredOptionsToArray(r, reqItemPath, options);
                });
            }
        },

        isRequiredItemInStock(requiredItem: IRequiredItem) {
          return isRequiredItemInStock(this.cart, this.item, requiredItem);
        },

        toggleRequired(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem, event) {
            if (!this.isRequiredItemInStock(requiredItem)) {
                return false;
            }

            if (event.target.checked) {
                return this.addRequired(requiredItemPath, requiredItem);
            } else {
                // get instance with objectId
                requiredItem = this.getRequiredInstance(requiredItemPath, requiredItem);

                if (!requiredItem) {
                    return false;
                }

                this.requirementsManager.remove(requiredItemPath, requiredItem);

                this.saveRequirements();

                return true;
            }
        },

        toggleNoRequired(requiredItemPath: RequiredItemPath, event): void {
            if (event.target.checked) {
                this.addNoRequired(requiredItemPath);
            } else {
                const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);

                const requiredNoItem: IRequiredItem = requiredItems.find(r => r.itemId === null);

                this.$emit('removeNoItem', requiredNoItem.objectId);
            }
        },

        addRequired(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem) {
            if (this.requirementsManager.add(this.cart, requiredItemPath, requiredItem)) {
                this.saveRequirements();

                return true;
            } else {
                const requiredOptionPath = deepClone(requiredItemPath);
                requiredOptionPath.itemId = null;
                requiredOptionPath.objectId = null;

                const option = getRequiredOptionFromPath(requiredItemPath, this.item);

                this.notifierService.error('You have reached the maximum selections for ' + option.name);

                return false;
            }

        },

        addNoRequired(requiredItemPath: RequiredItemPath) {
            const option = getRequiredOptionFromPath(requiredItemPath, this.item);

            if (option.parentItemId === this.item.itemId) {
                this.$emit('addNoRequiredItem', { requirementOption: option, parentId: this.item.objectId });

                return false;
            }

            let parentId: string = this.findParentId(option);
            this.$emit('addNoRequiredItem', { requirementOption: option, parentId });

            return true;
        },

        findParentId(option: IRequiredOption): string {
            let parentId: string;

            for (let requiredItem of this.requirementsManager.requirements) {
                if (requiredItem.itemId !== option.parentItemId) {
                    continue;
                }

                for (let requiredOption of requiredItem.requiredOptions) {
                    if (requiredOption.objectId === option.objectId) {
                        parentId = Util.getRequiredItemId(requiredItem);

                        break;
                    }
                }
            }

            return parentId;
        },

        isSelectedRequirement(requiredItemPath: RequiredItemPath, requiredItemId: string): boolean {
            const fullItemPath = deepClone(requiredItemPath);
            let deepestItem = fullItemPath;

            while (deepestItem.nestedItemPath) {
                deepestItem = deepestItem.nestedItemPath;
            }

            deepestItem.itemId = requiredItemId;

            return this.requirementsManager.hasRequirement(fullItemPath);
        },

        getRequiredInstance(requiredItemPath: RequiredItemPath, requiredItem?: IRequiredItem): any {
            if (this.requirementsManager.requirements.length <= 0) {
                return requiredItem;
            }

            return this.requirementsManager.getRequirement(requiredItemPath);
        },

        isNoSelected(requiredItemPath: RequiredItemPath) {
            if (this.requirementsManager.requirements.length <= 0) {
                return false;
            }

            const requiredItems = getRequiredItemsFromPath(requiredItemPath, this.item);

            return !!requiredItems.find(r => !r.itemId);
        },

        decreaseRequiredQty(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem) {
            const itemPath = deepClone(requiredItemPath);

            let nestedItemPath = itemPath;

            while (nestedItemPath.nestedItemPath) {
                nestedItemPath = nestedItemPath.nestedItemPath;
            }

            nestedItemPath.itemId = requiredItem.itemId;

            const result = this.requirementsManager.decreaseQuantity(itemPath);

            if (result) {
                this.saveRequirements();
            }
        },

        increaseRequiredQty(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem): void {
            if (this.requirementsManager.increaseQuantity(requiredItemPath, requiredItem)) {
                this.saveRequirements();
            } else {
                this.notifierService.error('You have reached the maximum selections for ' + requiredItem.name);
            }
        },

        getRequiredQty(requiredItemPath: RequiredItemPath, requiredItem: IRequiredItem): number {
            const itemPath = deepClone(requiredItemPath);

            let nestedItemPath = itemPath;

            while (nestedItemPath.nestedItemPath) {
                nestedItemPath = nestedItemPath.nestedItemPath;
            }

            nestedItemPath.itemId = requiredItem.itemId;

            return this.requirementsManager.getQuantity(itemPath);
        },

        saveRequirements() {
            this.$emit('updateRequirements', this.requirementsManager.requirements);
        },

        // styling

        getStyleList(styleName) {
            if (Object.keys(this.styleList).length > 0) {
                if (this.styleList.types.indexOf(styleName) > -1) {
                    let styleOptions: any[] = [];

                    for (let key in this.styleList.map) {
                        if (this.styleList.map.hasOwnProperty(key)) {
                            let styleList: any = this.styleList.map[key][styleName];

                            if (styleList) {
                                for (let style of styleList) {
                                    if (styleOptions.findIndex(x => x.name === style.name) < 0) {
                                        styleOptions.push(style);
                                    }
                                }
                            }
                        }
                    }

                    return styleOptions;
                }
            }
        },

        isSelectedStyle(style): boolean {
            let itemStyle: string = '';

            if (!this.item.styles) {
                return false;
            }

            for (let s of this.item.styles) {
                if (s.type.toLowerCase() === style.type.toLowerCase()) {
                    itemStyle = s.name;
                }
            }

            return style.name === itemStyle;
        },

        isSelectedSize(size): boolean {
            return size.name === this.item.size.name;
        },

        // toppings

        isToppingSelected(topping: ITopping, isSplit: boolean = false) {
            let splitSide;

            if (isSplit) {
                splitSide = this.splitSide;
            }

            return this.toppingsManager.hasTopping(topping, splitSide);
        },

        getToppingQuantity(topping: ITopping, fractionType?: string) {
            return this.toppingsManager.getToppingQuantity(topping, fractionType);
        },

        // topping quantity increase/decrease
        selectFractionType(topping: ITopping, fractionType: string, action?: string): void {
            if (this.isToppingBasedPricing) {
              this.$emit('resetValidation');
            }
            if (!action)
              action = ACTIONS.INCREASE;

            let change;

            if (action === ACTIONS.INCREASE) {
              change = 1;
              if (!this.isValidMaxToppingsLimit()) {
                return;
              }
            } else if (action === ACTIONS.DECREASE) {
              change = -1;
            }

            if (!fractionType) {
                const toppings = this.toppingsManager.getToppings(topping);

                if (toppings.length > 0) {
                    fractionType = toppings[0].fractionType;
                } else {
                    fractionType = FRACTION_TYPES.WHOLE;
                }
            }

            if (fractionType === FRACTION_TYPES.WHOLE) {
                // Check to see if there is already a quantity on whole toppings and, if so, increase that quantity --
                // If not clear and set back to 1
                if (this.getToppingQuantity(topping, FRACTION_TYPES.WHOLE) === 0) {
                    this.toppingsManager.clearTopping(topping);

                    topping.fractionType = FRACTION_TYPES.WHOLE;
                }
            } else {
                this.toppingsManager.clearTopping(topping, FRACTION_TYPES.WHOLE);
            }

            this.toppingsManager.adjustToppingQuantity(topping, fractionType, change);
            this.saveToppings();
        },

        isFractionSelected(topping: ITopping, fractionType: string): boolean {
            return this.toppingsManager.hasTopping(topping, fractionType);
        },

        toggleTopping(topping: any, event) {
            if (!isToppingInStock(this.cart, this.item, topping)) {
                return false;
            }

            let splitSide: string = this.splitSide;

            let fractionType: string = splitSide ? splitSide : FRACTION_TYPES.WHOLE;

            if (event.target.checked) {
                if (!this.isValidMaxToppingsLimit()) {
                  return;
                }
                if (!this.toppingsManager.addTopping(topping, fractionType)) {
                    event.target.checked = false;
                }
            } else {
                this.toppingsManager.clearTopping(topping, splitSide);
            }
            this.saveToppings();
        },

        toppingToggle($event) {
            if (this.isToppingBasedPricing) {
              this.$emit('resetValidation');
            }
            this.toggleTopping($event.topping, $event.event);
        },

        setFractionType($event) {
            this.selectFractionType($event.topping, $event.type, $event.change);
        },

        saveToppings() {
            this.$emit('updateToppings', this.toppingsManager.toppings);
        },

        hasToppingsInCategory(category) {
          return this.toppingsList.toppings[category.name].length > 0;
        },

        // Styling

        getFractionStyle(): any {
            return {
                color: this._designService.backgroundColor
            };
        },

        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        },

        getButtonClass(): any {
            return this._designService.getButtonClass();
        },

        getItemTabStyle(): any {
            return this._designService.getItemTabStyle();
        },

        getItemTabSelectedStyle(): any {
            return this._designService.getItemTabSelectedStyle();
        },

        // Private functions

        getRequiredItemId(requiredItem: IRequiredItem): string {
            return Util.getRequiredItemId(requiredItem);
        },

        isValidMaxToppingsLimit() {
          const { maxAllowed } = this.item;
          if (!this.isToppingBasedPricing || maxAllowed === null) {
            return true;
          }
          const { inclusionsCount, allowedToppings } = this.cartModel.selectedItemMaxToppingsData;
          if (this.toppingsManager.toppings.length - inclusionsCount >= allowedToppings) {
            this.$emit('validationError', `Limit has been met for ${this.item.name}.`);
            return false;
          }
          return true;
        }
    }
};
