















































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurantListMixin from '../mixins/restaurant-list.mixin';
import RestaurantListModal from './RestaurantListModal.vue';
import restaurants from '../models/Restaurants';
import order from '../../order/models/Order';
import LocationFinderModern from './LocationFinderModern.vue'
import cartHelperService from '../../cart/services/cart-helper.service';
import RestaurantGridModern from './RestaurantGridModern.vue';
import { IRestaurant, ORDER_TYPES } from '../types/restaurant.types';

declare var $: any;

export default Vue.extend({
  mixins: [baseMixin, restaurantListMixin],
  components: {
    LocationFinderModern,
    RestaurantGridModern,
    RestaurantListModal
  },
  computed: {
    // models

    cartHelperService() {
      return cartHelperService;
    },

    order() {
      return order;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    addresses() {
      return this.profile.userAddresses;
    },

    suggestedAddresses() {
      return this.profile.suggestedAddresses;
    },

    tenderRequest() {
      return this.order.tenderRequest;
    },

    user() {
      return this.profile.user;
    }
  },
  data() {
    return {
      locationString: null,
      searchingForLocations: false,
      showLocationList: false
    };
  },
  mounted() {
    if ($('.modal-backdrop').length > 0) {
      $('.modal-backdrop').remove();
      $('body').removeClass('modal-open');
    }

    window.scrollTo(0, 0);
  },
  methods: {
    loadData(): boolean { // TODO
      if (this.isInitialized) {
        /*const accountId: string = JSON.parse(this.storage.localStorage.getItem('tolAccountId'));
                const previewMode: boolean = JSON.parse(this.storage.sessionStorage.getItem('previewMode'));
                const singleLocation: boolean = JSON.parse(this.storage.sessionStorage.getItem('singleLocation'));

                this.restaurants.load(accountId, previewMode);*/
      } else {
        const restaurantList = this.restaurants.list;

        if (restaurantList.length === 1) {
          this.restaurantLoader.chooseRestaurant(restaurantList[0]);
        }
      }

      return true;
    },

    onClearSuggestedAddresses() {
      this.profile.clearSuggestedAddresses();
    },

    onChangeLocation() {
      this.userLocation = null;

      this.showLocationList = false;
    },

    onFindLocationsNearMe(origin: string) {
      this.orderType = ORDER_TYPES.DEFAULT;
      this.locationString = origin;
      this.searchingForLocations = true;

      this.findLocationsNearMe(origin).then(() => {
        this.showLocationList = true;
        this.searchingForLocations = false;

        this.$refs.restaurantListModal.open();
      }).catch(() => {
        this.searchingForLocations = false;
      });
    },

    // override restaurant list mixin method so that we set order type before navigating to menu
    onSelectModalRestaurant(payload: { restaurant: IRestaurant, distance?: string }) {
      if (this.redirectIfAvailable(payload.restaurant)) {
        return;
      }

      if (this.locationFinderActive) {
        this.restaurantLoader.selectRestaurant(payload.restaurant).then(restaurant => {
          if (this.orderType) {
            if (this.orderType === ORDER_TYPES.DELIVERY) {
              return this.setDeliveryOrderTypeAndZone(restaurant).then(() => {
                this.navigateToInnerLandingPage();

                return restaurant;
              });
            } else {
              return this.cart.setOrderType(this.orderType).then(() => {
                this.navigateToInnerLandingPage();

                return restaurant;
              });
            }
          } else {
            this.navigateToInnerLandingPage();

            return restaurant;
          }
        });
      } else {
        this.onSelectRestaurant(payload.restaurant);
      }
    },

    onSubmitDeliveryAddress(payload) {
      this.orderType = ORDER_TYPES.DELIVERY;
      this.searchingForLocations = true;

      this.submitDeliveryAddress(payload).then(restaurantList => {
        this.searchingForLocations = false;

        if (restaurantList.length === 0) {
          this.showLocationList = true;
        } else if (restaurantList.length === 1) {
          this.showLocationList = false;
        } else {
          this.showLocationList = true;
        }

        if (this.showLocationList) {
          this.$refs.restaurantListModal.open();
        }
      }).catch(() => {
        this.searchingForLocations = false;
      });
    },

    onSelectedOrderType(orderType) {
      this.orderType = orderType;
    }
  }
});
