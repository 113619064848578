









































































































import Vue from 'vue';
import baseMixin from '../mixins/base-component.mixin';
import StyledModal from './StyledModal.vue';
import notifierService from '../services/notifier.service';
import modalService from '../services/modal.service';
import SelectModern from './SelectModern.vue';
import { IUser } from '../../profile/stores/profile.store';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import { IFeedbackRequest } from '../../account/types/account.types';

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    StyledModal,
    SelectModern
  },
  data: () => ({
    feedbackError: null
  }),
  props: {
    feedbackRequest: {
      type: Object as () => IFeedbackRequest,
      required: true
    },
    user: {
      type: Object as () => IUser,
      required: false
    },
    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    }
  },
  computed: {
    _notifier() {
      return notifierService;
    },
    _modalService() {
      return modalService;
    }
  },
  created() {
    this._modalService.feedbackModal$.subscribe(() => {
      this.open();
    });
  },
  methods: {
    open() {
      if (this.$refs.modal) {
        this.$refs.modal.open();
      }
    },

    close() {
      if (this.$refs.modal) {
        this.$refs.modal.close();
      }
    },

    cancel() {
      this.close();
    },
    submit() {
      if (!this.feedbackRequest.email) {
        this.feedbackError = 'Please enter an e-mail address to send feedback!';

        return;
      }

      if (!this.feedbackRequest.message) {
        this.feedbackError = 'Please enter a message to send feedback!';

        return;
      }

      if (!this.feedbackRequest.category) {
        this.feedbackError = 'Please select a category to send feedback!';

        return;
      }

      this.feedbackError = undefined;

      this.feedbackRequest.location = this.selectedRestaurant.objectId;

      if (this.user && this.user.objectId) {
        this.feedbackRequest.user = this.user.objectId;
      }

      this.$emit('submitFeedback', this.feedbackRequest);
      this._notifier.success('Thank you for your feedback, if this is in regards to a pending order please call the Restaurant directly.');
      this.close();
    },

    onCategoryChange(categoryId) {
      this.feedbackRequest.category = categoryId;
    }
  }
})
