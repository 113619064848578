































































import Vue from 'vue';
import baseMixin, { navigateToMenu } from '../../common/mixins/base-component.mixin';
import CheckoutModal from '../components/CheckoutModal.vue';
import account from '../../account/models/Account';
import order from '../models/Order';
import profile from '../../profile/models/Profile';
import routeService from '../../common/services/route.service';
import modalBus from '../../common/messaging/modal.bus';
import profileBus, { IDeleteCardRequest, IUpdateExpirationDateRequest } from '../../profile/profile.bus';
import designService from '../../common/services/design.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import cart, { CURBSIDE_OPTIONS } from '../../cart/models/Cart';
import restaurants from '../../restaurants/models/Restaurants';
import {IAddress} from '../../common/models/Address';
import {MODAL_ACTIONS} from '../../common/common.constants';
import {ITenderRequest} from '../models/TenderRequest';
import CheckoutModern from '../components/CheckoutModern.vue';
import { ICart } from '../../cart/cart.types';
import { SHOW_ALERT } from '../../common/messaging/notifications';
import notificationService from '../../common/messaging/notification.service';
import ScheduleOrderModal from '../components/ScheduleOrderModal.vue';
import analyticsManager from '../../common/services/analytics-manager.service';
import { IZoneArea, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
import CurbsideModal from '../components/CurbsideModal.vue';

declare var Raygun: any;

export default Vue.extend({
    mixins: [baseMixin],

    components: {
        CheckoutModal,
        CheckoutModern,
        CurbsideModal,
        ScheduleOrderModal
    },

    computed: {
        // models

        _modalBus () {
            return modalBus;
        },

        _profileBus () {
            return profileBus;
        },

        routeService () {
            return routeService;
        },

        account () {
            return account;
        },

        cart () {
            return cart;
        },

        cartHelperService () {
            return cartHelperService;
        },

        designService () {
            return designService;
        },

        order () {
            return order;
        },

        profile () {
            return profile;
        },

        restaurants () {
            return restaurants;
        },

        // getters

        addresses() {
            return profile.userAddresses;
        },

        cartObject() {
            return this.cart.cart;
        },

        creditCards() {
            return profile.userCreditCards;
        },

        isDelivery(): boolean {
            return this.cart.cart.orderType === ORDER_TYPES.DELIVERY;
        },

        message() {
            return order.message;
        },

        restaurant() {
            return this.restaurants.selectedRestaurant;
        },

        phones() {
            return profile.userPhones;
        },

        phoneTypes() {
            return profile.phoneTypes;
        },

        progress() {
            return order.progress;
        },

        submitting() {
            return this.order.submitting;
        },

        successful() {
            return order.successful;
        },

        suggestedAddresses() {
            return profile.suggestedAddresses;
        },

        tenderRequest() {
          return order.tenderRequest;
        },

        updating() {
            return cart.updating;
        },

        user() {
            return profile.user;
        }
    },

    data() {
        return {
            alert: {show: false, message: ''},
            error: null, // string
            initialized: false,
            intervalId: 0,
            isCurbsideOrder: false,
            orderStatusTimeout: 0,
            removeShowAlertObserver: null, // function
            resetOrder: false,
            statusCheckIntervalId: 0,
            submitted: false
        };
    },

    watch: {
      error(value) { // TODO: test
        if (value) {
          if (order.errorCode === 607) { // toppings not allowed
            this.resetOrder = true;
          }
        }
      }
    },

    mounted () {
        this.intervalId = window.setInterval(
            function () {
                cart.checkOrderTime();
            },
            60000); // Every minute

        this.initialized = true;

        this.subscribe(this._profileBus.deleteCard$, (request: IDeleteCardRequest) => {
            this.profile.removeCreditCard(request);
        });

        this.subscribe(this._profileBus.updateExpirationDate$, (request: IUpdateExpirationDateRequest) => {
            this.profile.updateExpirationDate(request);
        });

        this.removeShowAlertObserver = notificationService.addObserver(SHOW_ALERT, payload => {
          this.showAlert(payload.message, payload.notification);
        });

        const orderType = this.restaurants.selectedRestaurant.orderTypes[this.cart.cart.orderType];

        if (orderType.curbside === CURBSIDE_OPTIONS.OPTIONAL) {
            this.$refs.curbsideModal.show();
        } else if (orderType.curbside === CURBSIDE_OPTIONS.ON) {
            this.isCurbsideOrder = true;
        }
    },

    methods: {
        clearSuggestedAddresses() {
            this.profile.clearSuggestedAddresses();
        },

        navigateToMenu() {
            navigateToMenu();
        },

        onClose() {
            if (this.resetOrder) {
                this.cart.fetchBySession().then(() => {
                  this.navigateToMenu();
                });
            }
        },

        removeDeliveryZone() {
            this.cart.removeDeliveryZone();
        },

        restartOrder(modal: any) {
            analyticsManager.track('Thank you', {
                cartId: this.cartObject.objectId,
                user: this.user ? this.user.objectId : null
            });

            this.cart.readyTime = this.cartObject.expectedTimeString;

            this.cart.fetchBySession();

            modal.close();

            let data = {};

            if (this.isCurbsideOrder) {
              data['isCurbsideOrder'] = true;
            }

            this.routeService.route('Confirmation', data);
        },

        saveAddress(payload) {
            this.profile.saveAddress(payload);
        },

        saveCreditCard(payload: {
            userId: string,
            encryptedCardInfo: string,
            creditCardId: string,
            billingAddress: IAddress}) {
            this.profile.saveCreditCard(payload);
        },

        setDeliveryZone(payload: IZoneArea) {
            this.cart.setDeliveryZone(payload);
        },

        setSuggestedAddresses(payload: any[]) {
            this.profile.setSuggestedAddresses(payload);
        },

        submitOrder(payload: { cart: ICart, tenderRequest: ITenderRequest }) {
            if (!this.cartHelperService.checkOrderTime(null, false, true)) {
                return;
            }

            this.order.submit(payload).then(payload => {
                this.submitted = true;

                if (this.statusCheckIntervalId) {
                    window.clearInterval(this.statusCheckIntervalId);

                    this.statusCheckIntervalId = 0;
                }

                if (payload) {
                    this._profileBus.holdUpdatedCardInfo$.subscribe(
                        (request: IUpdateExpirationDateRequest) => {
                            if (request !== null) {
                                this.profile.updateExpirationDate(request);
                            }
                        }
                    );
                }

                if (profile.hasLoyalty) {
                  this.profile.refresh(this.restaurant.objectId);
                }
            });

            setInterval(() => {
                const message = this.cart.cartError;

                if (!message) {
                    return;
                }

                if (message.toUpperCase() === 'SUBMISSION FAILURE') {
                    this.cart.fetchBySession();

                    if (this.orderStatusTimeout) {
                        window.clearTimeout(this.orderStatusTimeout);
                    }
                }
            }, 100);

            let self: any = this;
            let checkedForFinal: boolean = false;

            // delay status check by a second to give backend time to prepare a status
            setTimeout(() => {
                if (this.submitted) {
                    return;
                }

                this.statusCheckIntervalId = window.setInterval(() => {
                    cart.checkStatus();
                    self.order.checkStatus(payload.cart.objectId);
                },
                500);

                // Set timeout to configured value to end process and let user know that there was potentially a problem
                this.orderStatusTimeout = window.setTimeout(() => {
                    if (this.statusCheckIntervalId) {
                        window.clearInterval(this.statusCheckIntervalId);
                        this.statusCheckIntervalId = 0;

                        this.showOrderStatusTimeoutModal({ cartId: payload.cart.objectId, email: payload.tenderRequest.email });
                    }
                }, (this.account.orderSubmissionTimeout * 1000));
            }, 1000);
        },

        showOrderStatusTimeoutModal(payload: { cartId: string, email: string }) {
            analyticsManager.track(
                'Order Timeout',
                {
                  cartId: payload.cartId,
                  locationId: this.restaurant.objectId,
                  locationName: this.restaurant.name
                }
            );

            this._modalBus.orderProgressModal$.next(MODAL_ACTIONS.CLOSE);
            this._modalBus.orderSubmissionTimeoutModal$.next(MODAL_ACTIONS.OPEN);

            try {
                let errStr: string = `Order Submission Timeout: CART_ID=${payload.cartId}`
                    + `, ORDER_EMAIL=${payload.email}`;

                throw new Error(errStr);
            } catch (e) {
                if (typeof Raygun !== 'undefined') {
                    Raygun.send(e, null, ['ORDER_ERROR']);
                }
            }
        },

        loadData(): boolean {
            return false;
        },

        showAlert(message: string, notification?: string) {
            this.alert.show = true;
            this.alert.message = message;
            this.alert.notification = notification;

            window.scrollTo(0, 0);
        },

        getAlertStyle(): any {
            return this._designService.getAlertStyling();
        },

        clearAlert() {
            this.alert.show = false;
            this.alert.message = '';
        },

        onCurbsideModalSubmit(result: boolean) {
            this.isCurbsideOrder = result;

            cart.setOrderType(cart.cart.orderType, result);
        }
    },
    destroyed () {
        if (this.intervalId) {
          clearInterval(this.intervalId);
        }

        if (this.removeShowAlertObserver) {
          this.removeShowAlertObserver();
        }
    }
});
