




























import Vue from 'vue';
import { ICoupon, ICouponGroup } from '../models/Coupon';
import { IMenuItem } from '../../menu/models/Item';
import designService from '../../common/services/design.service';
import CouponItemModern from './CouponItemModern.vue';
import { ICategoryDesign, IItemDesign } from '../../menu/stores/menu.store';
import itemCardListMixin from '../../menu/mixins/item-card-list.mixin';
import { IRestaurant } from '../../restaurants/types/restaurant.types';

declare var $: any;

export default Vue.extend({
  mixins: [itemCardListMixin],
  components: {
    CouponItemModern
  },
  props: {
    categoryDesign: {
      type: Object as () => ICategoryDesign,
      required: false
    },
    itemDesign: {
      type: Object as () => IItemDesign,
      required: true
    },
    itemsMap: {
      type: Object,
      required: true
    },
    itemNumber: {
      type: Number,
      required: true
    },
    selectedCoupon: {
      type: Object as () => ICoupon,
      required: true
    },
    selectedItemsMap: {
      type: Object,
      required: true
    },
    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    }
  },
  computed: {
    // models

    _designService() {
      return designService;
    },

    // getters

    group() {
      if (!this.itemsMap || !this.selectedCoupon) {
        return null;
      }

      const groups = this.selectedCoupon.groups;

      if (groups.length < this.itemNumber) {
        return null;
      }

      return groups[this.itemNumber - 1];
    },

    items() {
      if (this.itemsMap) {
        if (!this.group || !this.itemsMap[this.group.objectId]) {
          return [];
        }

        return this.itemsMap[this.group.objectId];
      } else {
        return null;
      }
    }
  },
  data() {
    return {
      initialized: false
    };
  },
  created() {
    this.initialized = true;
  },
  mounted() {
    const backdrop = $('.modal-backdrop');

    if (backdrop.length > 0) {
      backdrop.remove();
      $('body').removeClass('modal-open');
    }
  },
  methods: {
    chooseItem(payload: { item: IMenuItem, sizeId: string, group: ICouponGroup }) {
      let newPayload: any = Object.assign({}, payload, {
        itemSelectedPostTask: this.selectedRestaurant.itemSelectedPostTask
      });

      this.$emit('selectItem', newPayload);
    },

    getImageUrl(showImage: boolean, imageLink: string, defaultImageLink: string) {
      if (!showImage) {
        return '';
      }

      if (imageLink === null || imageLink === '' || !imageLink) {
        return defaultImageLink;
      }

      return imageLink;
    },

    getItemNumber() {
      if (!this.selectedItemsMap || !this.itemNumber || !this.selectedItemsMap[this.itemNumber]) {
        return;
      }

      let currentNumber: number = 1;

      for (let i = 1, len = this.itemNumber; i <= len; i++) {
        if (!this.selectedItemsMap[i].items) {
          continue;
        }

        currentNumber += this.selectedItemsMap[i].items.length;
      }

      return currentNumber;
    },

    getIncludeName(include: any) {
      if (include.styles && include.styles.length > 0) {
        if (include.styles.length > 1) {
          let sizes: any[] = [];

          for (let style of include.styles) {
            if (style.type.toLowerCase() === 'size') {
              sizes.push(style.name);
            }
          }

          return sizes.join(' or ');
        } else {
          if (include.styles[0].type.toLowerCase() === 'size') {
            return (include.styles[0].name);
          }
        }
      } else {
        return include[include.groupType.toLowerCase()].name;
      }
    },

    getItemName(group: ICouponGroup) {
      if (!group) {
        return null;
      }

      if (group.includes && group.includes.length > 1) {
        let includeNames: string[] = [];

        for (let include of group.includes) {
          if (!include || !this.getIncludeName(include)
            || this.getIncludeName(include).indexOf('null') >= 0) {
            continue;
          }

          includeNames.push(this.getIncludeName(include));
        }

        return includeNames.join(' or ');
      } else if (group.includes && group.includes.length === 1) {
        return this.getIncludeName(group.includes[0]);
      }
    },

    getHeader(): any {
      if (!this.categoryDesign || !this.categoryDesign.header) {
        return {};
      }

      return this.categoryDesign.header;
    }
  }
});
