






























































































































































































































import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import restaurantListMixin from '../../restaurants/mixins/restaurant-list.mixin';
import RestaurantCardModern from '../../restaurants/components/RestaurantCardModern.vue';
import RestaurantListModal from '../../restaurants/components/RestaurantListModal.vue';
import designService from '../../common/services/design.service';
import { IRegisterRequest } from '../stores/profile.store';
import PhoneInput from '../../common/components/PhoneInput.vue';
import PasswordFormModern from './PasswordFormModern.vue';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import routeService from '../../common/services/route.service';
import environmentService from '../../common/services/environment.service';
import restaurants from '../../restaurants/models/Restaurants';
import FindLocationModern from '../../restaurants/components/FindLocationModern.vue';

const moment = require('moment');

export default Vue.extend({
  mixins: [baseMixin, restaurantListMixin],
  components: {
    PasswordFormModern,
    PhoneInput,
    RestaurantCardModern,
    RestaurantListModal,
    FindLocationModern
  },
  props: {
    error: String,
    registerRequest: {
      type: Object as () => IRegisterRequest,
      required: true
    }
  },
  computed: {
    // services

    _designService() {
      return designService;
    },

    // getters

    formattedError() {
      if (this.isDuplicatePhoneError) {
        return 'This phone number is already enrolled in ' + this.loyaltySettings.programName + ', please login.';
      } else {
        return this.error;
      }
    },

    isDuplicatePhoneError() {
      return this.error === 'This number is already associated with a loyalty customer.';
    },

    isEmailValid() {
      return this.isValidEmailAddress(this.registerRequest.username);
    },

    isFirstNameValid() {
      return this.registerRequest.firstName
        && this.registerRequest.firstName.length > 0 && this.registerRequest.firstName.length <= 255;
    },

    isLastNameValid() {
      return this.registerRequest.lastName
        && this.registerRequest.lastName.length > 0 && this.registerRequest.lastName.length <= 255;
    },

    isRestaurantValid() {
      return !!this.selectedRestaurant || !!this.restaurant;
    },

    isPhoneValid() {
      const digits = this.phoneDigits;

      return digits && digits.length === 10;
    },

    isValid() {
      return this.isFirstNameValid && this.isLastNameValid && this.isEmailValid && this.isPhoneValid
        && this.$refs.passwordForm.valid && this.isRestaurantValid;
    },

    loyaltyProgramDescription() {
      return this._designService.loyaltyProgramDescription;
    },

    phoneDigits() {
      if (!this.registerRequest.phoneNumber) {
        return null;
      }

      return this.registerRequest.phoneNumber.replace(/[^0-9]/g, '');
    },

    location() {
      if (this.userLocation === false) {
        return null;
      }

      return this.userLocation;
    },

    loyaltySettings() {
      let result = this.restaurants.loyalty;

      if (!result) {
        result = this.restaurants.singleLoyalty;
      }

      return result;
    },

    restaurant() {
      return this.restaurants.selectedRestaurant;
    }
  },
  data() {
    return {
      distanceFromUser: null, // number
      errorMessage: null, // string
      isDateOfBirthValid: false,
      isEmailChanged: false,
      isPhoneChanged: false,
      passwordError: null, // string
      postalCode: null, // string
      postalCodeChanged: false,
      searchingForLocations: false,
      submitted: false
    };
  },
  created() {
    if (this.loyaltySettings) {
      if (this.loyaltySettings.externalProgram) {
        this.registerRequest.rewards = true;
      }
    }
  },
  methods: {
    create(): void {
      this.errorMessage = this.passwordError;

      if (this.errorMessage) {
        window.scrollTo(0, 0);

        return;
      }

      if (!this.isValid) {
        this.submitted = true;

        return;
      }

      this.registerRequest.phoneNumber = this.phoneDigits;

      if (!this.loyaltySettings || !this.loyaltySettings.externalProgram) {
        this.registerRequest.rewards = false;
      }

      this.$emit('register', this.registerRequest);
    },

    isError(isValid: boolean, isChanged?: boolean): boolean {
      return this.isChanged(isChanged) && !isValid;
    },

    isControlError(isChanged: boolean, isValid: boolean): boolean {
      return this.isChanged(isChanged) && !isValid;
    },

    isChanged(isChanged: boolean): boolean {
      return isChanged || this.submitted === true;
    },

    isValidEmailAddress(email) {
      return email && email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i);
    },

    onDateOfBirthIsValidChanged(value) {
      this.isDateOfBirthValid = value;
    },

    chooseLocation(origin: string) {
      this.searchingForLocations = true;

      this.findLocationsNearMe(origin).then(() => {
        this.searchingForLocations = false;

        this.$refs.restaurantListModal.open();
      }).catch(() => {
        this.searchingForLocations = false;
      });
    },

    clearRestaurant() {
      this.restaurants.changeRestaurant();
      this.restaurants.clearLoyalty();
      this.registerRequest.location = null;
      this.registerRequest.rewards = false;
    },

    onSelectClosestRestaurant() {
      this.calculateClosestRestaurant().then(restaurant => {
        if (restaurant) {
          this.onSelectRestaurant({restaurant});
        }
      });
    },

    onSelectRestaurant(payload: { restaurant: IRestaurant, distance?: string }) {
      this.chooseRestaurant(payload, false);
      this.registerRequest.location = payload.restaurant.objectId;
      this.distanceFromUser = payload.distance;

      this.restaurants.loadLoyalty(payload.restaurant.objectId);
    },

    goBack() {
      if (environmentService.directRoute) {
        if (restaurants.selectedRestaurant) {
          this.navigateToMenu();
        } else {
          routeService.route('RestaurantsList');
        }
      } else {
        window.history.back();
      }
    },

    onPasswordError(error) {
      this.passwordError = error;
    },

    disableLoyalty() {
      this.registerRequest.rewards = false;
      this.registerRequest.dateOfBirth = null;

      this.clearError();
    },

    clearError() {
      this.$emit('clearError');
    }
  }
});
