

















import Vue from 'vue';
import baseMixin from '../mixins/base-component.mixin';
import StyledModal from './StyledModal.vue';

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    StyledModal
  },
  methods: {
    open() {
      if (this.$refs.modal) {
        this.$refs.modal.open();
      }
    },

    close() {
      if (this.$refs.modal) {
        this.$refs.modal.close();
      }
    }
  }
})
