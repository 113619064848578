import menu from '../../menu/models/Menu';
import { IMenuItem, IRequiredItem, ISplitItemState, ITopping } from '../../menu/models/Item';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import environment from '../../common/services/environment.service';
import storageService from '../../common/services/storage.service';
import { Util } from '../../common/services/Util';
import analyticsManager from '../../common/services/analytics-manager.service';
import { CART_STORE } from '../stores/cart.store';
import { ICart, IUnavailableMessage } from '../cart.types';
import store from '../../../store';
import { VuexModel } from '../../common/data/VuexModel';
import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
import menuBus from '../../menu/menu.bus';
import { IUser } from '../../profile/stores/profile.store';
import modalService from '../../common/services/modal.service';
import restaurants from '../../restaurants/models/Restaurants';
import { IRestaurant, IZoneArea } from '../../restaurants/types/restaurant.types';
import {
  getFulfilledAutoApplyCoupons,
  getNonSizeStyle,
  getValidationMessage,
  isModifierInStock,
  reorder,
  unableToPriceItem
} from '../helpers/cart.helpers';
import loyaltyService from '../../coupons/services/loyalty.service';

export const CURBSIDE_OPTIONS = {
  OFF: 'OFF',
  ON: 'ON',
  OPTIONAL: 'OPTIONAL'
};

export class Cart extends VuexModel {
  showSuggestion: boolean = false;
  showAutoApplyMessages: boolean = false;

  // getters

  get cart(): ICart {
    return this.state.cart;
  }

  get cartError() {
    return this.state.cartError;
  }

  get giftCardBalance() {
    return this.state.giftCardBalance;
  }

  get itemMap() {
    return this.state.itemMap;
  }

  get readyTime(): string {
    return store.state.cart.readyTime;
  }

  set readyTime(value: string) {
    store.commit('cart/SET_READY_TIME', value);
  }

  get selectedItem() {
    return store.getters[this.storeName + '/selectedItem'];
  }

  get storeName() {
    return CART_STORE;
  }

  get updating() {
    return this.state.updating;
  }

  get selectedItemMaxToppingsData() {
    return store.getters[this.storeName + '/selectedItemMaxToppingsData'];
  }

  // methods

  validateAddCouponCodeResponse(cart: ICart, handleError: boolean = true) {
    if (cart && cart.selectedItem) {
      let error;
      let coupon;

      for (let validation of cart.validations) {
        coupon = cart.coupons.find(c => c.objectId === validation.objectId);

        if (validation.objectId === cart.selectedItem) {
          error = validation;

          break;
        }
      }

      if (error) {
        if (handleError) {
          if (coupon) {
            modalService.selectCouponModal(coupon);
          } else {
            notifierService.error(getValidationMessage(error));
          }

          this.removeItem(cart.selectedItem);
        }
      }

      return false;
    }

    return true;
  }

  addCoupon(couponId: string, promoId?: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      couponId,
      promo: promoId || ''
    };

    return this.dispatch('addCoupon', payload).then(result => {
      couponWalkthrough.selectCoupon(result);

      return result;
    });
  }

  addCouponCode(couponCode: string) {
    if (!couponCode) {
      return;
    }

    let payload: any = {
      cartId: this.cart.objectId,
      couponCode
    };

    return this.dispatch('addCouponCode', payload).then(cart => {
      if (this.validateAddCouponCodeResponse(cart, false)) {
        notifierService.success('Coupon added successfully!');
      }

      return cart;
    });
  }

  fetchGiftCardBalance(giftCardCode: string, giftCardCvv: string) {
    if (!giftCardCode) {
      return;
    }

    const payload = {
      giftCardCode,
      locationId: this.cart.locationId
    };

    if (restaurants.selectedRestaurant.giftCvvRequired) {
      payload['giftCardCvv'] = giftCardCvv;
    }

    return this.dispatch('fetchGiftCardBalance', payload);
  }

  removeCoupons(cart?: ICart): void {
    if (!cart) {
      cart = this.cart;
    }

    let couponIds = cart.coupons.map(coupon => coupon.objectId);

    this.removeItems(couponIds, cart.objectId);
  }

  addCommentsToItem(itemId: string, comments: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId,
      comments
    };

    return this.dispatch('addCommentsToItem', payload);
  }

  addItem(data: { item: IMenuItem, sizeId?: string, showSuggestion?: boolean, showAutoApplyMessages?: boolean, quantity?: number }) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId: data.item.itemId,
      sizeId: data.sizeId,
      showSuggestion: data.showSuggestion || false,
      showAutoApplyMessages: data.showAutoApplyMessages || false,
      quantity: data.quantity
    };

    this.showSuggestion = payload.showSuggestion;
    this.showAutoApplyMessages = payload.showAutoApplyMessages;

    const itemsBefore = this.cart.items.length;

    const result = this.dispatch('addItem', payload).then(cart => {
      const promises = [];

      let item: IMenuItem = cart.items.find((cartItem) => {
        return cartItem.itemId === payload.itemId;
      });

      if (!item || cart.items.length <= itemsBefore) {
        throw 'Unable to add item to cart.';
      } else if (unableToPriceItem(cart)) {
        throw 'Unable to add item to cart.';
      } else {
        if (routeService.currentRoute.name !== 'CustomizeItem') {
          notifierService.success(item.name + ' Added!', undefined, false, 5000);
        }

        if (this.showSuggestion) {
          promises.push(menu.loadSuggestions(cart.objectId, item.itemId));
        }
      }

      const style = getNonSizeStyle(item);

      if (this.showAutoApplyMessages) {
        this.showAutoApplyCouponMessages(item);
      }

      promises.push(menu.loadToppingsList(
        item.itemId,
        item.size ? item.size.styleId : null,
        style ? style.styleId : null
      ));

      promises.push(menu.loadStyleList(item.itemId));

      return Promise.all(promises).then(() => cart);
    }).catch(error => {
      notifierService.error(error);
    });

    analyticsManager.track(
      'Add Item',
      {
        cartId: this.cart.objectId,
        // couponAutoApplied: data.item.couponAutoApplied,
        // customized: data.item.customized,
        itemId: data.item.itemId,
        itemName: data.item.name,
        locationId: this.cart.locationId,
        locationName: restaurants.selectedRestaurant.name,
        quantity: data.quantity
      }
    );

    return result;
  }

  addRequiredItem(requirementId: string, itemId: string, parentId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      requirementId,
      itemId,
      parentId
    };

    return this.dispatch('addRequiredItem', payload);
  }

  clear(user: IUser) {
    let restaurantId: string = JSON.parse(storageService.localStorage.getItem('selectedRestaurant'));

    if (!restaurantId) {
      return false;
    }

    let payload: any = {
      locationId: restaurantId,
      notificationId: null,
      userId: user ? user.objectId : null
    };

    if (environment.notificationId) {
      environment.clearNotificationId();
    }

    loyaltyService.linkOffer = null;
    loyaltyService.isLinkOfferFulfilled = false;

    return this.dispatch('clear', payload);
  }

  fetchBySession() {
    return this.dispatch('fetchBySession', this.cart ? this.cart.objectId : null);
  }

  removeItem(itemId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId
    };

    if (loyaltyService.linkOffer && loyaltyService.linkOffer.itemId === itemId) {
      loyaltyService.linkOffer = null;
      loyaltyService.isLinkOfferFulfilled = false;
    }

    return this.dispatch('removeItem', payload);
  }

  removeItems(itemIds: string[], cartId?: string) {
    if (!cartId) {
      cartId = this.cart.objectId;
    }

    let payload: any = {
      cartId,
      itemIds
    };

    if (loyaltyService.linkOffer) {
      const found = itemIds.find(id => loyaltyService.linkOffer.itemId === id);

      if (found) {
        loyaltyService.linkOffer = null;
        loyaltyService.isLinkOfferFulfilled = false;
      }
    }

    return this.dispatch('removeItems', payload);
  }

  removeItemsThenReview(itemIds: string[]) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemIds
    };

    if (loyaltyService.linkOffer) {
      const found = itemIds.find(id => loyaltyService.linkOffer.itemId === id);

      if (found) {
        loyaltyService.linkOffer = null;
        loyaltyService.isLinkOfferFulfilled = false;
      }
    }

    return this.dispatch('removeItems', payload).then(response => {
      window.setTimeout(() => {
          routeService.route('OrderReview').catch(() => {
            return;
          });
        },
        0); // wait a fraction of a second for the operation to complete.)

      return response;
    });
  }

  removeItemsThenCheckout(itemIds: string[]) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemIds
    };

    if (loyaltyService.linkOffer) {
      const found = itemIds.find(id => loyaltyService.linkOffer.itemId === id);

      if (found) {
        loyaltyService.linkOffer = null;
        loyaltyService.isLinkOfferFulfilled = false;
      }
    }

    return this.dispatch('removeItems', payload).then(response => {
      window.setTimeout(() => {
          routeService.route('Checkout').catch(() => {
            return;
          });
        },
        0); // wait a fraction of a second for the operation to complete.)

      return response;
    });
  }

  setQuantity(itemId: string, quantity: number) {
    if (typeof quantity === 'string') {
      quantity = parseInt(quantity);
    }

    if (typeof quantity !== 'number' || isNaN(quantity)) {
      quantity = 0;
    }

    let payload: any = {
      cartId: this.cart.objectId,
      itemId,
      quantity
    };

    return this.dispatch('setQuantity', payload);
  }

  setOrderType(orderTypeId: string, curbside: boolean = false, restaurant?: IRestaurant) {
    if (!restaurant) {
      restaurant = restaurants.selectedRestaurant;
    }

    if (!this.cart || !restaurant) {
      return Promise.reject();
    }

    if (restaurant.orderTypes[orderTypeId].curbside === CURBSIDE_OPTIONS.ON) {
      curbside = true;
    }

    /* analyticsManager.track('Order Type changes', {
      curbside,
      endingOrderType: orderTypeId,
      locationId: restaurant.objectId,
      locationName: restaurant.name,
      startingOrderType: this.cart.orderType
    });*/

    let payload: any = {
      curbside,
      orderTypeId
    };

    return this.dispatch('setOrderType', payload);
  }

  setOrderTime(date: string, time: string) {
    let payload: any = {
      date,
      time
    };

    return this.dispatch('setOrderTime', payload);
  }

  setTenderType(tenderTypeId: string): Promise<ICart> {
    const payload = {
      cartId: this.cart.objectId,
      tenderTypeId
    };

    return this.dispatch('setTenderType', payload);
  }

  checkOrderTime() {
    return this.dispatch('checkOrderTime');
  }

  scheduleAsap() {
    return this.dispatch('scheduleAsap');
  }

  updateSize(itemId: string, sizeId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId,
      sizeId
    };

    return this.dispatch('updateSize', payload);
  }

  updateStyle(itemId: string, styleId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId,
      styleId
    };

    if (!isModifierInStock(this.cart, payload.itemId, payload.styleId)) {
      return Promise.reject('Style out of stock');
    }

    return this.dispatch('updateStyle', payload);
  }

  updateToppings(itemId: string, toppings: ITopping[]) {
    let payload: any = {
      cartId: this.cart.objectId,
      itemId,
      toppings
    };

    return this.dispatch('updateToppings', payload);
  }

  addNoRequiredItem(requirementId: string, parentId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      requirement: requirementId,
      parent: parentId
    };

    return this.dispatch('addNoRequiredItem', payload);
  }

  addUser(userId: string) {
    let payload: any = {
      cartId: this.cart.objectId,
      userId
    };

    return this.dispatch('addUser', payload);
  }

  setOrderName(orderName: string) {
    let payload: any = {
      orderName
    };

    return this.dispatch('setOrderName', payload);
  }

  reorder(cartId: string): Promise<ICart> {
    return reorder(
      this.cart,
      cartId,
      (id: string) => this.dispatch('reorder', id),
      () => routeService.currentRoute.name === 'MainMenu',
      (itemIds: string[]) => this.removeItems(itemIds),
      (errors: string[]) => modalService.showErrorModal(errors),
      (orderTypeId: string) => this.setOrderType(orderTypeId),
      (day: string, time: string) => this.setOrderTime(day, time),
      () => this.scheduleAsap(),
      (zoneId: string) => this.setDeliveryZone(zoneId),
      (cart: ICart) => this.removeCoupons(cart),
      (/* cart: ICart */) => {
        /* analyticsManager.track('Repeat Order used', {
          locationId: restaurants.selectedRestaurant.objectId,
          locationName: restaurants.selectedRestaurant.name,
          orderTotal: cart.grandTotal
        });*/
      },
      (unavailableMessages: IUnavailableMessage[]) => {
        modalService.openItemUnavailableModal(unavailableMessages);
      },
      menu.categories
    );
  }

  checkStatus() {
    if (!this.cart || !this.cart.objectId) {
      return;
    }

    return this.dispatch('checkStatus');
  }

  setDeliveryZone(zone: IZoneArea | string) {
    return Util.waitUntil(() => this.cart).then(() => {
      return this.dispatch('setDeliveryZone',  { cartId: this.cart.objectId, zone });
    });
  }

  removeDeliveryZone() {
    return this.dispatch('removeDeliveryZone', this.cart.objectId);
  }

  splitItem(payload: ISplitItemState) {
    if (payload.item.alreadySplit) {
      this.unsplitItem(payload.item.objectId).then(item => {
        this.splitItem({ item, splitItemId: payload.splitItemId }).catch(() => {
          return;
        });
      });

      return;
    }

    let data: any = {
      cartId: this.cart.objectId,
      itemObjectId: payload.item.objectId,
      itemId: payload.splitItemId
    };

    return this.dispatch('splitItem', data).then(cart => {
      menuBus.toggleDisplayItem$.next(this.selectedItem.split.left);

      const item = cart.items.find(i => {
        return i.objectId === payload.item.objectId;
      });

      if (item && item.alreadySplit) {
        setTimeout(() => {
          menu.loadSplitToppingsList(payload.splitItemId);

          /* analyticsManager.track('Make it a Half and Half', {
            itemId: item.itemId,
            itemName: item.name,
            locationId: restaurants.selectedRestaurant.objectId,
            locationName: restaurants.selectedRestaurant.name
          });*/
        }, 50);
      }

      return item;
      // tslint:disable-next-line:no-empty
    }).catch(() => {
    });
  }

  unsplitItem(itemObjectId: string): Promise<IMenuItem> {
    let payload: any = {
      cartId: this.cart.objectId,
      itemObjectId
    };

    return this.dispatch('unsplitItem', payload).then(cart => {
      return cart.items.find(i => {
        return i.objectId === itemObjectId;
      });
      // tslint:disable-next-line:no-empty
    }).catch(() => {
    });
  }

  removeAllRequiredItemInstances(payload: { requiredItem: IRequiredItem, parentInstanceId: string }) {
    const newPayload = Object.assign({}, payload, {
      cartId: this.cart.objectId
    });

    return this.dispatch('removeAllRequiredItemInstances', newPayload)
      // tslint:disable-next-line:no-empty
    .catch(() => {
    });
  }

  updateRequirements(itemInstanceId: string, requirements: IRequiredItem[]) {
    return this.dispatch('updateRequirements', Object.assign({}, {
      cartId: this.cart.objectId,
      itemInstanceId,
      requirements
      // tslint:disable-next-line:no-empty
    })).catch(() => {
    });
  }

  selectItem(item: IMenuItem) {
    return this.dispatch('selectItem', item);
  }

  showAutoApplyCouponMessages(item: IMenuItem) {
    const fulfilledAutoApplyCoupons = getFulfilledAutoApplyCoupons(this.cart, item);

    if (fulfilledAutoApplyCoupons.length === 0) {
      return;
    }

    let msg = [];

    for (let i = 0; i < fulfilledAutoApplyCoupons.length; i++) {
      msg.push(fulfilledAutoApplyCoupons[i].name + ' will be added to your order.');
    }

    modalService.showErrorModal(msg);
  }

  deleteCoupons(couponObjectIds: string[], cart?: ICart): void {
    if (!cart) {
      cart = this.cart;
    }
    const items: string [] = [];
    for (const couponObjectId of couponObjectIds) {
      const rs = cart.coupons
        .filter((coupon) => coupon.objectId === couponObjectId)
        .map(coupon => coupon.objectId);
      if (rs.length) {
        items.push(rs.pop());
      }
    }
    this.removeItems(items, cart.objectId);
  }

  setMaxToppingsData(data: Record<string, { inclusionsCount: number, allowedToppings: number }>) {
    return this.dispatch('setMaxToppingsData', data);
  }
}

export default new Cart();
