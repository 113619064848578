








































import Vue from 'vue';
import subscriberMixin from './common/mixins/subscriber.mixin';
import App from './App.vue';
import account from './account/models/Account';
import cartModel from './cart/models/Cart';
import order from './order/models/Order';
import profile from './profile/models/Profile';
import restaurants from './restaurants/models/Restaurants';
import util from './common/services/Util';
import storageService from './common/services/storage.service';
import environmentService from './common/services/environment.service';
import restaurantLoader from './restaurants/services/restaurant-loader.service';
import maps from './maps/models/Maps';
import { navigateToInnerLandingPage, navigateToMenu } from './common/mixins/base-component.mixin';
import routeService from './common/services/route.service';
import { initializeSiteLoad } from './account/helpers/account.helpers';
import { IAccountInfo, IFeedbackRequest } from './account/types/account.types';
import loyaltyService from "./coupons/services/loyalty.service";

declare var UAParser: any;
declare var VENDOR_PREFIX: string;
declare var dataLayer: any;
declare var $: any;

export default Vue.extend({
  mixins: [subscriberMixin],
  components: {
    App
  },
  computed: {
    // models

    util() {
      return util;
    },

    modernThemeColorsCSS() {
      return this.$store.getters['modernTheme/colorsCssVariables'];
    },

    // getters

    accountDesign() {
      return account.design;
    },

    accountInfo() {
      return account.accountInfo;
    },

    cartObject() {
      return cartModel.cart;
    },

    cart() {
      return cartModel;
    },

    feedbackRequest() {
      return account.feedbackRequest;
    },

    inactiveLocations() {
      return restaurants.inactiveLocations;
    },

    loyalty() {
      return restaurants.loyalty;
    },

    restaurantCount() {
      return restaurants.count;
    },

    selectedRestaurant() {
      return restaurants.selectedRestaurant;
    },

    submitting() {
      return order.submitting;
    },

    successful() {
      return order.successful;
    },

    user() {
      return profile.user;
    }
  },
  data() {
    return {
      appPassword: null, // string;
      error: false,
      hasLoadedUAParser: false,
      initialized: false,
      password: null, // string;
      previewMode: false,
      submittingCart: null, // ICart
      submittingTenderRequest: null, // ITenderRequest
      token: null
    };
  },
  watch: {
    submitting(submitting) {
      if (submitting) {
        this.submittingCart = cartModel.cart;
        this.submittingTenderRequest = order.tenderRequest;
      }
    },
    successful(success) {
      if (success) {
        order.trackOrder({ cart: this.submittingCart, tenderRequest: this.submittingTenderRequest });
      }
    }
  },
  async created() {
    const response: { account: IAccountInfo } =  await initializeSiteLoad(
      window.location.href,
      storageService.localStorage,
      storageService.sessionStorage,
      token => {
        this.token = token;

        profile.setResetToken(token);
      },
      title => {
        document.title = title;
      },
      error => {
        this.error = true;

        $('body').css('display', 'block');

        routeService.route('ErrorDetails', { details: error });
      },
      password => {
        this.appPassword = password;
      },
      () => {
        return restaurants.selectedRestaurantId;
      },
      id => {
        restaurants.selectedRestaurantId = id;
      },
      previewMode => {
        this.previewMode = previewMode;
      },
      () => {
        this.$store.commit('modernTheme/SET_CUSTOMIZE_THEME', true);
      },
      (colors: string) => {
        try {
          this.$store.dispatch('modernTheme/initColorsFromJSON', colors);
        } catch (e) {
          console.error(e);
        }
      },
      route => {
        environmentService.directRoute = route;
      },
      id => {
        environmentService.orderId = id;
      },
      force => {
        environmentService.forceLocationFinder = force;
      },
      id => {
        environmentService.offerId = id;
      },
      (accountId: string, previewMode: boolean) => account.initialize(accountId, previewMode),
      () => {
        return this.$store.state.restaurants.entities.list;
      },
      () => {
        return this.inactiveLocations;
      },
      restaurant => {
        return profile.getUserDetails().then(user => {
          if (profile.isAuthenticated && user) {
            const useFavoriteLocation = !loyaltyService.linkOffer && !environmentService.offerId;

            return profile.loadLoggedInUser(user, useFavoriteLocation);
          } else {
            return restaurantLoader.selectRestaurant(restaurant);
          }
        });
      },
      url => {
        environmentService.open(url);
      },
      token => {
        routeService.route('PasswordReset', { token });
      },
      null,
      () => {
        maps.loadUserLocation().then(loc => {
          maps.calculateClosestRestaurant(loc, this.$store.state.restaurants.entities.list).then(() => {
            routeService.route('Landing');
          }).catch(() => {
            return;
          });
        }).catch(() => {
          routeService.route('RestaurantsList');
        });
      },
      () => {
        navigateToMenu();
      },
      route => {
        routeService.route(route);
      },
      () => {
        routeService.route('RestaurantsList');
      },
      (css: string) => {
        const style = $('<style>');
        style.text(css);
        $('head').append(style);
      },
      (logoUrl: string) => {
        const style = $('<style>');
        style.text("img:before { background-image: url('" + logoUrl + "');");
        $('head').append(style);
      },
      navigateToInnerLandingPage
    );

    if (response) {
      // hide page until loaded?
      setTimeout(() => {
        $('body').css('display', 'block');
      });

      profile.initialize(response.account.objectId);
      order.initialize();

      this.setUpGoogleTagManager(response.account.tagContainerId);
    }

    this.initialized = true;
  },
  methods: {
    submitPassword(): void {
      if (this.password === this.appPassword) {
        const url = window.location.href;
        window.location.href = url.substr(0, url.indexOf('?'));
      }
    },

    submitFeedback(feedbackRequest: IFeedbackRequest) {
      if (!this.hasLoadedUAParser) {
        $.getScript(VENDOR_PREFIX + 'vendor/ua-parser.min.js', () => {
          this.hasLoadedUAParser = true;

          this.submitFeedback(feedbackRequest);
        });

        return;
      }

      let parser = new UAParser();
      let browser = parser.getBrowser();
      let os = parser.getOS();
      let device = parser.getDevice();

      feedbackRequest.browserInfo = browser.name + '/' + browser.version + ' :: ' + os.name + '/' + os.version + ' :: ' +
        (device && device.vendor ? device.vendor + '/' + device.model : 'unknown device');

      account.submitFeedback(feedbackRequest);
    },

    // Private functions

    setUpGoogleTagManager(containerId: string): void {
      if (!containerId) {
        return;
      }

      let script: any = '<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({\'gtm.start\':\n' +
        'new Date().getTime(),event:\'gtm.js\'});var f=d.getElementsByTagName("head")[0],\n' +
        'j=d.createElement(s),dl=l!=\'dataLayer\'?\'&l=\'+l:\'\';j.async=true;j.src=\n' +
        '\'https://www.googletagmanager.com/gtm.js?id=\'+i+dl;f.appendChild(j);\n' +
        '})(window,document,\'script\',\'dataLayer\',\'';

      script += containerId;

      // fixes IDE formatting
      script += "');<" + '/script>';

      script = $(script);

      $('head').append(script);

      const src = 'https://www.googletagmanager.com/ns.html?id=' + containerId;
      const iframe = $('<iframe src="' + src + '" height="0" title="Google Tag Manager" width="0" style="display:none;visibility:hidden"></iframe>');
      const noscript = $('<noscript>').append(iframe);

      $('body').append(noscript);
    }
  }
});
