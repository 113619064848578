











import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import RewardsEnrollment from '../components/RewardsEnrollment.vue';
import restaurants from '../../restaurants/models/Restaurants';
import notifierService from '../../common/services/notifier.service';

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    RewardsEnrollment
  },
  computed: {
    // services

    _notifier() {
      return notifierService;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    loyaltyProgramDescription(): string {
      return this._designService.loyaltyProgramDescription;
    },

    user() {
      return this.profile.user;
    }
  },
  data() {
    return {
      errorMessage: null, // string
      loyalty: null, // ILoyalty
      profileRequest: null, // IRegisterRequest
      selectedRestaurant: null, // IRestaurant
      submittedProfileChange: false
    };
  },
  created() {
    if (this.profile.user.rewards) {
      this.routeService.route('RewardsList');

      return;
    }

    this.loyalty = this.restaurants.loyalty;

    if (!this.loyalty) {
      this.routeService.route('RestaurantsList');
    }

    this.selectedRestaurant = this.restaurants.selectedRestaurant;

    this.profileRequest = this.profile.registerRequest;
  },
  methods: {
    joinLoyalty(dateOfBirth: string) {
      if (this.submittedProfileChange) {
        return;
      }

      this.submittedProfileChange = true;

      let payload: any = {
        userId: this.profile.user.objectId,
        account: this.profileRequest.account,
        location: this.selectedRestaurant.objectId,
        username: this.profile.user.email,
        firstName: this.profile.user.firstName,
        lastName: this.profile.user.lastName,
        rewards: true,
        dateOfBirth: dateOfBirth
      };

      this.profile.updateProfile(payload).then(() => {
        const success = this.profile.profileSuccess;

        if (success) {
          this._notifier.success('Thanks for Joining! Check the "My Rewards" menu for your points and offers');

          this.navigateToMenu();
        } else {
          this.submittedProfileChange = false;

          this._notifier.error('Update failed!');
        }
      });
    }
  }
});
