

















































































































import Vue from 'vue';
import baseItemMixin from '../../common/mixins/base-item-component.mixin';
import couponWalkthrough from '../../coupons/models/CouponWalkthrough';
import cart from '../../cart/models/Cart';
import menu from '../../menu/models/Menu';
import designService from '../../common/services/design.service';
import modalService from '../../common/services/modal.service';
import routeService from '../../common/services/route.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import restaurants from '../../restaurants/models/Restaurants';
import profile from '../../profile/models/Profile';
import order from '../models/Order';
import {IMenuItem} from '../../menu/models/Item';
import CheckoutModal from '../components/CheckoutModal.vue';
import OrderReview from '../components/OrderReview.vue';
import {ITenderRequest} from '../models/TenderRequest';
import notificationService from '../../common/messaging/notification.service';
import { SHOW_ALERT } from '../../common/messaging/notifications';
import { IZoneArea, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
import { getValidationMessage } from '../../cart/helpers/cart.helpers';
import AlertModern from '../../common/components/AlertModern.vue';
import ModalModern from '../../common/components/ModalModern.vue';
import ErrorModal from "../../common/components/ErrorModal.vue";
import { VALIDATIONS } from '../../menu/menu.constants';

export default Vue.extend({
  mixins: [baseItemMixin],
  components: {
    AlertModern,
    CheckoutModal,
    ModalModern,
    OrderReview,
    ErrorModal
  },
  computed: {
    // services

    _designService() {
      return designService;
    },

    _modalService() {
      return modalService;
    },

    routeService() {
      return routeService;
    },

    cart() {
      return cart;
    },

    cartHelperService() {
      return cartHelperService;
    },

    couponWalkthrough() {
      return couponWalkthrough;
    },

    menu() {
      return menu;
    },

    order() {
      return order;
    },

    profile() {
      return profile;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    addresses() {
      return this.profile.userAddresses;
    },

    cartError() {
      return this.cart.cartError;
    },

    cartObject() {
      return cart.cart;
    },

    cartValidations() {
      return cart.cart.validations;
    },

    currentTime() {
      return this.restaurants.currentTime;
    },

    isDelivery(): boolean {
      return this.cart.cart.orderType === ORDER_TYPES.DELIVERY;
    },

    restaurant() {
      return this.restaurants.selectedRestaurant;
    },

    restaurantCount() {
      return this.restaurants.count;
    },

    suggestedAddresses() {
      return profile.suggestedAddresses;
    },

    tenderRequest() {
      return this.order.tenderRequest;
    },

    updating() {
      return this.cart.updating;
    },

    user() {
      return this.profile.user;
    }
  },
  data() {
    return {
      _debouncer: 0,
      alert: {show: false, message: ''},
      changeRestaurantSubscription: null, // ISubscription,
      initialized: false,
      intervalId: 0,
      isAddingCoupon: false,
      modalSubscription: null, // ISubscription,
      removeShowAlertObserver: null, // function
      couponObjectIds: []
    };
  },
  created() {
    this.removeShowAlertObserver = notificationService.addObserver(SHOW_ALERT, payload => {
      this.showAlert(payload.message, payload.notification);
    });
  },
  mounted() {
    this.intervalId = window.setInterval(function () {
      cart.checkOrderTime();
    }, 60000); // Every minute

    this.initialized = true;
    window.scrollTo(0, 0);
  },
  beforeDestroy() {
    clearInterval(this.intervalId);
  },
  methods: {
    addCouponCode(code: string) {
      this.isAddingCoupon = true;
      this.couponObjectIds = [];
      this.cart.addCouponCode(code).then(cart => {
        cart.validations.forEach(validation => {
          if (validation.type === VALIDATIONS.COUPON) {
            this.couponObjectIds.push(validation.objectId);
            this.$refs.errorModal.open([getValidationMessage(validation)]);
          }
        });

        this.isAddingCoupon = false;
      }).catch(() => {
        this.isAddingCoupon = false;
      });
    },

    removeCoupon() {
      this.cart.deleteCoupons(this.couponObjectIds);
    },

    addTip(tenderRequest: ITenderRequest) {
      this.order.update(tenderRequest);
    },

    openChangeRestaurantModal() {
      this._modalService.changeRestaurantModal();
      let self = this;

      this.modalSubscription = this._modalService.closeChangeRestaurantModal$.subscribe(() => {
        self.endSubscriptions();
      });

      this.changeRestaurantSubscription = this._modalService.changeRestaurant$.subscribe(() => {
        self.changeRestaurant();
      });
    },

    changeRestaurant() {
      this.endSubscriptions();
      this.restaurants.changeRestaurant();
      this.routeService.route('RestaurantsList');
    },

    endSubscriptions() {
      if (this.modalSubscription) {
        this.modalSubscription.unsubscribe();
      }

      if (this.changeRestaurantSubscription) {
        this.changeRestaurantSubscription.unsubscribe();
      }
    },

    chooseType(payload: any) {
      this.cartHelperService.setOrderType(payload.orderTypeId, () => {
        this.cartHelperService.openDeliveryModal();
      });
    },

    editThisItem(item: any) {
      this.editItem(item);

      this.routeService.setReturnRoute('OrderReview');
    },

    removeItem(itemId: string) {
      this.cart.removeItem(itemId).then(cartData => {
        if (cartData.items && cartData.items.length === 0) {
          this.navigateToMenu();
        }
      });
    },

    removeItems(itemIds: string[]): void {
      this.cart.removeItems(itemIds);
    },

    removeItemsThenCheckout(itemIds: string[]) {
      this.cart.removeItemsThenCheckout(itemIds);
    },

    saveAddress(payload) {
      this.profile.saveAddress(payload);
    },

    setQuantity(item: IMenuItem) {
      if (this._debouncer) {
        window.clearTimeout(this._debouncer);
      }

      this._debouncer = window.setTimeout(
        () => this.cart.setQuantity(item.objectId, item.quantity),
        400
      );
    },

    scheduleAsap() {
      this.cart.scheduleAsap();
    },

    setTime(payload: any) {
      this.cart.setOrderTime(payload.date, payload.time);
    },

    setOrderName(orderName: string, showModalIfGuest: boolean = true) {
      if (!this.user && showModalIfGuest) {
        return this.$refs.nameOrderModal.open();
      }

      this.cart.setOrderName(orderName);
    },

    clearAlert() {
      this.alert.show = false;
      this.alert.message = '';
    },

    showAlert(message: string, notification?: string) {
      if (this.isAddingCoupon) {
        return;
      }
      this.alert.show = true;
      this.alert.message = message;
      this.alert.notification = notification;

      window.scrollTo(0, 0);
    },

    removeDeliveryZone() {
      this.cart.removeDeliveryZone();
    },

    setDeliveryZone(payload: IZoneArea) {
      this.cart.setDeliveryZone(payload);
    },

    setSuggestedAddresses(payload: any[]) {
      this.profile.setSuggestedAddresses(payload);
    },

    clearSuggestedAddresses() {
      this.profile.clearSuggestedAddresses();
    },

    closeCart(event) {
      this.$emit('closeMobileCart', event);
    },

    login() {
      this.routeService.route('Login');
    },

    register() {
      this.routeService.route('Register');
    },

    closeNameOrderModal() {
      this.$refs.nameOrderModal.close();

      this.setOrderName('', false);
    },

    async clearCart() {
      await this.cart.clear(this.profile.user);
      await this.navigateToMenu();
    },

    // Styling

    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    },

    getAlertStyle(): any {
      return this._designService.getAlertStyling();
    }
  },
  destroyed() {
    if (this.removeShowAlertObserver) {
      this.removeShowAlertObserver();
    }
  },
  watch: {
    cartValidations(newValidations, oldValidations) {
      if (oldValidations.length !== newValidations.length && !this.isAddingCoupon) {
        this.clearAlert();
        for (let i = 0; i < newValidations.length; i++) {
          this.showAlert(getValidationMessage(newValidations[i]));
        }
      }
    }
  }
});
