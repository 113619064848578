

























































































































import Vue from 'vue';
import designService from '../../common/services/design.service';
import {Util} from '../../common/services/Util';
import {IOrder} from '../models/Order';
import designMixin from '../../common/mixins/design.mixin';
import { IRestaurant } from '../../restaurants/types/restaurant.types';
import filtersMixin from '../../common/mixins/filters.mixin';

const moment = require('moment');

export default Vue.extend({
  mixins: [designMixin, filtersMixin],
  props: {
    historicalOrders: {
      type: Array as () => Array<IOrder>,
      required: true
    },
    selectedRestaurant: {
      type: Object as () => IRestaurant,
      required: true
    }
  },
  computed: {
    // services

    _designService() {
      return designService;
    }
  },
  methods: {
    chooseOrder(order) {
      this.$emit('chooseOrder', order.objectId);
    },

    getFormattedTime(orderTime: string): string {
      return Util.formatDate(moment(orderTime));
    }
  }
});
