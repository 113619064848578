





































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import modalMixin from '../../common/mixins/modal.mixin';
import {IMenuItem} from '../models/Item';
import modalService from '../../common/services/modal.service';
import designService from '../../common/services/design.service';
import ModalModern from '../../common/components/ModalModern.vue';

export default Vue.extend({
    mixins: [designMixin, modalMixin],
    components: {
      ModalModern
    },
    props: {
        selectedItem: {
            type: Object as () => IMenuItem,
            required: false
        }
    },
    computed: {
        // services
        _modalService() {
            return modalService;
        },

        addText() {
          return 'NO, ADD TO CART';
        },
        closeStyle() {
          return {
            color: designService.getButtonColor()
          };
        },
        customizeText() {
          return 'YES, CUSTOMIZE';
        },
        message () {
          if (this.selectedItem) {
            return 'Want To Customize Your ' + this.selectedItem.name + '?';
          } else {
            return '';
          }
        }
    },
    methods: {
        customizeItem() {
            this.close();
            this._modalService.customizeItem(this.selectedItem);
            this.$emit('setSelectedItem');
            this.$emit('customize');
        },

        addItem() {
            this.close();
            this._modalService.addItem(this.selectedItem);
            this.$emit('setSelectedItem');
            this.$emit('add');
        }
    }
});
