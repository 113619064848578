














import Vue from 'vue';

export default Vue.extend({
    props: {
        includeEmpty: Boolean,
        items: {
            type: Array as () => Array<any>,
            required: true
        },
        labelKey: String,
        value: {},
        valueKey: String
    },
    methods: {
        getLabel(item) {
            if (this.labelKey) {
                return item[this.labelKey];
            } else {
                return item;
            }
        },

        getValue(item) {
            if (this.valueKey) {
                return item[this.valueKey];
            } else {
                return item;
            }
        },

        onChange(event) {
            this.$emit('input', event.target.value);
        }
    }
});
