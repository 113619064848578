



















import Vue from 'vue';
import {ICoupon} from '../../coupons/models/Coupon';

const moment = require('moment');

export default Vue.extend({
  props: {
    coupon: {
      type: Object as () => ICoupon,
      required: true
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('M/D/YY');
    }
  }
});
