































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import {IMenuItem} from '../models/Item';
import filtersMixin from '../../common/mixins/filters.mixin';

export default Vue.extend({
  mixins: [designMixin, filtersMixin],
  props: {
    item: {
      type: Object as () => IMenuItem,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    // models

    isSelected: {
      get() {
        return this.selected;
      },
      set() {
        this.$emit('select', this.item);
      }
    },
    getItemImageStyle() {
      return 'background-image: url("' +
        this.item.imageLink +
        '");';
    }
  }
});
