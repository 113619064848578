










































































import Vue from 'vue';
import baseMixin, { navigateToMenu } from '../../common/mixins/base-component.mixin';
import SelectCouponItem from '../components/SelectCouponItem.vue';
import Spinner from '../../common/components/Spinner.vue';
import cart from '../../cart/models/Cart';
import couponWalkthrough from '../models/CouponWalkthrough';
import menu from '../../menu/models/Menu';
import restaurants from '../../restaurants/models/Restaurants';
import designService from '../../common/services/design.service';
import notifierService from '../../common/services/notifier.service';
import routeService from '../../common/services/route.service';
import { IMenuItem } from '../../menu/models/Item';
import { ICouponGroup } from '../models/Coupon';
import CustomizeModal from '../../menu/components/CustomizeModal.vue';
import { ADD_TO_CART_ACTIONS, Util } from '../../common/services/Util';
import { checkWalkthroughStatus, getAvailableCrustForCouponItem, getValidations } from '../helpers/coupon.helpers';
import OrderTypeModal from '../../menu/components/OrderTypeModal.vue';
import modalService from '../../common/services/modal.service';
import CouponModal from '../components/CouponModal.vue';
import DeliveryModal from '../../common/components/DeliveryModal.vue';
import order from '../../order/models/Order';
import { IZoneArea } from '../../restaurants/types/restaurant.types';
import { ICart, ORDER_TYPES } from '../../cart/cart.types';
import loyaltyService from '../../coupons/services/loyalty.service';
import ChangeLocationModal from '../../common/components/ChangeLocationModal.vue';
import cartHelperService from '../../cart/services/cart-helper.service';
import analyticsManager from '../../common/services/analytics-manager.service';
import profile from '../../profile/models/Profile';

declare var dataLayer: any;

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    ChangeLocationModal,
    CouponModal,
    CustomizeModal,
    DeliveryModal,
    OrderTypeModal,
    SelectCouponItem,
    Spinner
  },

  computed: {
    // models

    _designService() {
      return designService;
    },

    _notifier() {
      return notifierService;
    },

    routeService() {
      return routeService;
    },

    cart() {
      return cart;
    },

    couponWalkthrough() {
      return couponWalkthrough;
    },

    menu() {
      return menu;
    },

    profile() {
      return profile;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    addresses() {
      return profile.addresses;
    },

    categoryDesign() {
      return this.menu.categoryDesign;
    },

    defaultItemDesign() {
      return this.menu.defaultItemDesign;
    },

    itemsMap() {
      return this.couponWalkthrough.itemsByGroupMap;
    },

    orderType() {
      return cart.cart.orderType;
    },

    restaurantCount() {
      return restaurants.restaurantCount;
    },

    selectedCoupon() {
      return this.couponWalkthrough.selectedCoupon;
    },

    selectedItemsMap() {
      return this.couponWalkthrough.selectedItemsMap;
    },

    selectedRestaurant() {
      return this.restaurants.selectedRestaurant;
    },

    sizeMap() {
      return this.restaurants.sizeMap;
    },

    suggestedAddresses() {
      return profile.suggestedAddresses;
    },

    tenderRequest() {
      return order.tenderRequest;
    },

    updating() {
      return cart.updating;
    },

    user() {
      return profile.user;
    },

    // refs

    clearCartModal() {
      return this.$refs.clearCartModal;
    },

    customizeModal() {
      return this.$refs.customizeModal;
    },

    spinner() {
      return this.$refs.spinner;
    }
  },

  data() {
    return {
      couponId: null, // string,
      group: null, // ICouponGroup,
      initialized: false,
      item: null, // IMenuItem,
      itemNumber: 0, // number,
      sizeId: null // string
    };
  },

  watch: {
    orderType(orderTypeId) {
      this.checkValidations(orderTypeId, true);
    }
  },

  created() {
    const itemNumber = parseInt(this.$route.params.itemNumber, 10);
    this.checkWalkthroughStatus(itemNumber, this.$route.params.group || this.selectedCoupon.groups[itemNumber - 1]);

    window.scrollTo(0, 0);
  },

  mounted() {
    Util.waitUntil(() => this.itemNumber > 0).then(() => {
      if (!cart.cart.orderType) {
        if (!this.restaurants.cameFromLocationFinder) {
          this.$refs.orderTypeModal.open(false);
        }
      } else if (loyaltyService.linkOffer
        && cart.cart.orderType === ORDER_TYPES.DELIVERY
        && (!order.tenderRequest.deliveryAddress || !order.tenderRequest.deliveryAddress.addressLine)
        && this.itemNumber === 1) {
        cartHelperService.openDeliveryModal();
      } else if (loyaltyService.linkOffer
        && this.itemNumber === 1
        && loyaltyService.linkOffer === this.selectedCoupon) {
        const orderTypesCount = Object.values(this.selectedRestaurant.orderTypes)
          .filter((type: any) => type.active)
          .length;

        if (this.restaurants.cameFromLocationFinder
          && cart.cart.orderType !== ORDER_TYPES.DELIVERY && orderTypesCount > 2) {
          // force user to choose a specific carry-out order type if coming from location finder
          this.$refs.orderTypeModal.open(false);
        } else {
          const coupon = cart.cart.coupons.find(c => c.couponId === loyaltyService.linkOffer.couponId);

          if (!coupon) {
            loyaltyService.chooseCoupon(loyaltyService.linkOffer, false);
          }
        }
      } else if (loyaltyService.linkOffer && this.itemNumber === 1 && !this.selectedCoupon && cart.cart.orderType) {
        loyaltyService.chooseCoupon(loyaltyService.linkOffer, false);
      }
    });

    this.subscribe(modalService.clearCartModal$, () => {
      this.clearCartModal.open();
      this._designService.setModalStyling();
    });
  },

  methods: {
    checkWalkthroughStatus(itemNumber: number, group: ICouponGroup) {
      Util.waitUntil(() => this.selectedItemsMap && this.itemsMap).then(() => {
        const result = checkWalkthroughStatus(
          itemNumber,
          this.selectedItemsMap,
          () => {
            this.itemNumber = itemNumber + 1;
          }, () => {
            if (loyaltyService.linkOffer && loyaltyService.linkOffer.objectId === this.selectedCoupon.objectId) {
              loyaltyService.isLinkOfferFulfilled = true;
            }

            // Google tag manager tracking
            dataLayer.push({
              event: 'couponfinished',
              data: {
                couponId: this.couponWalkthrough.selectedCoupon.couponId,
                couponName: this.couponWalkthrough.selectedCoupon.name,
                cartId: this.cart.cart.objectId,
                userId: this.profile.user ? this.profile.user.objectId : null,
                locationId: this.selectedRestaurant.objectId,
                locationName: this.selectedRestaurant.name
              }
            });

            // tracking
            analyticsManager.track('Coupon walkthrough finished', {
              couponId: this.couponWalkthrough.selectedCoupon.couponId,
              couponName: this.couponWalkthrough.selectedCoupon.name,
              cartId: this.cart.cart.objectId,
              userId: this.profile.user ? this.profile.user.objectId : null,
              locationId: this.selectedRestaurant.objectId,
              locationName: this.selectedRestaurant.name
            });

            this.couponWalkthrough.clearSelectedItemsMap();
            this.couponWalkthrough.clearSelectedCoupon();

            this.routeService.clearReturnRoute();

            navigateToMenu().then(() => {
              window.setTimeout(() => {
                this._notifier.success('Coupon Completed!');
              }, 300);
            });
          },
          group
        );

        if (!result) {
          this.couponId = this.$route.params.couponId;
          this.itemNumber = itemNumber;
        }

        this.initialized = true;
      });
    },

    selectItem(payload: {
      item: IMenuItem, sizeId: string, group: ICouponGroup, itemSelectedPostTask: string,
      addToCartAction?: ADD_TO_CART_ACTIONS, styleId?: string
    }) {
      cart.selectItem(null);
      this.menu.clearItemOptions();

      const isForceCustomize = () => {
        if (payload.addToCartAction === ADD_TO_CART_ACTIONS.FORCE_CUSTOMIZE) {
          return true;
        }

        let toppingLimit: string = payload.group.includes[0].toppingLimit;

        if (toppingLimit === 'EXACT' || toppingLimit === 'ATLEAST') {
          let itemToppingCount: number = payload.item.toppingCount;
          let toppingCount: number = payload.group.includes[0].toppingCount;

          if (toppingLimit === 'ATLEAST' && itemToppingCount < toppingCount) {
            return true;
          } else if (toppingLimit === 'EXACT' && itemToppingCount !== toppingCount) {
            return true;
          }
        }

        return false;
      }

      // Get available crusts for this item
      const crustsAvailable = getAvailableCrustForCouponItem(payload.group);
      if (crustsAvailable.length > 0) {
        // if only one crust is available, add crust automatically and don't customize
        if (crustsAvailable.length === 1) {
          payload.styleId = crustsAvailable[0];
        } else if (crustsAvailable.length > 1 &&
          payload.item.hasDefaultStyle &&
          crustsAvailable.indexOf(payload.item.defaultStyleId) < 0) {
          // otherwise, force the user to customize the item
          return this.customizeItemForSelect(payload);
        }
      }

      if (isForceCustomize()) {
        return this.customizeItemForSelect(payload);
      } else if (payload.addToCartAction === ADD_TO_CART_ACTIONS.FORCE_ADD) {
        return this.addItemForSelect(payload);
      } else {
        // ADD_TO_CART_ACTIONS.COMPUTE or undefined payload.addToCartAction (coming from classic)
        return Util.decideToCustomizeItem(
          payload.item,
          payload.sizeId,
          this.sizeMap, payload.itemSelectedPostTask,
          () => {
            this.customizeItemForSelect(payload);
          }, () => {
            this.addItemForSelect(payload);
          }, () => {
            this.customizeItemForSelect(payload);
          }
        );
      }
    },

    customizeItemForSelect(
      payload: { item: IMenuItem, sizeId: string, group: ICouponGroup, itemSelectedPostTask: string, styleId?: string }) {
      let cartPayload: any = {
        item: payload.item,
        sizeId: payload.sizeId,
        group: payload.group
      };

      let data: any = {
          itemId: payload.item.objectId
        },
        returnData: any = {
          couponId: this.couponId,
          itemNumber: this.itemNumber,
          item: payload.item,
          group: payload.group
        };

      this.couponWalkthrough.selectCouponGroup(payload.group);

      this.cart.addItem(cartPayload).then((cart) => {
        this.routeToCustomize(data, returnData).then(() => {
          if (payload.styleId) {
            this.addStyleToLastItem(cart, cartPayload.item.objectId, payload.styleId);
          }
        });
      }).catch(error => {
        notifierService.error(error);
      });
    },

    addItemForSelect(payload: {
      item: IMenuItem,
      sizeId: string,
      group: ICouponGroup,
      itemSelectedPostTask: string,
      styleId?: string
    }) {
      let cartPayload: any = {
        item: payload.item,
        sizeId: payload.sizeId,
        group: payload.group
      };

      this.cart.addItem(cartPayload).then((cart) => {
        this.couponWalkthrough.selectCouponItem(payload.item, this.itemNumber, payload.group).then(() => {
          if (payload.styleId) {
            this.addStyleToLastItem(cart, cartPayload.item.objectId, payload.styleId);
          }
        });

        this.checkWalkthroughStatus(this.itemNumber, payload.group);
      }).catch(error => {
        notifierService.error(error);
      });
    },

    askToCustomizeItemForSelect(
      payload: { item: IMenuItem, sizeId: string, group: ICouponGroup, itemSelectedPostTask: string }) {
      this.item = payload.item;
      this.group = payload.group;
      this.sizeId = payload.sizeId;

      this.customizeModal.open();
    },

    routeToCustomize(data: any, returnData: any) {
      this.routeService.setReturnRoute('SelectCouponItem',
        {couponId: returnData.couponId, itemNumber: returnData.itemNumber, group: returnData.group},
        {item: returnData.item, group: returnData.group}
      );

      // this._notifier.success('Item Added');
      return this.routeService.route('CustomizeItem', data);
    },

    addItem() {
      let cartPayload: any = {
        item: this.item,
        sizeId: this.sizeId,
        group: this.group,
        styleId: null
      };

      this.customizeModal.close();

      // Get available crusts for this item
      const crustsAvailable = getAvailableCrustForCouponItem(cartPayload.group);
      if (crustsAvailable.length === 1) {
        cartPayload.styleId = crustsAvailable[0];
      }
      this.cart.addItem(cartPayload).then((cart) => {
        this.couponWalkthrough.selectCouponItem(this.item, this.itemNumber, this.group).then(() => {
          if (cartPayload.styleId) {
            this.addStyleToLastItem(cart, cartPayload.item.objectId, cartPayload.styleId);
          }
        });
        // this._notifier.success('Item Added');
        this.checkWalkthroughStatus(this.itemNumber, this.group);
      }).catch(error => {
        notifierService.error(error);
      });
    },

    addStyleToLastItem(cart: ICart, itemId: string, styleId: string) {
      if (!cart) {
        throw 'Failed to add style to item';
      }
      const item: IMenuItem = cart.items[cart.items.length - 1];
      if (!item) {
        throw 'Failed to add style to item';
      }
      this.cart.updateStyle(item.objectId, styleId);
    },

    checkValidations(orderType, alreadyAdded) {
      let messages: string[] = getValidations(
        this.selectedCoupon,
        cart.cart,
        this.selectedRestaurant,
        restaurants.currentTime,
        alreadyAdded,
        orderType
      );

      if (messages && messages.length > 0) {
        modalService.selectCouponErrorModal(this.selectedCoupon, messages);
      }
    },

    customizeItem() {
      this.spinner.open();

      let cartPayload: any = {
          item: this.item,
          sizeId: this.sizeId,
          group: this.group
        },
        data: any = {
          itemId: this.item.itemId
        },
        returnData: any = {
          couponId: this.couponId,
          itemNumber: this.itemNumber,
          item: this.item,
          group: this.group
        };

      this.couponWalkthrough.selectCouponGroup(this.group);

      this.cart.addItem(cartPayload).then(() => {
        this.routeToCustomize(data, returnData);
      });
    },

    clearSuggestedAddresses() {
      profile.clearSuggestedAddresses();
    },

    removeDeliveryZone() {
      cart.removeDeliveryZone();
    },

    saveDeliveryAddress(payload: any) {
      profile.saveAddress(payload);
    },

    setDeliveryZone(payload: IZoneArea) {
      cart.setDeliveryZone(payload);
    },

    setSuggestedAddresses(payload: any[]) {
      profile.setSuggestedAddresses(payload);
    },

    onCustomizeModalAdd() {
      this.addItem();
    },

    onCustomizeModalCustomize() {
      this.customizeItem();
    },

    onSelectOrderType(orderType) {
      this.checkValidations(orderType, false);
    },

    onCouponModalClose() {
      if (this.$refs.couponModal.errorMessages) {
        this.navigateToMenu();
      }
    },

    cancelClearCart() {
      this.clearCartModal.close();
      modalService.cancelClearCart();
    },

    clearCart(): void {
      setTimeout(() => {
        if (this.clearCartModal) {
          this.clearCartModal.close();
        }
      }, 300);

      modalService.clearCart();
    },

    onClearCartModalClose() {
      modalService.cancelClearCart();
    },

    // Styling

    getButtonStyle(): any {
      return this._designService.getButtonStyle();
    },

    getButtonClass(): any {
      return this._designService.getButtonClass();
    },

    getModalHeaderStyle(): any {
      return this._designService.getModalHeaderStyling();
    },

    getModalBodyStyle(): any {
      return this._designService.getModalBodyStyling();
    }
  }
});
