

































































import Vue from 'vue';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import OrderTypeList from '../../restaurants/components/OrderTypeList.vue';
import RestaurantInfo from '../../restaurants/components/RestaurantInfo.vue';
import designService from '../../common/services/design.service';
import modalService from '../../common/services/modal.service';
import modalBus from '../../common/messaging/modal.bus';
import cart from '../../cart/models/Cart';
import cartHelperService from '../../cart/services/cart-helper.service';
import { MODAL_ACTIONS } from '../../common/common.constants';
import designMixin from '../../common/mixins/design.mixin';
import ModalModern from '../../common/components/ModalModern.vue';
import { IOrderType, IRestaurant, ORDER_TYPES } from '../../restaurants/types/restaurant.types';
import { OPEN_ORDER_TYPE_MODAL } from '../../common/messaging/notifications';
import loyaltyService from '../../coupons/services/loyalty.service';
const _ = require('lodash');

export default Vue.extend({
  mixins: [subscriberMixin, designMixin],
  components: {
    OrderTypeList,
    RestaurantInfo,
    ModalModern
  },
  props: {
    orderTypes: {
      type: Array as () => Array<string>,
      required: false
    },
    restaurant: {
      type: Object as () => IRestaurant,
      required: true
    },
    restaurantCount: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    allowClose: true,
    ORDER_TYPES
  }),
  computed: {
    // services

    _designService() {
      return designService;
    },

    cart() {
      return cart;
    },

    cartHelperService() {
      return cartHelperService;
    },

    modalService() {
      return modalService;
    },

    modalBus() {
      return modalBus;
    },

    orderTypeList(): Object {
      const activeOrderTypes = Object.values(this.restaurant.orderTypes).filter((type: any) => type.active);

      if (this.orderTypes) {
        return activeOrderTypes.filter((type: IOrderType) => this.orderTypes.find(t => t.objectId === type.objectId));
      } else {
        return activeOrderTypes;
      }
    }
  },
  created() {
    this.subscribe(this.modalService.selectOrderTypeModal$, () => {
      this.open();
    });

    this.subscribe(this.modalBus.selectOrderTypeModal$, (action: boolean) => {
        if (action === MODAL_ACTIONS.OPEN) {
          this.open();
        }
      }
    );

    this.observe(OPEN_ORDER_TYPE_MODAL, () => {
      this.open();
    });
  },
  methods: {
    open(allowClose: boolean = true) {
      if (loyaltyService.linkOffer) {
        const addedCoupon = cart.cart.coupons.find(coupon => coupon.itemId === loyaltyService.linkOffer.itemId);

        if (!addedCoupon) {
          allowClose = false;
        }
      }

      this.allowClose = allowClose;

      this.$refs.modal.open();

      this._designService.setModalStyling();
    },

    hide() {
      this.$refs.modal.hide();
    },

    setOrderType(orderType: IOrderType): void {
      this.setOrderTypeById(orderType.objectId);
    },

    setOrderTypeById(orderTypeId): void {
      this.$emit('onSelect', orderTypeId);

      this.$refs.modal.close();

      let promise;

      if (cart.cart.orderType === orderTypeId && orderTypeId !== ORDER_TYPES.DELIVERY) {
        promise = Promise.resolve(); // cartHelperService.setOrderType will return false if order type is already set
      } else {
        promise = this.cartHelperService.setOrderType(orderTypeId);
      }

      // delivery logic will add coupon
      if (promise && loyaltyService.linkOffer && orderTypeId !== ORDER_TYPES.DELIVERY) {
        promise.then(() => {
          loyaltyService.chooseCoupon(loyaltyService.linkOffer, false);
        });
      }
    },

    chooseRestaurant(): void {
      this.$emit('chooseRestaurant');
    },

    onHide() {
      this.modalService.closeOrderTypeModal();
    }
  }
});
