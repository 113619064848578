


















































import Vue from 'vue';
import loyaltyService from '../../coupons/services/loyalty.service';
import {ICoupon} from '../../coupons/models/Coupon';
import designService from '../../common/services/design.service';
import designMixin from '../../common/mixins/design.mixin';
import { ICart } from '../../cart/cart.types';
import { IUser } from '../../profile/stores/profile.store';

export default Vue.extend({
  mixins: [designMixin],
    props: {
        cart: {
            type: Object as () => ICart,
            required: true
        },
        user: {
            type: Object as () => IUser,
            required: false
        },
        viewingSuggestions: {
          type: Boolean,
          required: true
        },
        showAll: {
          type: Boolean,
          required: true,
          default: false
        }
    },
    computed: {
        _designService () {
            return designService;
        },
        loyaltyService () {
            return loyaltyService;
        }
    },
    data () {
        return {
            initialized: false
        }
    },
    mounted () {
        this.initialized = true;
    },
    methods: {
        getUserCoupons(): ICoupon[] {
            return this.loyaltyService.getAvailableCoupons();
        },

        chooseCoupon(coupon: ICoupon) {
            this.loyaltyService.chooseCoupon(coupon, false);

            this.$emit('closeOrderSettingsModal');

            this.$emit('choseCoupon', coupon);
        },

        // Styling
        getSidebarStyle(): any {
            return this._designService.getSidebarStyle();
        },

        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        },

        getButtonClass(): any {
            return this._designService.getButtonClass();
        }
    }
})
