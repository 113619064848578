

































































import Vue from 'vue';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import designMixin from '../../common/mixins/design.mixin';
import CartTicketContainer from '../../cart/containers/CartTicketContainer.vue';
import OrderSettings from '../components/OrderSettings.vue';
import SelectedRestaurant from '../../common/components/SelectedRestaurant.vue';
import Suggestions from '../components/Suggestions.vue';
import cart from '../../cart/models/Cart';
import profile from '../../profile/models/Profile';
import restaurants from '../../restaurants/models/Restaurants';
import order from '../../order/models/Order';
import modalService from '../../common/services/modal.service';
import routeService from '../../common/services/route.service';
import cartHelperService from '../../cart/services/cart-helper.service';
import designService from '../../common/services/design.service';
import OrderDetailsSidebar from '../components/OrderDetailsSidebar.vue';
import { IRestaurant, IZoneArea } from '../../restaurants/types/restaurant.types';
import { canLeaveItemCustomization } from '../helpers/item-customization.helpers';
import { CART_STORE } from '../../cart/stores/cart.store';
import suggestionService from '../../common/services/suggestion.service';
import menu from '../models/Menu';
import notifierService from '../../common/services/notifier.service';
import { IMenuItem } from '../models/Item';
import { IValidation } from '../../cart/cart.types';

export default Vue.extend({
  mixins: [subscriberMixin, designMixin],
  components: {
    CartTicketContainer,
    OrderSettings,
    SelectedRestaurant,
    Suggestions,
    OrderDetailsSidebar
  },
  props: {
    forceShowSuggestions: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    // services

    _modalService () {
      return modalService;
    },

    routeService () {
      return routeService;
    },

    cart () {
      return cart;
    },

    cartHelperService () {
      return cartHelperService;
    },

    designService () {
      return designService;
    },

    order () {
      return order;
    },

    profile () {
      return profile;
    },

    // getters

    cartError() {
      return cart.cartError;
    },

    cartObj() {
      return cart.cart;
    },

    currentTime() {
      return restaurants.currentTime;
    },

    historicalOrders() {
      return this.order.historicalOrders;
    },

    restaurantCount() {
      return restaurants.count;
    },

    restaurantSelected() {
      return restaurants.selected;
    },

    selectedRestaurant() {
      return restaurants.selectedRestaurant;
    },

    tenderRequest() {
      return order.tenderRequest;
    },

    updating() {
      return cart.updating;
    },

    user() {
      return this.profile.user;
    }
  },
  data () {
    return {
      cancelClearCartSubscription: null,
      clearCartSubscription: null,
      viewingSuggestions: false
    };
  },
  created() {
    if (this.forceShowSuggestions) {
      this.viewingSuggestions = true;
    }
  },
  methods: {
    changeRestaurant() {
      const selectedItem = this.$store.getters[CART_STORE + '/selectedItem'];

      if (selectedItem && !canLeaveItemCustomization(null, this.cartObj,
        selectedItem, this.selectedRestaurant, false,
        this.$store.state.cart.shouldCheckForSuggestions,
        this.$store.state.cart.editingCheckoutItem,
        () => this.$store.commit(CART_STORE + '/SET_EDITING_CHECKOUT_ITEM', false),
        routeService.mustRoute(),
        () => routeService.clearReturnRoute(),
        routeService.fromSuggestion,
        value => routeService.fromSuggestion = value,
        (item?: IMenuItem, restaurant?: IRestaurant) => suggestionService.checkForSuggestions(item, restaurant),
        (cartId: string, itemId: string) => menu.loadSuggestions(cartId, itemId),
        (message: string, type: string, validation: IValidation) => notifierService.error(message, type, validation))) {
        return;
      }

      this.closeCart();

      let route = () => {
        restaurants.changeRestaurant();

        this.routeService.route('RestaurantsList');
      };

      if (this.hasClearCart()) {
        route();
      } else {
        let unsub = () => {
          this.clearCartSubscription.unsubscribe();
          this.clearCartSubscription = null;

          this.cancelClearCartSubscription.unsubscribe();
          this.cancelClearCartSubscription = null;
        };

        // not using this.subscribe since we want these subscriptions to last after this object is destroyed
        this.clearCartSubscription = this._modalService.clearCart$.subscribe(() => {
          unsub();

          route();
        });

        this.cancelClearCartSubscription = this._modalService.cancelClearCartModal$.subscribe(unsub);

        this._modalService.clearCartModal();
      }
    },

    hasClearCart(): boolean {
      return (!this.cartObj.items || this.cartObj.items.length <= 0)
        && (!this.cartObj.coupons || this.cartObj.coupons.length <= 0);
    },

    viewLocation(): void {
      this.closeCart();
      this.routeService.route('RestaurantView', { restaurantId: this.selectedRestaurant.objectId });
    },

    chooseType(payload) {
      if (!this.cartHelperService.setOrderType(payload.orderTypeId)) {
        return;
      }

      this.clearAlert();
    },

    removeDeliveryZone() {
      this.cart.removeDeliveryZone();
    },

    saveAddress(payload) {
      this.profile.saveAddress(payload);
    },

    setDeliveryZone(payload: IZoneArea) {
      this.cart.setDeliveryZone(payload);
    },

    setSuggestedAddresses(payload: any) {
      this.profile.setSuggestedAddresses(payload);
    },

    closeCart(): void {
      this.$emit('closeMobileCart');
    },

    choseCoupon(): void {
      this.closeCart();
    },

    showAlert () {
      this.$emit('showAlert');
    },

    clearAlert () {
      this.$emit('clearAlert');
    },

    showErrorAlert (error) {
      this.$emit('showErrorAlert', error);
    },

    // styling
    getButtonStyle() {
      return {};
    },

    getButtonClass(): any {
      return {};
    }
  }
})
