
















import Vue from 'vue';
import designService from '../../common/services/design.service';
import { IOrderType, IRestaurant } from '../types/restaurant.types';

export default Vue.extend({
    props: {
        selectedRestaurant: {
            type: Object as () => IRestaurant,
            required: true
        }
    },
    computed: {
        _designService () {
            return designService;
        }
    },
    methods: {
        getOrderTypes(): IOrderType[] {
            let values: IOrderType[] = [];

            if (this.selectedRestaurant && Object.keys(this.selectedRestaurant).length > 0 && this.selectedRestaurant.orderTypes) {
                for (let key of Object.keys(this.selectedRestaurant.orderTypes)) {
                    values.push(this.selectedRestaurant.orderTypes[key]);
                }
            }

            return values;
        },

        selectOrderType (type) {
            this.$emit('selectOrderType', type);
        },

        // Styling
        getButtonStyle(): any {
            return this._designService.getButtonStyle();
        },

        getButtonClass(): any {
            return this._designService.getButtonClass();
        }
    }
});
