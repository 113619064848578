

































import Vue from 'vue';
import designMixin from '../../common/mixins/design.mixin';
import subscriberMixin from '../../common/mixins/subscriber.mixin';
import modalService from '../../common/services/modal.service';
import ModalModern from '../../common/components/ModalModern.vue';
import cart from '../../cart/models/Cart';
import { IUnavailableMessage } from '../../cart/cart.types';
import filtersMixin from '../../common/mixins/filters.mixin';

export default Vue.extend({
  mixins: [designMixin, subscriberMixin, filtersMixin],
  components: { ModalModern },
  data() {
    return {
      itemIds: [],
      messages: []
    }
  },
  computed: {
    // services

    _modalService() {
      return modalService;
    },

    // getters

    _cart() {
      return cart;
    },

    getTitle() {
      return this.itemIds.length > 1 ? 'Items unavailable' : 'Item unavailable';
    },

    getRemoveButtonText() {
      return this.itemIds.length > 1 ? 'Remove Items from Cart' : 'Remove Item from Cart';
    },

    // styling

    bodyStyle() {
      return this.modalHeaderStyle;
    }
  },
  created() {
    this.subscribe(this._modalService.itemUnavailableModal$, (unavailableMessages: IUnavailableMessage[]) => {
      this.itemIds = [];
      this.messages = [];
      if (unavailableMessages && unavailableMessages.length > 0) {
        for (let i = 0; i < unavailableMessages.length; i++) {
          this.itemIds.push(unavailableMessages[i].objectId);
          this.messages.push(`${unavailableMessages[i].message}
           (Item is available from ${unavailableMessages[i].availableTime})`);
        }
      }
      this.open();
    });
  },
  methods: {
    removeItemFromCart() {
      this.$store.commit('notifications/CLEAR');
      this._cart.removeItems(this.itemIds);
      this.close();
    },

    scheduleFutureOrder() {
      this.close();
      this._modalService.openScheduleOrderModal();
    },

    open() {
      this.$refs.modal.open();
    },

    close() {
      this.$refs.modal.close();
    }
  }
});
