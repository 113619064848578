








import Vue from 'vue';
import baseMixin from '../../common/mixins/base-component.mixin';
import profile from '../models/Profile';
import Register from '../components/Register.vue';
import restaurants from '../../restaurants/models/Restaurants';
import designService from '../../common/services/design.service';
import environmentService from '../../common/services/environment.service';
import restaurantLoader from '../../restaurants/services/restaurant-loader.service';
import { IRegisterRequest } from '../stores/profile.store';
import routeService from '../../common/services/route.service';
import { Util } from '../../common/services/Util';

export default Vue.extend({
  mixins: [baseMixin],
  components: {
    Register
  },
  computed: {
    // models

    _designService() {
      return designService;
    },

    environment() {
      return environmentService;
    },

    profile() {
      return profile;
    },

    restaurantLoader() {
      return restaurantLoader;
    },

    restaurants() {
      return restaurants;
    },

    // getters

    registerError() {
      return this.profile.registerError;
    },

    registerRequest() {
      return this.profile.registerRequest;
    },

    route() {
      return Util.getQueryParam('route');
    }
  },
  methods: {
    register(registerRequest: IRegisterRequest) {
      const selectedRestaurantId: string = this.restaurants.selectedRestaurantId;

      if (!registerRequest.location) {
        registerRequest.location = selectedRestaurantId;
      }

      return this.profile.register(registerRequest).then(success => {
        if (success) {
          const restaurant = restaurants.restaurants.find(rest => rest.objectId === registerRequest.location);

          if (restaurant && restaurant.active) {
            if (this.route) {
              routeService.route(this.route);
            } else if (selectedRestaurantId === registerRequest.location) {
              this.environment.navigateToLandingPage();
            } else {
              this.restaurantLoader.selectRestaurantById(registerRequest.location, false).then(() => {
                this.environment.navigateToLandingPage();
              });
            }
          } else {
            routeService.route('RegisterConfirmation');
          }
        }
      });
    }
  }
});
